import { createContext, useState } from 'react'
export const vistaMediacionContext = createContext()
export function VistaMediacionContextProvider ({children}) {
    const [vistaMediacion, setVistaMediacion] = useState(true)
    function setVistaMediacionTrue () {
        setVistaMediacion(true)
    }
    function setVistaMediacionFalse () {
        setVistaMediacion(false)
    }
    return (
        <vistaMediacionContext.Provider value={{vistaMediacion, setVistaMediacionTrue, setVistaMediacionFalse, setVistaMediacion}}>
            {children}
        </vistaMediacionContext.Provider>
    )
}