import './detalles.css'
import axios from 'axios';
import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import esrever from 'esrever'
export default function Mensaje(mensaje) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    let clas = ''
    let clas1 = ''
    let clas2 = ''
    let clas3 = ''
    if (mensaje.mensaje['ESTADO'] === 'MEDIACION' && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
        clas = 'mediacionEmpleado'
        clas1 = 'userTextDetalleEmpleado m-0'
        clas2 = 'mensajeDetalleEmpleado m-0'
        clas3 = 'horaDetalleEmpleado m-0'
    } else if (mensaje.mensaje['ESTADO'] === 'MEDIACION' && mensaje.mensaje['CARGO'] === 'MEDIADOR') {
        clas = 'mediacionEmpleado'
        clas1 = 'userTextDetalleEmpleado m-0'
        clas2 = 'mensajeDetalleEmpleado m-0'
        clas3 = 'horaDetalleEmpleado m-0'
    } else if (mensaje.mensaje['ESTADO'] === 'MEDIACION' && mensaje.mensaje['CARGO'] === 'GERENCIA') {
        clas = 'mediacionGerencia'
        clas1 = 'userTextDetalleGerencia m-0'
        clas2 = 'mensajeDetalleGerencia m-0'
        clas3 = 'horaDetalleGerencia m-0'
    } else if (mensaje.mensaje['ANCLADO'] && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
        clas = 'ancladoEmpleado'
        clas1 = 'userTextDetalleEmpleado m-0'
        clas2 = 'mensajeDetalleEmpleado m-0'
        clas3 = 'horaDetalleEmpleado m-0'
    } else if (mensaje.mensaje['ANCLADO'] && mensaje.mensaje['CARGO'] === 'GERENCIA') {
        clas = 'ancladoGerencia'
        clas1 = 'userTextDetalleGerencia m-0'
        clas2 = 'mensajeDetalleGerencia m-0'
        clas3 = 'horaDetalleGerencia m-0'
    } else if (mensaje.mensaje['OFRECIMIENTO'] && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
        clas = 'ofrecimientoEmpleado'
        clas1 = 'userTextDetalleEmpleado m-0'
        clas2 = 'mensajeDetalleEmpleado m-0'
        clas3 = 'horaDetalleEmpleado m-0'
    } else if (mensaje.mensaje['OFRECIMIENTO'] && mensaje.mensaje['CARGO'] === 'GERENCIA') {
        clas = 'ofrecimientoGerencia'
        clas1 = 'userTextDetalleGerencia m-0'
        clas2 = 'mensajeDetalleGerencia m-0'
        clas3 = 'horaDetalleGerencia m-0'
    } else if (mensaje.mensaje['PRIVADO'] && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
        clas = 'privadoEmpleado'
        clas1 = 'userTextDetalleEmpleado m-0'
        clas2 = 'mensajeDetalleEmpleado m-0'
        clas3 = 'horaDetalleEmpleado m-0'
    } else if (mensaje.mensaje['PRIVADO'] && mensaje.mensaje['CARGO'] === 'GERENCIA') {
        clas = 'privadoGerencia'
        clas1 = 'userTextDetalleGerencia m-0'
        clas2 = 'mensajeDetalleGerencia m-0'
        clas3 = 'horaDetalleGerencia m-0'
    } else if ((mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
        clas = 'empleado'
        clas1 = 'userTextDetalleEmpleado m-0'
        clas2 = 'mensajeDetalleEmpleado m-0'
        clas3 = 'horaDetalleEmpleado m-0'
    } else if (mensaje.mensaje['CARGO'] === 'GERENCIA') {
        clas = 'gerencia'
        clas1 = 'userTextDetalleGerencia m-0'
        clas2 = 'mensajeDetalleGerencia m-0'
        clas3 = 'horaDetalleGerencia m-0'
    } else {
        clas = 'default bg-primary'
    }
    const btn = { border: 'none', background: 'none', color: 'blue' }
    const visible = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/gerencia/detalles/visible`,
            {
                id: mensaje.mensaje['ID'],
            }
        ).then((response) => {
            if (response) {
                setTimeout(() => {
                    window.location.reload()
                }, 0);
            }
        })
    });
    const b = esrever.reverse((JSON.stringify(mensaje.mensaje['FECHA']).replace(/\"/g, ""))).slice(3)
    const c = esrever.reverse(b)
    return (
        <div>
            {mensaje.mensaje['VISIBLE'] ?
                <div>
                    {cookies.user.cargo === 'PAS' ?
                        <div className='mt-2'>
                            {mensaje.mensaje['ANCLADO'] === true ? <div></div> : <div>
                                <div className={clas}>
                                    <p className={clas1}>{mensaje.mensaje['USUARIO']}</p>
                                    <p className={clas2}>{JSON.stringify(mensaje.mensaje['OBSERVACIONES']).toLowerCase().replace(/\"/g, "").replace(/\\n/g, " ")}</p>
                                </div>
                                <div className='d-flex'>
                                    <p className={clas3}>{c} - {mensaje.mensaje['ESTADO']}</p>
                                </div>
                            </div>}
                        </div> :
                        <div className='mt-2'>
                            <div className={clas}>
                                <p className={clas1}>{mensaje.mensaje['USUARIO']}</p>
                                <p className={clas2}>{JSON.stringify(mensaje.mensaje['OBSERVACIONES']).toLowerCase().replace(/\"/g, "").replace(/\\n/g, " ")}</p>
                            </div>
                            <div className='d-flex'>
                                <p className={clas3}>{c} - {mensaje.mensaje['ESTADO']}</p>
                                {cookies.user.cargo === 'GERENCIA' && !mensaje.mensaje['ANCLADO'] ? <button style={btn} onClick={visible} className=' '><i class="bi bi-trash"></i></button> : <div></div>}
                            </div>
                        </div>}
                </div> : <div></div>}
        </div>
    )
}