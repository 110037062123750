import { useState } from 'react';
import '../index.css'
import axios from 'axios'
import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
export default function AmpliacionesPas({ expediente }) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const today = new Date()
    const now = today.toLocaleString()
    const [doc, setDoc] = useState({ cedula: [], registro: [], dni: [], automotor: [], vtv: [], cobertura: [], franquicia: [], fotos: [] })
    const mailData = new FormData();
    for (var i = 0; i < doc.dni.length; i++) {
        mailData.append(`dni${i}`, doc.dni[i]);
    }
    for (var i = 0; i < doc.cedula.length; i++) {
        mailData.append(`cedula${i}`, doc.cedula[i]);
    }
    for (var i = 0; i < doc.registro.length; i++) {
        mailData.append(`registro${i}`, doc.registro[i]);
    }
    for (var i = 0; i < doc.fotos.length; i++) {
        mailData.append(`fotos${i}`, doc.fotos[i]);
    }
    for (var i = 0; i < doc.vtv.length; i++) {
        mailData.append(`vtv${i}`, doc.vtv[i]);
    }
    for (var i = 0; i < doc.automotor.length; i++) {
        mailData.append(`automotor${i}`, doc.automotor[i]);
    }
    for (var i = 0; i < doc.cobertura.length; i++) {
        mailData.append(`cobertura${i}`, doc.cobertura[i]);
    }
    for (var i = 0; i < doc.franquicia.length; i++) {
        mailData.append(`franquicia${i}`, doc.franquicia[i]);
    }
    const [carga, setCarga] = useState(false)
    const submit = useCallback(async (e) => {
        e.preventDefault()
        setCarga(true)
        const response = await axios.post(`https://api.tuveunchoque.com.ar/api/mail/nuevaDocu/${expediente.nro}`, mailData, { headers: { "Content-Type": "multipart/form-data" } }).then(async (response) => {
            if (response.status === 200) {
                if (expediente.asignadoA === 'JUAN') {
                    setTimeout(async () => {
                        await axios.post(`https://api.tuveunchoque.com.ar/api/mail/nuevaAmp`, { nro: expediente.nro, mail: 'podesta@estudio-pyp.com.ar', sharepoint: expediente.sharepoint })
                        await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionCargaPas`, { id: expediente.id, carga: 1 })
                        window.location.reload()
                    }, 1000);
                } else if (expediente.asignadoA === 'FRANCO') {
                    setTimeout(async () => {
                        await axios.post(`https://api.tuveunchoque.com.ar/api/mail/nuevaAmp`, { nro: expediente.nro, mail: 'piazze@estudio-pyp.com.ar', sharepoint: expediente.sharepoint })
                        await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionCargaPas`, { id: expediente.id, carga: 1 })
                    }, 1000);
                } else if (expediente.asignadoA === 'AGOSTINA') {
                    setTimeout(async () => {
                        await axios.post(`https://api.tuveunchoque.com.ar/api/mail/nuevaAmp`, { nro: expediente.nro, mail: 'rodriguezg@estudio-pyp.com.ar', sharepoint: expediente.sharepoint })
                        await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionCargaPas`, { id: expediente.id, carga: 1 })
                        window.location.reload()
                    }, 1000);
                } else if (expediente.asignadoA === 'DANIELA') {
                    setTimeout(async () => {
                        axios.post(`https://api.tuveunchoque.com.ar/api/mail/nuevaAmp`, { nro: expediente.nro, mail: 'maragliano@estudio-pyp.com.ar', sharepoint: expediente.sharepoint })
                        await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionCargaPas`, { id: expediente.id, carga: 1 })
                        window.location.reload()
                    }, 1000);
                } else if (expediente.asignadoA === 'MICAELA') {
                    setTimeout(async () => {
                        axios.post(`https://api.tuveunchoque.com.ar/api/mail/nuevaAmp`, { nro: expediente.nro, mail: 'meijide@estudio-pyp.com.ar', sharepoint: expediente.sharepoint })
                        await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionCargaPas`, { id: expediente.id, carga: 1 })
                        window.location.reload()
                    }, 1000);
                } else if (expediente.asignadoA === 'SOFIA') {
                    setTimeout(async () => {
                        await axios.post(`https://api.tuveunchoque.com.ar/api/mail/nuevaAmp`, { nro: expediente.nro, mail: 'acosta@estudio-pyp.com.ar', sharepoint: expediente.sharepoint })
                        await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionCargaPas`, { id: expediente.id, carga: 1 })
                        window.location.reload()
                    }, 1000);
                } else if (expediente.asignadoA === 'FEDERICO') {
                    setTimeout(async () => {
                        await axios.post(`https://api.tuveunchoque.com.ar/api/mail/nuevaAmp`, { nro: expediente.nro, mail: 'valls@estudio-pyp.com.ar', sharepoint: expediente.sharepoint })
                        await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionCargaPas`, { id: expediente.id, carga: 1 })
                        window.location.reload()
                    }, 1000);
                } else {
                    await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionCargaPas`, { id: expediente.id, carga: 1 })
                    window.location.reload()
                }
            }
        })
    }, [expediente, mailData]);
    console.log(doc);

    return (
        <div className={expediente.ampliacionDni === 0 && expediente.ampliacionCedulaVerde === 0 && expediente.ampliacionRegistro === 0 && expediente.ampliacionFotosDanio === 0 && expediente.ampliacionPresupuesto === 0 && expediente.ampliacionCerificado08 === 0 && expediente.ampliacionTelefono === 0 && expediente.ampliacionMail ? 'd-none' : 'sectionAmpliacionesPas me-5 ms-5 mt-5 mb-5'}>
            <div className="divTituloAmpliaciones align-items-center ps-5">
                <h3 className='tituloAmpliaciones m-0'>SOLICITUD DOCUMENTACION AMPLIACION</h3>
            </div>
            <div className="row mt-3 pe-5 ps-5">
                <div className="row mt-3 pe-5">
                    {expediente.ampliacionCedulaVerde === 1 ? <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('automotor').click() }}>
                        <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                        <label htmlFor="automotor" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>DENUNCIA ADMINISTRATIVA</label>
                        <input type="file" name="" multiple id="automotor" style={{ display: 'none' }}
                            onChange={(e) => {
                                setDoc((prevState) => ({
                                    ...prevState,
                                    automotor: e.target.files,
                                }))
                            }} />
                        <div className="col m-0">
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[0]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[1]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[2]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[3]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[4]?.name}</p>
                        </div>
                    </div> : <div className='col-3'></div>}
                    {expediente.ampliacionDni === 1 ? <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('cobertura').click() }}>
                        <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                        <label htmlFor="cobertura" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>CERTIFICADO DE COBERTURA</label>
                        <input type="file" multiple name="" id="cobertura" style={{ display: 'none' }}
                            onChange={(e) => {
                                setDoc((prevState) => ({
                                    ...prevState,
                                    cobertura: e.target.files,
                                }))
                            }} />
                        <div className="col m-0">
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cobertura[0]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cobertura[1]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cobertura[2]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cobertura[3]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cobertura[4]?.name}</p>
                        </div>
                    </div> : <div className='col-3'></div>}
                    {expediente.ampliacionRegistro === 1 ? <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('dniA').click() }}>
                        <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                        <label htmlFor="dniA" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>DNI</label>
                        <input type="file" name="" id="dniA" multiple style={{ display: 'none' }}
                            onChange={(e) => {
                                setDoc((prevState) => ({
                                    ...prevState,
                                    dni: e.target.files,
                                }))
                            }} />
                        <div className="col m-0">
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[0]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[1]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[2]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[3]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[4]?.name}</p>
                        </div>
                    </div> : <div className='col-3'></div>}
                    {expediente.ampliacionFotosDanio === 1 ? <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('registroA').click() }}>
                        <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                        <label htmlFor="registroA" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>REGISTRO</label>
                        <input type="file" name="" id="registroA" multiple style={{ display: 'none' }}
                            onChange={(e) => {
                                setDoc((prevState) => ({
                                    ...prevState,
                                    registro: e.target.files,
                                }))
                            }} />
                        <div className="col m-0">
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[0]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[1]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[2]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[3]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[4]?.name}</p>
                        </div>
                    </div> : <div className='col-3'></div>}
                </div>
                <div className="row mt-2 pe-5">
                    {expediente.ampliacionPresupuesto === 1 ? <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('cedulaA').click() }}>
                        <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                        <label htmlFor="cedulaA" name='frenteCedula' id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>CÉDULA VERDE/TITULO AUTOMOTOR</label>
                        <input type="file" id="cedulaA" multiple style={{ display: 'none' }}
                            onChange={(e) => {
                                setDoc((prevState) => ({
                                    ...prevState,
                                    cedula: e.target.files,
                                }))
                            }} />
                        <div className="col m-0">
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[0]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[1]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[2]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[3]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[4]?.name}</p>
                        </div>
                    </div> : <div className='col-3'></div>}
                    {expediente.ampliacionMail === 1 ? <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('fotos').click() }}>
                        <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                        <label htmlFor="fotos" id="labelDoc" className="center mt-1 text-center" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>FOTOS DE LOS DAÑOS<br /> (UNA CON PATENTE VISIBLE CON DAÑO)</label>
                        <input type="file" name="" id="fotos" multiple style={{ display: 'none' }}
                            onChange={(e) => {
                                setDoc((prevState) => ({
                                    ...prevState,
                                    fotos: e.target.files,
                                }))
                            }} />
                        <div className="col m-0">
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotos[0]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotos[1]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotos[2]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotos[3]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotos[4]?.name}</p>
                        </div>
                    </div> : <div className='col-3'></div>}
                    {expediente.ampliacionCerificado08 === 1 ? <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('franquicia').click() }}>
                        <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                        <label htmlFor="franquicia" id="labelDoc" className="center mt-1 text-center" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>CARTA DE FRANQUICIA <br />(EN CASO DE COBERTURA TODO RIESGO)</label>
                        <input type="file" multiple name="" id="franquicia" style={{ display: 'none' }}
                            onChange={(e) => {
                                setDoc((prevState) => ({
                                    ...prevState,
                                    franquicia: e.target.files,
                                }))
                            }}/>
                        <div className="col m-0">
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.franquicia[0]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.franquicia[1]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.franquicia[2]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.franquicia[3]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.franquicia[4]?.name}</p>
                        </div>
                    </div> : <div className='col-3'></div>}
                    {expediente.ampliacionTelefono === 1 ? <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('vtv').click() }}>
                        <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                        <label htmlFor="vtv" id="labelDoc" className="center text-center" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>PRESUPUESTO <br /> (OPCIONAL, EN CASO DE NO POSEER EL ESTUDIO CONFECCIONARÁ UNO DE FORMA GRATUITA PARA EL CLIENTE)</label>
                        <input type="file" name="" id="vtv" multiple style={{ display: 'none' }}
                            onChange={(e) => {
                                setDoc((prevState) => ({
                                    ...prevState,
                                    vtv: e.target.files,
                                }))
                            }} />
                        <div className="col m-0">
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.vtv[0]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.vtv[1]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.vtv[2]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.vtv[3]?.name}</p>
                            <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.vtv[4]?.name}</p>
                        </div>
                    </div> : <div className='col-3'></div>}
                </div>
            </div>
            <div className='center mt-3'>
                <button className='btn btn-primary' onClick={submit}>Enviar Documentacion</button>
            </div>
            {carga ?
                <div className="center mt-5">
                    <div class="spinner-border text-primary center" role="status">
                        <span class="visually-hidden center">Loading...</span>
                    </div>
                </div> : <div></div>}
        </div>
    )
}