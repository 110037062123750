import { useNavigate } from "react-router-dom";
export default function SesionOut() {
    const navigate = useNavigate();
    function a() {
        navigate('/login');
    }
    a()
    return (
        <>
        </>
    );
};
