import {useContext} from "react";
import SeccionCobroEditar from "./SeccionCobroEditar";
import SeccionCobroLeer from "./SeccionCobroLeer";
import { editarContext } from "../../../context/Editar";
export default function SeccionCobro ({expediente}) {
    const {editar} = useContext(editarContext)
    return (
        <div className="m-0 p-3 bordeContainerPlanilla">
            {editar ?
                <SeccionCobroEditar expediente={expediente}/>
                :
                <SeccionCobroLeer expediente={expediente}/>}
        </div>
    )
}