import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import "react-circular-progressbar/dist/styles.css";
export default function NivelMediacion({ nivel }) {
    let color = 'transparent'
    let porcentaje = '0'
    if (Number(nivel) === 1) {
        color = 'green'
        porcentaje = 25
    } else if (Number(nivel) === 2) {
        color = 'yellow'
        porcentaje = 50
    } else if (Number(nivel) === 3) {
        color = 'red'
        porcentaje = 100
    }
    return (
        <>
            {window.innerWidth > 1920 ?
            <div className=''>
            <div style={{ width: '30px' }}>
                <CircularProgressbar value={porcentaje} circleRatio={0.6} styles={buildStyles({ rotation: 0.7, pathColor: `${color}` })} />
            </div>
        </div>
        :
        <div className=''>
            <div style={{ width: '20px' }}>
                <CircularProgressbar value={porcentaje} circleRatio={0.6} styles={buildStyles({ rotation: 0.7, pathColor: `${color}` })} />
            </div>
        </div>}
        </>
    )
}