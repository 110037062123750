import axios from "axios";
import { useCallback, useState, useEffect } from "react";
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import './index.css'
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
export default function AgregarExpPas() {
    const navigate = useNavigate()
    const a = localStorage.getItem('user');
    if (a === null) {
        navigate('/login')
    }
    const [expedientes, setExpedientes] = useState([]);
    const [companias, setCompanias] = useState([])
    const [productores, setProductores] = useState([])
    const [carga, setCarga] = useState(false)
    useEffect(() => {
        axios.get("https://api.tuveunchoque.com.ar/api/crearExp/allExp").then((res) => {
            setExpedientes(res.data.reverse());
        });
        axios.get('https://api.tuveunchoque.com.ar/api/gerencia/companias').then((res) => {
            setCompanias(res.data);
        });
        axios.get('https://api.tuveunchoque.com.ar/api/gerencia/pas').then((res) => {
            setProductores(res.data);
        });
    }, []);
    const [hayLes, setHayLes] = useState({ les: null })
    const [cookies, setCookie, removeCookie] = useCookies(['user', 'agregarExpPas']);
    const [expedienteData, setExpedienteData] = useState({
        estado: "RECEPCIONADO", nombre: '', dominio: '', pas: cookies.user?.nombre, honorarios: '', tel: '', mail: '', dominioReq: '', compReq: '', fechaStro: '', infoAdicional: ''
    });
    const regEx = /(^[a-zA-Z]{3}[-]{0,1}[0-9]{3,4}$)|(^[a-zA-Z]{2}[-]{0,1}[0-9]{3}[a-zA-Z]{1,2}$)|(^[0-9]{3}[-]{0,1}[a-zA-Z]{3,4}$)|(^[a-zA-Z]{1}[-]{0,1}[0-9]{3}[a-zA-Z]{3}$)/
    const divInfo = { height: "105px", width: "100%" };
    const [doc, setDoc] = useState({ cedula: [], registro: [], dni: [], automotor: [], vtv: [], cobertura: [], franquicia: [], fotos: [], docMedica: [], fotosLes: [], denunciaPolicial: [] })
    const lesionesInput = ['SI', 'NO'];
    const mailData = new FormData();
    for (var i = 0; i < doc.dni.length; i++) {
        mailData.append(`dni${i}`, doc.dni[i]);
    }
    for (var i = 0; i < doc.cedula.length; i++) {
        mailData.append(`cedula${i}`, doc.cedula[i]);
    }
    for (var i = 0; i < doc.registro.length; i++) {
        mailData.append(`registro${i}`, doc.registro[i]);
    }
    for (var i = 0; i < doc.fotos.length; i++) {
        mailData.append(`fotos${i}`, doc.fotos[i]);
    }
    for (var i = 0; i < doc.vtv.length; i++) {
        mailData.append(`vtv${i}`, doc.vtv[i]);
    }
    for (var i = 0; i < doc.docMedica.length; i++) {
        mailData.append(`docMedica${i}`, doc.docMedica[i]);
    }
    for (var i = 0; i < doc.fotosLes.length; i++) {
        mailData.append(`fotosLes${i}`, doc.fotosLes[i]);
    }
    for (var i = 0; i < doc.denunciaPolicial.length; i++) {
        mailData.append(`denunciaPolicial${i}`, doc.denunciaPolicial[i]);
    }
    for (var i = 0; i < doc.automotor.length; i++) {
        mailData.append(`automotor${i}`, doc.automotor[i]);
    }
    for (var i = 0; i < doc.cobertura.length; i++) {
        mailData.append(`cobertura${i}`, doc.cobertura[i]);
    }
    for (var i = 0; i < doc.franquicia.length; i++) {
        mailData.append(`franquicia${i}`, doc.franquicia[i]);
    }
    const hoy = new Date().toISOString().split('T')[0];
    const expedienteDataCreate = useCallback(async (e) => {
        e.preventDefault()
        setCarga(true)
        const response = await axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/expedientes/nuevo/pas`, {
            fechaStro: expedienteData.fechaStro,
            estado: expedienteData.estado,
            honorarios: expedienteData.honorarios,
            infoAdicional: expedienteData.infoAdicional,
            nombre: expedienteData.nombre,
            dominio: expedienteData.dominio,
            tel: expedienteData.tel,
            mail: expedienteData.mail,
            compReq: expedienteData.compReq,
            dominioReq: expedienteData.dominioReq,
            fechaExp: hoy,
            pas: expedienteData.pas,
            modificadoPor: cookies.user.nombre,
            vencimiento: new Date().toLocaleDateString('fr-FR'),
            prioridad: hayLes.les === 'SI' ? 'ALTA' : ''
        }).then(async (response) => {
            if (response.status === 200) {
                await axios.post(`https://api.tuveunchoque.com.ar/api/mail/recepcionado`, {
                    nro: response.data.nro,
                    pas: response.data.productorAsociado,
                    honorariosPas: response.data.honorarios,
                    patente: response.data.dominioCliente
                })
                await axios.post(`https://api.tuveunchoque.com.ar/api/mail/recepcionadoPas`, {
                    nro: response.data.nro,
                    pas: response.data.productorAsociado,
                    honorariosPas: response.data.honorarios,
                    patente: response.data.dominioCliente
                })
                await axios.post(`https://api.tuveunchoque.com.ar/api/mail/allNuevoExp?nro=${response.data.nro}`, mailData, { headers: { "Content-Type": "multipart/form-data" } })
                await axios.post(`https://api.tuveunchoque.com.ar/api/mail/allNuevoExpGuardar?nro=${response.data.nro}`, mailData, { headers: { "Content-Type": "multipart/form-data" } })
                setTimeout(() => {
                    Swal.fire(
                        'Expediente creado!',
                        `<p>Nro: ${response.data.nro}<br/>Nombre: ${response.data.nombre}<br/>Patente: ${response.data.dominioCliente}<br/>Compañia: ${response.data.compania}<br/>Productor: ${response.data.productorAsociado}<br/>Honorarios: ${response.data.honorarios}<br/>Estado: Recepcionado</p>`,
                        'success'
                    ).then(() => { window.location.reload() })
                }, 500);
            }
        })
    }, [expedienteData, mailData]);
    const m = expedientes.find(e => e.dominioCliente === expedienteData.dominio && e.fechaStro === expedienteData.fechaStro)
    function dom() {
        if (m !== undefined) {
            return <div className="mt-3">
                <h6 className="letra">Detectamos que la patente y fecha de siniestro ingresadas, coinciden con un expediente subido previamente.</h6>
                <h6 className="letra">Favor de comunicarse con <a href="mailto:recepcion@tuveunchoque.com.ar">recepcion@tuveunchoque.com.ar</a></h6>
            </div>
        }
    }
    const m2 = expedientes.find(e => e.dominioCliente === expedienteData.dominioReq && e.fechaStro === expedienteData.fechaStro && e.patenteRequerido === expedienteData.dominio)
    function dom2() {
        if (m2 !== undefined) {
            return <div className="mt-3">
                <h6 className="letra">Estimados, por inconvenientes en este siniestro, solicitamos que envie la informacion pertinente a la casilla <a href="mailto:recepcion@tuveunchoque.com.ar">recepcion@tuveunchoque.com.ar</a></h6>
            </div>
        }
    }
    const [active, setActive] = useState(1);
    const submitExp = (e) => {
        e.preventDefault()
        setActive(2)
    }
    const submitCli = (e) => {
        e.preventDefault()
        setActive(3)
    }
    const submitReq = (e) => {
        e.preventDefault()
        setActive(4)
    }
    const submitLes = (e) => {
        e.preventDefault()
        setActive(5)
    }
    const honorariosInput = [{ label: 0, value: 0 }, { label: 5, value: 5 }, { label: 10, value: 10 }, { label: 15, value: 15 }, { label: 20, value: 20 }]
    const ActiveView = () => {
        switch (active) {
            case 1:
                return <form className="contenedorPestaniaAgregarExp" onSubmit={(e) => submitExp(e)}>
                    <h2 className="tituloPestania">INGRESO DE EXPEDIENTES</h2>
                    <div className="mt-3">
                        <label className="labelPestania">¿Cuándo fue el choque? (Fecha de Siniestro) (*)</label>
                        <input
                            type="date"
                            class="form-control w-50"
                            required
                            value={expedienteData.fechaStro}
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    fechaStro: e.target.value,
                                }))
                            }
                        />
                    </div>
                    <div>
                        <label className="labelPestania">¿Se cobran honorarios? (*)</label>
                        <Select
                            required
                            placeholder={expedienteData.honorarios ? expedienteData.honorarios : "Indicar % de ser cierto. En caso contrario, dejar vacío"}
                            className="w-50"
                            options={honorariosInput}
                            onChange={(newValue) => setExpedienteData((prevState) => ({
                                ...prevState,
                                honorarios: newValue.value,
                            }))}
                        />
                    </div>
                    <div>
                        <label className="labelPestania">¿Algún comentario que quiera agregar?</label>
                        <textarea
                            className="form-control w-50"
                            style={divInfo}
                            placeholder="Información adicional"
                            value={expedienteData.infoAdicional}
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    infoAdicional: e.target.value,
                                }))}
                        />
                    </div>
                    <div className="mt-3 d-flex justify-content-end pe-5 me-5">
                        <button className="btn btn-primary" type="submit">SIGUIENTE</button>
                    </div>
                </form>;
            case 2:
                return <form className="contenedorPestaniaAgregarExp" onSubmit={(e) => submitCli(e)}>
                    <h4 className="tituloPestania">DATOS DEL CLIENTE</h4>
                    <div>
                        <label className="labelPestania">¿Cómo se llama el cliente? (*)</label>
                        <input type="text" class="form-control w-50" value={expedienteData.nombre}
                            required
                            placeholder="Nombre Asegurado"
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    nombre: e.target.value,
                                }))} />
                    </div>
                    <div>
                        <label className="labelPestania">¿Cuál es la patente de tu asegurado? (*)</label>
                        <input type="text" class="form-control w-50" value={expedienteData.dominio}
                            required
                            placeholder="Patente Asegurado"
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    dominio: e.target.value,
                                }))} />
                    </div>
                    <div>
                        <label className="labelPestania">Dejános un teléfono de contacto</label>
                        <input type="tel" class="form-control w-50" value={expedienteData.tel}
                            placeholder="Teléfono de contacto"
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    tel: e.target.value,
                                }))} />
                    </div>
                    <div>
                        <label className="labelPestania">Mail de contacto</label>
                        <input type="mail" class="form-control w-50" value={expedienteData.mail} placeholder="(Optativo)"
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    mail: e.target.value,
                                }))} />
                    </div>
                    <div className={m ? 'd-none' : "mt-3 d-flex justify-content-end pe-5 me-5"}>
                        <button disabled={!regEx.test(expedienteData.dominio) ? true : false} className="btn btn-primary" type="submit">SIGUIENTE</button>
                    </div>
                    {expedienteData.dominio ? <p className={!regEx.test(expedienteData.dominio) ? "text-danger center mt-1" : 'd-none'}>El formato de la patente en Dominio es incorrecto</p> : <></>}
                    {dom()}
                </form>;
            case 3:
                return <form className="contenedorPestaniaAgregarExp" onSubmit={(e) => submitReq(e)}>
                    <h4 className="tituloPestania">DATOS DEL REQUERIDO</h4>
                    <div>
                        <label className="labelPestania">¿Sabemos a qué compañía reclamar? (*)</label>
                        <select
                            id="compReq"
                            name="compReq"
                            value={expedienteData.compReq}
                            className="form-select w-50"
                            required
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    compReq: e.target.value,
                                }))
                            }
                        >
                            <option disabled selected value="">
                                -- Elige una opción --
                            </option>
                            {companias.sort((a, b) => a.compania.localeCompare(b.compania)).map((comp) => {
                                return <option value={comp['ID']}>{comp.compania}</option>
                            })}
                        </select>
                        <p>(Elegir compañía, en caso de no figurar, seleccionar "AVERIGUAR")</p>
                    </div>
                    <div>
                        <label className="labelPestania">Escriba patente del tercero responsable</label>
                        <input type="text" class="form-control w-50" value={expedienteData.dominioReq}
                            placeholder="En caso de no tenerla, dejar vacío"
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    dominioReq: e.target.value,
                                }))} />
                        <p>(En caso de no tener tercero, escribir "NO TIENE")</p>
                    </div>
                    <div className={m2 ? 'd-none' : "mt-3 d-flex justify-content-end pe-5 me-5"}>
                        <button disabled={!regEx.test(expedienteData.dominio) ? true : false} className="btn btn-primary" type="submit">SIGUIENTE</button>
                    </div>
                    {dom2()}
                </form>;
            case 4:
                return <form className="contenedorPestaniaAgregarExp" onSubmit={(e) => submitLes(e)}>
                    <label className="labelPestania">¿Hay Lesiones? (*)</label>
                    <Select
                        required
                        options={lesionesInput.map(les => ({ value: les, label: les }))}
                        className="w-50"
                        onChange={(newValue) => setHayLes((prevState) => ({
                            ...prevState,
                            les: newValue.value,
                        }))}
                    />
                    <div className={hayLes.les === 'SI' ? 'mt-5' : 'd-none'}>
                        <h5 className="tituloPestania">ADJUNTAR DOCUMENTACIÓN</h5>
                        <h6 className="subtituloPestania">FORMATOS: JPG, PNG, JPEG, PDF</h6>
                        <h6 className="text-danger">(Los archivos en su conjunto no pueden ser mayor que 25mb)</h6>
                        <div className="row mt-3 pe-5">
                            <div className="col-4 divAdjuntosLes mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('docMedica').click(); }}>
                                <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                                <label htmlFor="docMedica" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>DOCUMENTAL MEDICA</label>
                                <p className="center m-0">(RADIOGRAFIAS - CERTIFICADOS MEDICOS - HISTORIA CLINICA)</p>
                                <input type="file" name="" id="docMedica" multiple style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setDoc((prevState) => ({
                                            ...prevState,
                                            docMedica: e.target.files,
                                        }))
                                    }} />
                                <div className="col m-0">
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.docMedica[0]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.docMedica[1]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.docMedica[2]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.docMedica[3]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.docMedica[4]?.name}</p>
                                </div>
                            </div>
                            <div className="col-4 divAdjuntosLes mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('fotosLes').click() }}>
                                <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                                <label htmlFor="fotosLes" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>FOTOS DE LESIONES</label>
                                <input type="file" name="" id="fotosLes" multiple style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setDoc((prevState) => ({
                                            ...prevState,
                                            fotosLes: e.target.files,
                                        }))
                                    }} />
                                <div className="col mt-4 m-0">
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotosLes[0]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotosLes[1]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotosLes[2]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotosLes[3]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotosLes[4]?.name}</p>
                                </div>
                            </div>
                            <div className="col-4 divAdjuntosLes mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('denunciaPolicial').click() }}>
                                <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                                <label htmlFor="denunciaPolicial" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>DENUNCIA POLICIAL</label>
                                <input type="file" name="" id="denunciaPolicial" multiple style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setDoc((prevState) => ({
                                            ...prevState,
                                            denunciaPolicial: e.target.files,
                                        }))
                                    }} />
                                <div className="col mt-4 m-0">
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.denunciaPolicial[0]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.denunciaPolicial[1]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.denunciaPolicial[2]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.denunciaPolicial[3]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.denunciaPolicial[4]?.name}</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 d-flex justify-content-end pe-5 me-5">
                            <button className="btn btn-primary" type="submit">SIGUIENTE</button>
                        </div>
                    </div>
                    <div className={hayLes.les === 'SI' ? 'd-none' : 'mt-3'}>
                        <div className="mt-3 d-flex justify-content-end pe-5 me-5">
                            <button className="btn btn-primary" type="submit">SIGUIENTE</button>
                        </div>
                    </div>
                </form>
            case 5:
                return <div className="contenedorPestaniaAgregarExp">
                    <h5 className="tituloPestania m-0">ADJUNTAR DOCUMENTACIÓN</h5>
                    <h6 className="subtituloPestania m-0">FORMATOS: JPG, PNG, JPEG, PDF</h6>
                    <h6 className="text-danger">(Los archivos en su conjunto no pueden ser mayor que 25mb)</h6>
                    <div className="row mt-3 pe-5">
                        <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('automotor').click() }}>
                            <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                            <label htmlFor="automotor" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>DENUNCIA ADMINISTRATIVA</label>
                            <input type="file" name="" id="automotor" multiple style={{ display: 'none' }}
                                onChange={(e) => {
                                    setDoc((prevState) => ({
                                        ...prevState,
                                        automotor: e.target.files,
                                    }))
                                }} />
                            <div className="col m-0">
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[0]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[1]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[2]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[3]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[4]?.name}</p>
                            </div>
                        </div>
                        <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('cobertura').click() }}>
                            <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                            <label htmlFor="cobertura" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>CERTIFICADO DE COBERTURA</label>
                            <input type="file" name="" id="cobertura" multiple style={{ display: 'none' }}
                                onChange={(e) => {
                                    setDoc((prevState) => ({
                                        ...prevState,
                                        cobertura: e.target.files,
                                    }))
                                }} />
                            <div className="col m-0">
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cobertura[0]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cobertura[1]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cobertura[2]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cobertura[3]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cobertura[4]?.name}</p>
                            </div>
                        </div>
                        <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('dniA').click() }}>
                            <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                            <label htmlFor="dniA" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>DNI</label>
                            <input type="file" name="" id="dniA" multiple style={{ display: 'none' }}
                                onChange={(e) => {
                                    setDoc((prevState) => ({
                                        ...prevState,
                                        dni: e.target.files,
                                    }))
                                }} />
                            <div className="col m-0">
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[0]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[1]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[2]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[3]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[4]?.name}</p>
                            </div>
                        </div>
                        <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('registroA').click() }}>
                            <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                            <label htmlFor="registroA" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>REGISTRO</label>
                            <input type="file" name="" id="registroA" multiple style={{ display: 'none' }}
                                onChange={(e) => {
                                    setDoc((prevState) => ({
                                        ...prevState,
                                        registro: e.target.files,
                                    }))
                                }} />
                            <div className="col m-0">
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[0]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[1]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[2]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[3]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[4]?.name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2 pe-5">
                        <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('cedulaA').click() }}>
                            <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                            <label htmlFor="cedulaA" name='frenteCedula' id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>CÉDULA VERDE/TITULO AUTOMOTOR</label>
                            <input type="file" id="cedulaA" multiple style={{ display: 'none' }}
                                onChange={(e) => {
                                    setDoc((prevState) => ({
                                        ...prevState,
                                        cedula: e.target.files,
                                    }))
                                }} />
                            <div className="col m-0">
                                <div className="col m-0">
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[0]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[1]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[2]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[3]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[4]?.name}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('fotos').click() }}>
                            <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                            <label htmlFor="fotos" id="labelDoc" className="center mt-1 text-center" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>FOTOS DE LOS DAÑOS<br /> (UNA CON PATENTE VISIBLE CON DAÑO)</label>
                            <input type="file" name="" id="fotos" multiple style={{ display: 'none' }}
                                onChange={(e) => {
                                    setDoc((prevState) => ({
                                        ...prevState,
                                        fotos: e.target.files,
                                    }))
                                }} />
                            <div className="col m-0">
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotos[0]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotos[1]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotos[2]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotos[3]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotos[4]?.name}</p>
                            </div>
                        </div>
                        <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('franquicia').click() }}>
                            <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                            <label htmlFor="franquicia" id="labelDoc" className="center mt-1 text-center" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>CARTA DE FRANQUICIA <br />(EN CASO DE COBERTURA TODO RIESGO)</label>
                            <input type="file" name="" multiple id="franquicia" style={{ display: 'none' }}
                                onChange={(e) => {
                                    setDoc((prevState) => ({
                                        ...prevState,
                                        franquicia: e.target.files,
                                    }))
                                }} />
                            <div className="col m-0 ">
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.franquicia[0]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.franquicia[1]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.franquicia[2]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.franquicia[3]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.franquicia[4]?.name}</p>
                            </div>
                        </div>
                        <div className="col-3 divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('vtv').click() }}>
                            <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                            <label htmlFor="vtv" id="labelDoc" className="center text-center" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>PRESUPUESTO <br /> (OPCIONAL, EN CASO DE NO POSEER EL ESTUDIO CONFECCIONARÁ UNO DE FORMA GRATUITA PARA EL CLIENTE)</label>
                            <input type="file" name="" id="vtv" multiple style={{ display: 'none' }}
                                onChange={(e) => {
                                    setDoc((prevState) => ({
                                        ...prevState,
                                        vtv: e.target.files,
                                    }))
                                }} />
                            <div className="col m-0">
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.vtv[0]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.vtv[1]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.vtv[2]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.vtv[3]?.name}</p>
                                <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.vtv[4]?.name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 d-flex justify-content-end pe-5 me-5">
                        <button className="btn btn-primary" onClick={(e) => expedienteDataCreate(e)} disabled={carga ? true : false}>CARGAR</button>
                        {carga ?
                            <div className="center">
                                <div class="spinner-border text-primary center" role="status">
                                    <span class="visually-hidden center">Loading...</span>
                                </div>
                            </div> : <div></div>}
                    </div>
                </div>;
            default:
                return <div className="">
                    <h4>INGRESO DE EXPEDIENTES</h4>
                    <div>
                        <label htmlFor="">¿Cuándo fue el choque? (Fecha de Siniestro)</label>
                        <input
                            type="date"
                            class="form-control"
                            required
                            value={expedienteData.fechaStro}
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    fechaStro: e.target.value,
                                }))
                            }
                        />
                    </div>
                    <div>
                        <label htmlFor="">¿Se cobran honorarios?</label>
                        <input type="text" class="form-control" value={expedienteData.honorarios}
                            required
                            placeholder="Indicar % de ser cierto. En caso contrario, dejar vacío"
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    honorarios: e.target.value,
                                }))} />
                    </div>
                    <div>
                        <label htmlFor="">¿Algún comentario que quiera agregar?</label>
                        <textarea
                            className="form-control"
                            style={divInfo}
                            placeholder="Información adicional"
                            value={expedienteData.infoAdicional}
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    infoAdicional: e.target.value,
                                }))}
                        />
                    </div>
                </div>
        }
    };
    return (
        <main className="center mainCrearExp">
            {cookies.user ? <div className="agregarExpPasContainer">
                <div className="d-flex">
                    {active > 1 ? <div className={active === 1 ? 'btnCambiarPestaniaActivoExp' : 'btnCambiarPestaniaExp'}>
                        <button className="btnCambioPlanilla" onClick={(e) => setActive(1)}>EXPEDIENTE</button>
                    </div> : <div className={active === 1 ? 'btnCambiarPestaniaActivoExp' : 'btnCambiarPestaniaExp'}>
                        <button className="btnCambioPlanilla">EXPEDIENTE</button>
                    </div>}
                    {active >= 2 ? <div className={active === 2 ? 'btnCambiarPestaniaActivo' : 'btnCambiarPestania'}>
                        <button className="btnCambioPlanilla" onClick={(e) => setActive(2)}>CLIENTE</button>
                    </div> : <div className={active === 2 ? 'btnCambiarPestaniaActivo' : 'btnCambiarPestania'}>
                        <button className="btnCambioPlanilla">CLIENTE</button>
                    </div>}
                    {active >= 3 ? <div className={active === 3 ? 'btnCambiarPestaniaActivo' : 'btnCambiarPestania'}>
                        <button className="btnCambioPlanilla" onClick={(e) => setActive(3)}>REQUERIDO</button>
                    </div> : <div className={active === 3 ? 'btnCambiarPestaniaActivo' : 'btnCambiarPestania'}>
                        <button className="btnCambioPlanilla">REQUERIDO</button>
                    </div>}
                    {active >= 4 ? <div className={active === 4 ? 'btnCambiarPestaniaActivo' : 'btnCambiarPestania'}>
                        <button className="btnCambioPlanilla" onClick={(e) => setActive(4)}>LESIONES</button>
                    </div> : <div className={active === 4 ? 'btnCambiarPestaniaActivo' : 'btnCambiarPestania'}>
                        <button className="btnCambioPlanilla">LESIONES</button>
                    </div>}
                    <div className={active === 5 ? 'btnCambiarPestaniaActivoReq' : 'btnCambiarPestaniaReq'}>
                        <button className="btnCambioPlanilla">DOCUMENTOS</button>
                    </div>
                </div>
                {ActiveView()}
            </div> : <div></div>}
        </main>
    );
}
