import { useState } from 'react';
import '../index.css'
import axios from 'axios'
import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
export default function Ampliaciones({ expediente }) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [check, setChecked] = useState({ cedula: expediente.ampliacionCedulaVerde === 1 ? true : false, dni: expediente.ampliacionDni === 1 ? true : false, registro: expediente.ampliacionRegistro === 1 ? true : false, fotos: expediente.ampliacionFotosDanio === 1 ? true : false, presupuesto: expediente.ampliacionPresupuesto === 1 ? true : false, mail: expediente.ampliacionMail === 1 ? true : false, cert: expediente.ampliacionCerificado08 === 1 ? true : false, tel: expediente.ampliacionTelefono === 1 ? true : false });
    const [docu, setDocu] = useState({ cedula: '', dni: '', registro: '', fotos: '', presupuesto: '', mail: '', cert: '', tel: '' })
    function handleChangeCedula(e) {
        setChecked((prevState) => ({ ...prevState, cedula: e.target.checked, }))
        if (!check.cedula) {
            setDocu((prevState) => ({
                ...prevState,
                cedula: 'Cedula \n',
            }))
        } else if (check.cedula) {
            setDocu((prevState) => ({
                ...prevState,
                cedula: '',
            }))
        }
    }
    function handleChangeDni(e) {
        setChecked((prevState) => ({ ...prevState, dni: e.target.checked, }))
        if (!check.dni) {
            setDocu((prevState) => ({
                ...prevState,
                dni: 'Dni \n',
            }))
        } else if (check.dni) {
            setDocu((prevState) => ({
                ...prevState,
                dni: '',
            }))
        }
    }
    function handleChangeRegistro(e) {
        setChecked((prevState) => ({ ...prevState, registro: e.target.checked, }))
        if (!check.registro) {
            setDocu((prevState) => ({
                ...prevState,
                registro: 'Registro \n',
            }))
        } else if (check.registro) {
            setDocu((prevState) => ({
                ...prevState,
                registro: '',
            }))
        }
    }
    function handleChangeFotos(e) {
        setChecked((prevState) => ({ ...prevState, fotos: e.target.checked, }))
        if (!check.fotos) {
            setDocu((prevState) => ({
                ...prevState,
                fotos: 'Fotos Daños \n',
            }))
        } else if (check.fotos) {
            setDocu((prevState) => ({
                ...prevState,
                fotos: '',
            }))
        }
    }
    function handleChangePresu(e) {
        setChecked((prevState) => ({ ...prevState, presupuesto: e.target.checked, }))
        if (!check.presupuesto) {
            setDocu((prevState) => ({
                ...prevState,
                presupuesto: 'Presupuesto \n',
            }))
        } else if (check.presupuesto) {
            setDocu((prevState) => ({
                ...prevState,
                presupuesto: '',
            }))
        }
    }
    function handleChangeMail(e) {
        setChecked((prevState) => ({ ...prevState, mail: e.target.checked, }))
        if (!check.mail) {
            setDocu((prevState) => ({
                ...prevState,
                mail: 'Mail \n',
            }))
        } else if (check.mail) {
            setDocu((prevState) => ({
                ...prevState,
                mail: '',
            }))
        }
    }
    function handleChangeCert(e) {
        setChecked((prevState) => ({ ...prevState, cert: e.target.checked, }))
        if (!check.cert) {
            setDocu((prevState) => ({
                ...prevState,
                cert: 'Certificado 08 \n',
            }))
        } else if (check.cert) {
            setDocu((prevState) => ({
                ...prevState,
                cert: '',
            }))
        }
    }
    function handleChangeTel(e) {
        setChecked((prevState) => ({ ...prevState, tel: e.target.checked, }))
        if (!check.tel) {
            setDocu((prevState) => ({
                ...prevState,
                tel: 'Telefono \n',
            }))
        } else if (check.tel) {
            setDocu((prevState) => ({
                ...prevState,
                tel: '',
            }))
        }
    }
    const [textarea, setTextarea] = useState({textearea: ''})
    const today = new Date()
    const now = today.toLocaleString()
    const solicitarAmpliacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacion`, {
            nro: expediente.nro,
            patente: expediente.dominioCliente,
            pas: expediente.productorAsociado,
            cedula: check.cedula ? 'Denuncia Admin\n' : '',
            dni: check.dni ? 'Certificado de Cobertura\n' : '',
            registro: check.registro ? 'Dni\n' : '',
            fotos: check.fotos ? 'Registro\n' : '',
            presupuesto: check.presupuesto ? 'Cedula Verde/Titulo Automotor\n' : '',
            mailA: check.mail ? 'Fotos Daños\n' : '',
            cert: check.cert ? 'Carta Franquicia\n' : '',
            tel: check.tel ? 'Presupuesto\n' : '',
            textarea: textarea.textearea ? `${textarea.textearea}\n` : '',
            sharepoint: expediente.sharepoint
        }).then(async (response) => {
            if (response.status === 200) {
                await axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Se solicitó documentación',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionAct`, {
                    id: expediente.id,
                    cedula: check.cedula,
                    dni: check.dni,
                    registro: check.registro,
                    fotos: check.fotos,
                    presupuesto: check.presupuesto,
                    mailA: check.mail,
                    cert: check.cert,
                    tel: check.tel,
                })
                setTimeout(async () => {
                    window.location.reload()
                }, 1000);
            }
        })
    }, [expediente, check, textarea]);
    const divInfo = { height: "100px", width: "100%" };
    return (
        <div className='sectionAmpliaciones col p-0 me-2'>
            <div className="divTituloAmpliaciones d-flex align-items-center ps-5">
                <h3 className='tituloAmpliaciones m-0'>AMPLIACIONES</h3>
            </div>
            <section className='sectionItemsAmpliaciones mt-3'>
                <div className="row">
                    <div className="col-6">
                        <div className='divCheckboxAmpliaciones form-check form-switch'>
                            <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeCedula} checked={check.cedula} />
                            <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Denuncia Admin</label>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className='divCheckboxAmpliaciones form-check form-switch'>
                            <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeDni} checked={check.dni} />
                            <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Certificado de Cobertura</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className='divCheckboxAmpliaciones form-check form-switch'>
                            <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeRegistro} checked={check.registro} />
                            <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Dni</label>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className='divCheckboxAmpliaciones form-check form-switch'>
                            <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeFotos} checked={check.fotos} />
                            <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Registro</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className='divCheckboxAmpliaciones form-check form-switch'>
                            <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangePresu} checked={check.presupuesto} />
                            <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Cedula Verde/Titulo Automotor</label>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className='divCheckboxAmpliaciones form-check form-switch'>
                            <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeMail} checked={check.mail} />
                            <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Fotos Daños</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className='divCheckboxAmpliaciones form-check form-switch'>
                            <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeCert} checked={check.cert} />
                            <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Carta Franquicia</label>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className='divCheckboxAmpliaciones form-check form-switch'>
                            <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={handleChangeTel} checked={check.tel} />
                            <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">Presupuesto</label>
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className="col-8">
                        <textarea
                            className="form-control"
                            style={divInfo}
                            onChange={(e) =>
                                setTextarea((prevState) => ({
                                    ...prevState,
                                    textearea: e.target.value,
                                }))}
                        />
                    </div>
                    <div className="col-4">
                        <button className='botonEnviarAmpliacion w-75 center pbtn' onClick={(e) => solicitarAmpliacion(e)}>SOLICITAR AMPLIACION</button>
                    </div>
                </div>
            </section>
        </div>
    )
}