import SeccionExpedienteEditar from "./SeccionExpedienteEditar";
import SeccionExpedienteLeer from "./SeccionExpedienteLeer";
import { editarContext } from "../../../context/Editar";
import { useContext} from "react";
export default function SeccionExpediente({expediente}) {
    const {editar} = useContext(editarContext)
    return (
        <div className='m-0 p-3 bordeContainerPlanilla'>
            {
                editar ?
                    <SeccionExpedienteEditar expediente={expediente} />
                    :
                    <SeccionExpedienteLeer expediente={expediente}/>
            }
        </div>
    )
}