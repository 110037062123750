import { useCallback, useState, useEffect } from "react";
import axios from 'axios'
import { useCookies } from 'react-cookie';
export default function Niveles(niv) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const btn = { border: 'none', background: 'none' }
    const [edit, setEdit] = useState(false)
    const [com, setCom] = useState({ plazo: niv.niv.plazo, nivel: niv.niv.nivel})
    const nivUpdate = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/ajustes/niveles/update`,
            {
                id: niv.niv.id,
                plazo: com.plazo,
                nivel: com.nivel
            }
        ).then((response) => {
            if (response) {
                setTimeout(() => {
                    window.location.reload()
                }, 10);
            }
        })
    }, [com]);
    return (
        <tr className='letra'>
            <td class="align-middle">
                <h6 className="mb-0 ps-3">{niv.niv.estado}</h6>
            </td>
            <td class="align-middle">
            {!edit ? <h6 class="mb-0 ps-3">{niv.niv.nivel}</h6> : <input type="number" class="form-control form-control-sm w-50" placeholder={niv.niv.nivel} onChange={(e) =>
                    setCom((prevState) => ({
                        ...prevState,
                        nivel: e.target.value,
                    }))}></input>}
            </td>
            <td class="align-middle">
                {!edit ? <h6 class="mb-0 ps-3">{niv.niv.plazo}</h6> : <input type="number" class="form-control form-control-sm w-50" placeholder={niv.niv.plazo} onChange={(e) =>
                    setCom((prevState) => ({
                        ...prevState,
                        plazo: e.target.value,
                    }))}></input>}
            </td>
            <td>{!edit ? <div></div> :
                <div className="d-flex">
                    <button className="btn btn-primary btn-sm me-2" onClick={(e) => nivUpdate()}>Actualizar</button>
                </div>}</td>
            <td>
                {!edit ? <button style={btn} onClick={((e) => setEdit(true))} className={cookies.user.cargo === 'GERENCIA' ? '' : 'd-none'}><i class="bi bi-pencil-square"></i></button> :
                    <div className="d-flex">
                        <button style={btn} onClick={((e) => setEdit(false))}><i class="bi bi-x-circle-fill"></i></button>
                    </div>}
            </td>
        </tr>
    )
}