import axios from "axios";
import "../../pages/index.css";
import "../../pages/estadisticas.css";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { MultiSelect } from "react-multi-select-component";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CompEstadisticas from "./Comp";
import PasEstadisticas from "./Pas";
export default function PasNuevoEstadisticas({ updatedList, updatedList1, infoTicket, comp, pas }) {
    // Promedio de capital entre los expedientes filtrados por pas en un rango de fecha
    let capitalPas = 0;
    let promedioCapitalPas = 0;
    updatedList.forEach((e) => { capitalPas += Number(e.capital); promedioCapitalPas += 1; });
    const a = capitalPas;
    // Prom A es el promedio de capital que genera el Pas
    const promA = capitalPas / promedioCapitalPas
    // Total y promedio hns cobrados
    let hnsPas = 0;
    let promedioHnsPas = 0;
    updatedList.forEach((e) => { hnsPas += Number(e.honorariosCobro); promedioHnsPas += 1; });
    const ab = hnsPas;
    // Prom AB es el promedio de hns cobrados por el pas
    const promAB = hnsPas / promedioHnsPas
    // GENERAL
    // Promedio de capital gral de expedientes filtrados en un rango de fecha
    let capitalPasGeneral = 0;
    let promedioCapitalPasGeneral = 0;
    updatedList1.forEach((e) => { capitalPasGeneral += Number(e.capital); promedioCapitalPasGeneral += 1; });
    // Prom B es el promedio de capital gral
    const promB = capitalPasGeneral / promedioCapitalPasGeneral
    const promedioCapitalPasConElGral = (promA * 100) / promB - 100;
    // Promedio de Hns gral de expedientes filtrados en un rango de fecha
    let hnsPasGeneral = 0;
    let promedioHnsPasGeneral = 0;
    updatedList1.forEach((e) => { hnsPasGeneral += Number(e.honorariosCobro); promedioHnsPasGeneral += 1; });
    // Prom BA es el promedio general de hns cobrados
    const promBA = hnsPasGeneral / promedioCapitalPasGeneral
    const promedioHnsPasConElGral = (promAB * 100) / promBA - 100;
    // Promedio de expedientes que fueron cobrados filtrados por pas en un rango de fechas
    let updatedList2 = updatedList;
    let expCobrados = 0;
    updatedList2.filter((e) => e.mesCobrado !== null).forEach((e) => { expCobrados += 1; });
    const c = expCobrados / updatedList.length;
    // promedio de expedientes en gral cobrados en un rango de fechas 
    let updatedList4 = updatedList1;
    let expCobradosGeneral = 0;
    updatedList4.filter((e) => e.mesCobrado !== null).forEach((e) => { expCobradosGeneral += 1; });
    const d = expCobrados * 100 / expCobradosGeneral;
    // nro de expedientes segun estado filtrado por Pas
    const cantDeExpedientesEnProcPas = updatedList.filter((e) => e.estado === 'EN PROCESO DE PAGO').length
    const cantDeExpedientesAceptacionPas = updatedList.filter((e) => e.estado === 'ACEPTACION').length
    const cantDeExpedientesPresSinResPas = updatedList.filter((e) => e.estado === 'PRES SIN RESOLUCION').length
    const cantDeExpedientesCobradoPas = updatedList.filter((e) => e.estado === 'COBRADO').length
    // cant de dias en estado Proc Pago Pas
    let duracionProcPagoPas = 0
    let cantProcDePagoConContadorPas = 0
    updatedList.forEach(e => {
        if (e.contadorProcesoPago) {
            duracionProcPagoPas += e.contadorProcesoPago
            cantProcDePagoConContadorPas += 1
        }
    });
    const e = duracionProcPagoPas / cantProcDePagoConContadorPas
    // cant de dias en estado Aceptacion Pas
    let duracionAceptacionPas = 0
    let cantAceptacionContadorPas = 0
    updatedList.forEach(e => {
        if (e.contadorAceptacion) {
            duracionAceptacionPas += e.contadorAceptacion
            cantAceptacionContadorPas += 1
        }
    });
    const f = duracionAceptacionPas / cantAceptacionContadorPas
    // cant de dias en estado Pres Sin Res Pas
    let duracionPresSinResPas = 0
    let cantPresSinResContadorPas = 0
    updatedList.forEach(e => {
        if (e.contadorPresSinRes) {
            duracionPresSinResPas += e.contadorPresSinRes
            cantPresSinResContadorPas += 1
        }
    });
    const g = duracionPresSinResPas / cantPresSinResContadorPas
    // Logica Para Seccion 3 Pas
    const cantDeExpedientesCerradoPas = updatedList.filter((e) => e.estado === 'CERRADO').length
    const cantDeExpedientesMediacionPas = updatedList.filter((e) => e.estado === 'MEDIACION').length
    const cantDeExpedientesNivel3Pas = updatedList.filter((e) => e.nivel == 3).length
    let updatedList5 = updatedList
    updatedList5.filter((e) => e.nivel === 3 || e.estado === 'CERRADO' || e.estado === 'MEDIACION').forEach(e => {
        duracionPresSinResPas += e.contadorPresSinRes
        cantPresSinResContadorPas += 1
    });
    let updatedList6 = updatedList1
    updatedList6.filter((e) => e.nivel === 3 || e.estado === 'CERRADO' || e.estado === 'MEDIACION').forEach(e => {
        duracionPresSinResPas += e.contadorPresSinRes
        cantPresSinResContadorPas += 1
    });
    const h = updatedList5.length * 100 / updatedList6.length
    let itemsPerPage = 25
    const [itemOffset, setItemOffset] = useState(0);
    const pageCount = Math.ceil(comp.filter(c => updatedList.filter(e => e.compania === c.compania).length > 0).length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % comp.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    }
    let itemsPerPage1 = 25
    const [itemOffset1, setItemOffset1] = useState(0);
    const pageCount1 = Math.ceil(pas.filter(c => updatedList1.filter(e => e.productorAsociado === c.nombre).length > 0).length / itemsPerPage1);
    const [currentPage1, setCurrentPage1] = useState(0);
    const handlePageClick1 = (event) => {
        const newOffset1 = (event.selected * itemsPerPage1) % pas.length;
        setItemOffset1(newOffset1);
        setCurrentPage1(event.selected);
    }
    const [sortA, setSortA] = useState({ sort: 'NRO ↑' })
    const botonSort = { border: 'none', background: 'none' }
    const iconSort = { color: '#FFFFFF' }
    
    return (
        <section className="mt-5">
            <div className="d-flex">
                <div className="cardEstadisticasPas11 pe-4 ps-4 pt-4">
                    <h3 className="textoPasEstadisticasTitulo">{infoTicket.pas.length !== 0 ? infoTicket.pas[0].value : ""}</h3>
                    <h4 className="textoPasEstadisticasTitulo2">{infoTicket.expFrom !== null && infoTicket.expTo ? `${infoTicket.expFrom} | ${infoTicket.expTo}` : ""}</h4>
                    <h5 className="textoPasEstadisticasTitulo3">Total de expedientes por rango</h5>
                    <div className="row">
                        <div className="col-6">
                            <h2 className="textoPasEstadisticasTitulo4">{updatedList.length}</h2>
                            <div className="d-flex mt-4">
                                <h5>{`Nivel 2: ${updatedList.filter((e) => e.nivel == 2).length}`}</h5>
                                <h5 className="ps-4">{`Nivel 3: ${updatedList.filter((e) => e.nivel == 3).length}`}</h5>
                            </div>
                        </div>
                        <div className="col-6 mt-2">
                            <h2 className="textoPasEstadisticasTitulo5">{`Capital: $${a}`}</h2>
                            <div className="textoPasEstadisticasTitulo6Container center"><h6 className="textoPasEstadisticasTitulo6">{`${promedioCapitalPasConElGral.toFixed(2)}% que el promedio`}</h6></div>
                            <h2 className="textoPasEstadisticasTitulo5">{`Hns Cobrados: $${ab}`}</h2>
                            <div className="textoPasEstadisticasTitulo6Container center"><h6 className="textoPasEstadisticasTitulo6">{`${promedioHnsPasConElGral.toFixed(2)}% que el promedio`}</h6></div>
                        </div>
                    </div>
                </div>
                <div className="cardEstadisticasPas12 pe-3 ps-3 pt-3">
                    <div className="d-flex">
                        <div style={{ width: 50, height: 50 }}>
                            <CircularProgressbar value={c.toFixed(2) * 100} text={`${c.toFixed(2) * 100}%`} background={'white'} backgroundPadding={5} styles={buildStyles({
                                // Text size
                                textSize: '24px',
                                // Colors
                                pathColor: `#800080`,
                                textColor: 'black',
                                trailColor: 'transparent',
                                backgroundColor: 'white',
                            })} />
                        </div>
                        <h2 className="textoPasEstadisticasTitulo7 ps-3">Expedientes cobrados</h2>
                    </div>
                    <div className="textoPasEstadisticasTitulo8Container center"><h3 className="textoPasEstadisticasTitulo8">{`+${d.toFixed(2)}% que el promedio`}</h3></div>
                    <div className="row mt-3">
                        <div className="col">
                            <h6 className="textoPasEstadisticasTitulo9">En Proc</h6>
                            <h6 className="textoPasEstadisticasTitulo9">{`${cantDeExpedientesEnProcPas} (${e.toFixed(2)} dias)`}</h6>
                        </div>
                        <div className="col">
                            <h6 className="textoPasEstadisticasTitulo9">Acept</h6>
                            <h6 className="textoPasEstadisticasTitulo9">{`${cantDeExpedientesAceptacionPas} (${f.toFixed(2)} dias)`}</h6>
                        </div>
                        <div className="col">
                            <h6 className="textoPasEstadisticasTitulo9">Pres Sin Res</h6>
                            <h6 className="textoPasEstadisticasTitulo9">{`${cantDeExpedientesPresSinResPas} (${g.toFixed(2)} dias)`}</h6>
                        </div>
                        <div className="col">
                            <h6 className="textoPasEstadisticasTitulo9">Cobrados</h6>
                            <h6 className="textoPasEstadisticasTitulo9">{`${cantDeExpedientesCobradoPas}`}</h6>
                        </div>
                    </div>
                </div>
                <div className="cardEstadisticasPas13 pe-3 ps-3 pt-4">
                    <h2 className="textoPasEstadisticasTitulo7">Expedientes especiales</h2>
                    <div className="textoPasEstadisticasTitulo8ContainerEspeciales center"><h3 className="textoPasEstadisticasTitulo8">{`${h.toFixed(2)}% que el promedio`}</h3></div>
                    <div className="row mt-3">
                        <div className="col">
                            <h6 className="textoPasEstadisticasTitulo9">Mediaciones</h6>
                            <h6 className="textoPasEstadisticasTitulo9">{`${cantDeExpedientesMediacionPas}`}</h6>
                        </div>
                        <div className="col">
                            <h6 className="textoPasEstadisticasTitulo9">Cerrados</h6>
                            <h6 className="textoPasEstadisticasTitulo9">{`${cantDeExpedientesCerradoPas}`}</h6>
                        </div>
                        <div className="col">
                            <h6 className="textoPasEstadisticasTitulo9">Nivel 3</h6>
                            <h6 className="textoPasEstadisticasTitulo9">{`${cantDeExpedientesNivel3Pas}`}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <div className="tablaHead row ps-3 m-0">
                        {sortA.sort === 'NRO ↑' ?
                            <div class="col p-0 d-flex">
                                <p className="tituloTabla1366 ps-2 center">COMPAÑIA</p>
                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↓' }))}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                            </div> : <div className="d-none"></div>}
                        {sortA.sort === 'NRO ↓' ?
                            <div class="col p-0 d-flex">
                                <p className="tituloTabla1366 ps-2 center">COMPAÑIA</p>
                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                            </div> : <div className="d-none"></div>}
                        {sortA.sort !== 'NRO ↑' && sortA.sort !== 'NRO ↓' ?
                            <div class="col p-0 d-flex">
                                <p className="tituloTabla1366 ps-2 center">COMPAÑIA</p>
                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-filter" style={iconSort} ></i></button>
                            </div> : <div className="d-none"></div>}

                        {sortA.sort === 'PATENTE ↑' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 center">CANT EXP</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort === 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 center">CANT EXP</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort !== 'PATENTE ↑' && sortA.sort !== 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 center">CANT EXP</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}

                        {sortA.sort === 'PAS ↑' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">PROC PAGO</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort === 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">PROC PAGO</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort !== 'PAS ↑' && sortA.sort !== 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">PROC PAGO</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}

                        {sortA.sort === 'FECHA ↑' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">ACEPTACION</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort === 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">ACEPTACION</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort !== 'FECHA ↑' && sortA.sort !== 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">ACEPTACION</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}

                        {sortA.sort === 'COMPAÑIA ↑' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">PRES SIN RES</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort === 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">PRES SIN RES</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort !== 'COMPAÑIA ↑' && sortA.sort !== 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">PRES SIN RES</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}


                        {sortA.sort === 'CLIENTE ↑' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">COBRADO</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort === 'CLIENTE ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">COBRADO</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort !== 'CLIENTE ↑' && sortA.sort !== 'CLIENTE ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">COBRADO</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}


                        {sortA.sort === 'VTO ↑' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">CERRADO</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort === 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">CERRADO</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort !== 'VTO ↑' && sortA.sort !== 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">CERRADO</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}

                        {sortA.sort === 'NIVEL ↑' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">NIVEL 1</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↓' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort === 'NIVEL ↓' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">NIVEL 1</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort !== 'NIVEL ↑' && sortA.sort !== 'NIVEL ↓' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">NIVEL 1</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}


                        {sortA.sort === 'ESTADO ↑' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">NIVEL 2</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort === 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">NIVEL 2</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort !== 'ESTADO ↑' && sortA.sort !== 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">NIVEL 2</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}


                        {sortA.sort === 'ESTADO ↑' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">NIVEL 3</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort === 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">NIVEL 3</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                        {sortA.sort !== 'ESTADO ↑' && sortA.sort !== 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                            <p className="tituloTabla1366 ps-1 center">NIVEL 3</p>
                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                        </div> : <div className="d-none"></div>}
                    </div>
                    <div className="mt-1">{comp.filter(c => updatedList.filter(e => e.compania === c.compania).length > 0).slice(itemOffset, Number(itemOffset + 25)).map((c) => {
                        return (
                            <CompEstadisticas
                                expedientes={updatedList}
                                key={c.id}
                                comp={c}
                            />
                        );
                    })}</div>
                    <div className="mt-4 center">
                        <ReactPaginate
                            className='center p-0'
                            forcePage={currentPage}
                            activeClassName='item active'
                            breakClassName='item break-me'
                            breakLabel={'...'}
                            marginPagesDisplayed={1}
                            onPageChange={handlePageClick}
                            pageClassName='item pagination-page'
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col">
                    <div className="tablaHead row ps-3 m-0">
                        <div class="col p-0 d-flex"><p className="tituloTabla ps-2">PAS</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla ps-2">CANT EXP</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla ps-2">HONORARIOS</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla ps-2">CAPITAL</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla ps-2">PROC PAGO</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla ps-2">PRES SIN RES</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla ps-2">COBRADO</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla ps-2">NIVEL 1</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla ps-2">NIVEL 2</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla ps-2">NIVEL 3</p></div>
                    </div>
                    <div className="mt-1">{pas.slice(itemOffset1, Number(itemOffset1 + 25)).map((p) => {
                        return (
                            <PasEstadisticas
                                expedientes={updatedList1}
                                key={p.id}
                                pas={p}
                            />
                        );
                    })}</div>
                    <div className="mt-4 center">
                        <ReactPaginate
                            className='center p-0'
                            forcePage={currentPage1}
                            activeClassName='item active'
                            breakClassName='item break-me'
                            breakLabel={'...'}
                            marginPagesDisplayed={1}
                            onPageChange={handlePageClick1}
                            pageClassName='item pagination-page'
                            pageRangeDisplayed={5}
                            pageCount={pageCount1}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
