import { useCallback, useState, useEffect } from "react";
import axios from 'axios'
export default function CompEstadisticas({ comp, expedientes }) {
    const cantDeExpedientes = expedientes?.filter((e) => e.compania === comp.compania).length
    const cantDeExpedientesEnProc = expedientes?.filter((e) => e.compania === comp.compania && e.estado === 'EN PROCESO DE PAGO').length
    const cantDeExpedientesAceptacion = expedientes?.filter((e) => e.compania === comp.compania && e.estado === 'ACEPTACION').length
    const cantDeExpedientesPresSinRes = expedientes?.filter((e) => e.compania === comp.compania && e.estado === 'PRES SIN RESOLUCION').length
    const cantDeExpedientesCobrado = expedientes?.filter((e) => e.compania === comp.compania && e.estado === 'COBRADO').length
    const cantDeExpedientesCerrado = expedientes?.filter((e) => e.compania === comp.compania && e.estado === 'CERRADO').length
    const cantDeExpedientesNivel1 = expedientes?.filter((e) => e.compania === comp.compania && e.nivel == 1).length
    const cantDeExpedientesNivel2 = expedientes?.filter((e) => e.compania === comp.compania && e.nivel == 2).length
    const cantDeExpedientesNivel3 = expedientes?.filter((e) => e.compania === comp.compania && e.nivel == 3).length
    let duracionProcPago = 0
    let cantProcDePagoConContador = 0
    expedientes?.filter((e) => Number(e.nro) > 7000).forEach(e => {
        if (e.contadorProcesoPago && e.compania === comp.compania) {
            duracionProcPago += e.contadorProcesoPago
            cantProcDePagoConContador += 1
        }
    });
    const a = duracionProcPago / cantProcDePagoConContador

    let duracionAceptacion = 0
    let cantAceptacionContador = 0
    expedientes?.filter((e) => Number(e.nro) > 7000).forEach(e => {
        if (e.contadorAceptacion && e.compania === comp.compania) {
            duracionAceptacion += e.contadorAceptacion
            cantAceptacionContador += 1
        }
    });
    const b = duracionAceptacion / cantAceptacionContador

    let duracionPresSinRes = 0
    let cantPresSinResContador = 0
    expedientes?.filter((e) => Number(e.nro) > 7000).forEach(e => {
        if (e.contadorPresSinRes && e.compania === comp.compania) {
            duracionPresSinRes += e.contadorPresSinRes
            cantPresSinResContador += 1
        }
    });
    const c = duracionPresSinRes / cantPresSinResContador
    const prom = cantDeExpedientes * 100 / expedientes.length
    return (
        <tr className={cantDeExpedientes !== 0 ? 'containerTablaExpedientes row mb-3 me-1 ms-1' : 'd-none'}>
            <th class="tituloExpedienteTabla3 col m-0 ms-1 p-0">{comp.compania}</th>
            <th class="tituloExpedienteTabla3 col p-0 m-0">{`${cantDeExpedientes} (${Math.round(prom)}%)`}</th>
            <th class="tituloExpedienteTabla3 col p-0 m-0">{cantDeExpedientesEnProc} ({Math.round(a)} días)</th>
            <th class="tituloExpedienteTabla3 col p-0 m-0">{cantDeExpedientesAceptacion} ({Math.round(b)} días)</th>
            <th class="tituloExpedienteTabla3 col p-0 m-0">{cantDeExpedientesPresSinRes} ({Math.round(c)} días)</th>
            <th class="tituloExpedienteTabla3 col p-0 m-0">{cantDeExpedientesCobrado}</th>
            <th class="tituloExpedienteTabla3 col p-0 m-0">{cantDeExpedientesCerrado}</th>
            <th class="tituloExpedienteTabla3 col p-0 m-0">{cantDeExpedientesNivel1}</th>
            <th class="tituloExpedienteTabla3 col p-0 m-0">{cantDeExpedientesNivel2}</th>
            <th class="tituloExpedienteTabla3 col p-0 m-0">{cantDeExpedientesNivel3}</th>
        </tr >
    )
}