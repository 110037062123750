import axios from "axios";
import { useState, useEffect } from "react";
import ExpedientePas from "../components/Expedientes/ExpedientePas"
import "./index.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { MultiSelect } from "react-multi-select-component";
import ReactPaginate from 'react-paginate';
import Excel from "./Excel";
import './paginate.css'
import iconoFiltrar from "../images/iconoFiltrar.png";
import iconoGlobal from "../images/iconoGlobal.png";
import Select from 'react-select';
export default function ExpedientesOrganizaciones() {
    const navigate = useNavigate();
    const a = localStorage.getItem('user');
    if (a === null) {
        navigate('/login')
    }
    const [expedientes, setExpedientes] = useState([]);
    const [productores, setProductores] = useState([]);
    const [companias, setCompanias] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    let arrayPas = [];
    let allPas = cookies.user?.productores.split(',');
    allPas.forEach(function (obj) {
        arrayPas.push(allPas);
    });
    const [preCarga, setPreCarga] = useState(false)
    useEffect(() => {
        arrayPas[0].forEach(e => {
            axios.get(`https://api.tuveunchoque.com.ar/api/gerencia/expedientes/tablas/${e}`).then((res) => {
                setExpedientes((prevState) => {
                    prevState.push(...res.data.reverse())
                    return [...prevState];
                })
                setPreCarga(true)
            });
        })
        axios.get("https://api.tuveunchoque.com.ar/api/gerencia/pas").then((res) => {
            setProductores(res.data);
        });
        axios.get("https://api.tuveunchoque.com.ar/api/gerencia/companias").then((res) => {
            setCompanias(res.data);
        });
    }, []);
    const estadoInput = [
        { label: "RECEPCIONADO", value: "RECEPCIONADO" },
        { label: "SIN DENUNCIA", value: "SIN DENUNCIA" },
        { label: "ENVIADO A COTIZAR", value: "ENVIADO A COTIZAR" },
        { label: "PRES SIN RESOLUCION", value: "PRES SIN RESOLUCION" },
        { label: "ACEPTACION", value: "ACEPTACION" },
        { label: "EN PROCESO DE PAGO", value: "EN PROCESO DE PAGO" },
        { label: "COBRADO", value: "COBRADO" },
        { label: "MEDIACION", value: "MEDIACION" },
        { label: "CERRADO", value: "CERRADO" },
    ];
    const nivelInput = [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
    ];
    const fechaInput = [
        { label: "HOY", value: "HOY" },
        { label: "MAÑANA", value: "MAÑANA" },
        { label: "VENCIDOS", value: "VENCIDOS" },
    ];
    const asignadoInput = [
        { label: "AGOSTINA", value: "AGOSTINA" },
        { label: "CATALINA", value: "CATALINA" },
        { label: "DANIELA", value: "DANIELA" },
        { label: "FRANCO", value: "FRANCO" },
        { label: "JUAN", value: "JUAN" },
        { label: "SOFIA", value: "SOFIA" },
        { label: "MICAELA", value: null },
        { label: 'SANDRA', value: 'SANDRA' },
        { label: "", value: null }
    ];
    const compInput = companias.map((c) => {
        return { value: c.compania, label: c.compania };
    });
    const proInput = arrayPas[0].map((p) => {
        return { value: p, label: p };
    });
    let updatedList = expedientes;
    const cook = () => {
        if (!cookies.user) {
            navigate("/login");
        }
    };
    cook();
    const [infoTabla, setInfoTabla, removeInfoTabla] = useCookies(["tabla"]);
    const [info, setInfo] = useState({
        info: infoTabla.tabla.info,
        pas: infoTabla.tabla.pas,
        comp: infoTabla.tabla.comp,
        estado: infoTabla.tabla.estado,
        nivel: infoTabla.tabla.nivel,
        fecha: infoTabla.tabla.fecha,
        asignado: infoTabla.tabla.asignado,
        estadoPrueba: infoTabla.tabla.estadoPrueba,
        patente: infoTabla.tabla.patente,
        cliente: infoTabla.tabla.cliente,
    });
    const conFiltroPas = () => {
        if (infoTabla.tabla.info) {
            updatedList = updatedList.filter((expediente) =>
                JSON.stringify(expediente.nro).includes(infoTabla.tabla.info) ||
                JSON.stringify(expediente.dominioCliente).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.productorAsociado).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nombre).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.compania).toLowerCase().includes(JSON.stringify(infoTabla.tabla.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
            );
        }
        if (infoTabla.tabla.comp.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.comp.some((ele) => {
                    return ele.value === expediente.compania;
                });
            });
        }
        if (infoTabla.tabla.estado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.estado.some((ele) => {
                    return ele.value === expediente.estado;
                });
            });
        }
        if (infoTabla.tabla.asignado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.asignado.some((ele) => {
                    return ele.value === expediente.asignadoA;
                });
            });
        }
        if (infoTabla.tabla.patente) {
            updatedList = updatedList.filter(
                (expediente) =>
                    JSON.stringify(expediente.dominioCliente)
                        .toLowerCase()
                        .includes(infoTabla.tabla.patente) ||
                    JSON.stringify(expediente.dominioCliente)
                        .toUpperCase()
                        .includes(infoTabla.tabla.patente)
            );
        }
        if (infoTabla.tabla.cliente) {
            updatedList = updatedList.filter(
                (expediente) =>
                    JSON.stringify(expediente.nombre)
                        .toLowerCase()
                        .includes(infoTabla.tabla.cliente) ||
                    JSON.stringify(expediente.nombre)
                        .toUpperCase()
                        .includes(infoTabla.tabla.cliente)
            );
        }
        if (infoTabla.tabla.pas.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tabla.pas.some((ele) => {
                    return ele.value === expediente.productorAsociado;
                });
            });
        }
        if (arrayPas[0].length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return arrayPas[0].some((ele) => {
                    return ele === expediente.productorAsociado;
                });
            });
        }
    }
    conFiltroPas();
    const [abc, setAbc] = useState(20);
    const mas = { fontSize: "40px", color: "#0722A8" };
    const [bcd, setBcd] = useState(20);
    const borde = { background: "none", border: "none" };
    const filtrar = () => {
        setAbc(20);
        setBcd(20);
        setCookie("tabla", JSON.stringify(info), {
            path: "/",
        });
    };
    const vaciar = () => {
        setAbc(20);
        setBcd(20);
        setInfo({
            info: "",
            pas: [],
            comp: [],
            estado: [],
            nivel: [],
            fecha: [],
            asignado: [],
            patente: "",
            asignado: "",
        });
        setCookie("tabla", JSON.stringify(info), { path: "/" });
    };
    const ordenarInput = [{ label: '', value: 0 }, { label: 'NRO ↑', value: 'NRO ↑' }, { label: 'NRO ↓', value: 'NRO ↓' }, { label: 'PATENTE ↑', value: 'PATENTE ↑' }, { label: 'PATENTE ↓ZA', value: 'PATENTE ↓ZA' }, { label: 'COMPAÑIA ↑', value: 'COMPAÑIA ↑' }, { label: 'COMPAÑIA ↓ZA', value: 'COMPAÑIA ↓ZA' }, { label: 'CLIENTE ↑', value: 'CLIENTE ↑' }, { label: 'CLIENTE ↓ZA', value: 'CLIENTE ↓ZA' }]
    const [sortA, setSortA] = useState({ sort: 'NRO ↑' })
    const sort = () => {
        if (sortA.sort === 'NRO ↑') {
            updatedList.sort(function (a, b) {
                return b.nro - a.nro;
            })
        }
        if (sortA.sort === 'NRO ↓') {
            updatedList.sort(function (a, b) {
                return a.nro - b.nro;
            })
        }
        if (sortA.sort === 'NIVEL ↑') {
            updatedList.sort(function (a, b) {
                return a.nivel - b.nivel;
            })
        }
        if (sortA.sort === 'NIVEL ↓') {
            updatedList.sort(function (a, b) {
                return b.nivel - a.nivel;
            })
        }
        if (sortA.sort === 'PATENTE ↑') {
            updatedList.sort(function (a, b) {
                { if (a.dominioCliente && b.dominioCliente) return JSON.stringify(a.dominioCliente).localeCompare(JSON.stringify(b.dominioCliente)) }
            })
        }
        if (sortA.sort === 'PATENTE ↓ZA') {
            updatedList.sort(function (a, b) {
                { if (a.dominioCliente && b.dominioCliente) return JSON.stringify(b.dominioCliente).localeCompare(JSON.stringify(a.dominioCliente)) }
            })
        }
        if (sortA.sort === 'COMPAÑIA ↑') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.compania).toLowerCase()
                const nameB = JSON.stringify(b.compania).toLowerCase()
                if (nameA < nameB && a.compania)
                    return -1;
                if (nameA > nameB && a.compania)
                    return 1;
            })
        }
        if (sortA.sort === 'COMPAÑIA ↓ZA') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.compania).toLowerCase()
                const nameB = JSON.stringify(b.compania).toLowerCase()
                if (nameB < nameA && a.compania)
                    return -1;
                if (nameB > nameA && b.compania)
                    return 1;
            })
        }
        if (sortA.sort === 'CLIENTE ↑') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.nombre).toLowerCase()
                const nameB = JSON.stringify(b.nombre).toLowerCase()
                if (nameA < nameB && a.nombre)
                    return -1;
                if (nameA > nameB && a.nombre)
                    return 1;
            })
        }
        if (sortA.sort === 'CLIENTE ↓ZA') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.nombre).toLowerCase()
                const nameB = JSON.stringify(b.nombre).toLowerCase()
                if (nameB < nameA && a.nombre)
                    return -1;
                if (nameB > nameA && b.nombre)
                    return 1;
            })
        }
        if (sortA.sort === 'STRO ↑') {
            updatedList.sort(function (a, b) {
                return new Date(b.fechaStro) - new Date(a.fechaStro);
            })
        }
        if (sortA.stro === 'STRO ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date(a.fechaStro) - new Date(b.fechaStro);
            })
        }
        if (sortA.sort === 'ESTADO ↑') {
            updatedList.sort(function (a, b) {
                { if (a.estado && b.estado) return JSON.stringify(a.estado).localeCompare(JSON.stringify(b.estado)) }
            })
        }
        if (sortA.sort === 'ESTADO ↓ZA') {
            updatedList.sort(function (a, b) {
                { if (a.estado && b.estado) return JSON.stringify(b.estado).localeCompare(JSON.stringify(a.estado)) }
            })
        }
        if (sortA.sort === 'ESTIMADO ↑') {
            updatedList.sort(function (a, b) {
                return new Date(b.mesEstimado) - new Date(a.mesEstimado);
            })
        }
        if (sortA.sort === 'ESTIMADO ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date(a.mesEstimado) - new Date(b.mesEstimado);
            })
        }
        if (sortA.sort === 'ESTIMADO ↑') {
            updatedList.sort(function (a, b) {
                return new Date((moment(b.mesEstimado, "DD/MM/YYYY").toDate())) - new Date((moment(a.mesEstimado, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'ESTIMADO ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date((moment(a.mesEstimado, "DD/MM/YYYY").toDate())) - new Date((moment(b.mesEstimado, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 0) {
            updatedList.sort((a, b) => b)
        }
    }
    sort()
    let itemsPerPage = 20
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = updatedList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(updatedList.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % updatedList.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    }
    useEffect(() => {
        setItemOffset(0);
        setCurrentPage(0);
    }, [updatedList.length]);
    const aka = { marginLeft: '16px', marginRight: '16px' }
    const img = { heigth: '44px', width: '44px', display: 'block' }
    const btn = { border: 'none', background: 'none', fontSize: '12px', color: '#0089FF', fontWeight: '100' }
    const botonSort = { border: 'none', background: 'none' }
    const iconSort = { color: '#FFFFFF' }
    return (
        <main className="mainTabla pb-5">
            <div className="ordenarPorInputTabla">
                <Select
                    placeholder={sortA.sort ? sortA.sort : 'Ordenar Por'}
                    className="react-select-container"
                    options={ordenarInput}
                    onChange={(newValue) => setSortA((prevState) => ({
                        sort: newValue.value,
                    }))}
                />
            </div>
            {cookies.user ? (
                <section className="row sectionExpedientesGral">
                    <div className="col-10">
                        {preCarga === true ? (updatedList.length > 0 ?
                            <div className="ms-2">
                                <div className="tablaHead row ps-3 m-0">
                                    {sortA.sort === 'NRO ↑' ?
                                        <div class="col p-0 d-flex">
                                            <p className="tituloTabla ps-2">NRO</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↓' }))}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                        </div> : <div className="d-none"></div>}
                                    {sortA.sort === 'NRO ↓' ?
                                        <div class="col p-0 d-flex">
                                            <p className="tituloTabla ps-2">NRO</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                        </div> : <div className="d-none"></div>}
                                    {sortA.sort !== 'NRO ↑' && sortA.sort !== 'NRO ↓' ?
                                        <div class="col p-0 d-flex">
                                            <p className="tituloTabla ps-2">NRO</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-filter" style={iconSort} ></i></button>
                                        </div> : <div className="d-none"></div>}


                                    {sortA.sort === 'PATENTE ↑' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla">PATENTE</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}
                                    {sortA.sort === 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla">PATENTE</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}
                                    {sortA.sort !== 'PATENTE ↑' && sortA.sort !== 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla">PATENTE</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}

                                    {sortA.sort === 'STRO ↑' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla ps-1">F STRO</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'STRO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}
                                    {sortA.sort === 'STRO ↓ZA' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla ps-1">F STRO</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'STRO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}
                                    {sortA.sort !== 'STRO ↑' && sortA.sort !== 'STRO ↓ZA' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla ps-1">F STRO</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'STRO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}

                                    {sortA.sort === 'COMPAÑIA ↑' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla ps-1">CIA</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}
                                    {sortA.sort === 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla ps-1">CIA</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}
                                    {sortA.sort !== 'COMPAÑIA ↑' && sortA.sort !== 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla ps-1">CIA</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}


                                    {sortA.sort === 'CLIENTE ↑' ? <div class="col-3 p-0 d-flex">
                                        <p className="tituloTabla ps-1">CLIENTE</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}
                                    {sortA.sort === 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                        <p className="tituloTabla ps-1">CLIENTE</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}
                                    {sortA.sort !== 'CLIENTE ↑' && sortA.sort !== 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                        <p className="tituloTabla ps-1">CLIENTE</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}


                                    {sortA.sort === 'ESTIMADO ↑' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla ">ESTIMADO</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTIMADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}
                                    {sortA.sort === 'ESTIMADO ↓ZA' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla">ESTIMADO</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTIMADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}
                                    {sortA.sort !== 'ESTIMADO ↑' && sortA.sort !== 'ESTIMADO ↓ZA' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla ">ESTIMADO</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTIMADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}


                                    {sortA.sort === 'ESTADO ↑' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla ps-1">ESTADO</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}
                                    {sortA.sort === 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla ps-1">ESTADO</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}
                                    {sortA.sort !== 'ESTADO ↑' && sortA.sort !== 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                        <p className="tituloTabla ps-1">ESTADO</p>
                                        <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                    </div> : <div className="d-none"></div>}
                                    <div className="col p-0 d-flex"></div>
                                </div>
                                <div class="w-100 mt-3">
                                    <div style={aka}>{updatedList.slice(itemOffset, Number(itemOffset + 20)).map((expediente) => {
                                        return (
                                            <ExpedientePas
                                                expediente={expediente}
                                                key={expediente.id}
                                            />
                                        );
                                    })}</div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="table-responsive scrollbar divTabla">
                                    <div className="tablaHead row ps-3 m-0">
                                        {sortA.sort === 'NRO ↑' ?
                                            <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-2">NRO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↓' }))}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                            </div> : <div className="d-none"></div>}
                                        {sortA.sort === 'NRO ↓' ?
                                            <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-2">NRO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                            </div> : <div className="d-none"></div>}
                                        {sortA.sort !== 'NRO ↑' && sortA.sort !== 'NRO ↓' ?
                                            <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-2">NRO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-filter" style={iconSort} ></i></button>
                                            </div> : <div className="d-none"></div>}


                                        {sortA.sort === 'PATENTE ↑' ? <div class="col p-0 d-flex">
                                            <p className="tituloTabla">PATENTE</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}
                                        {sortA.sort === 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                            <p className="tituloTabla">PATENTE</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}
                                        {sortA.sort !== 'PATENTE ↑' && sortA.sort !== 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                            <p className="tituloTabla">PATENTE</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}

                                        {sortA.sort === 'STRO ↑' ? <div class="col p-0 d-flex">
                                            <p className="tituloTabla ps-1">F STRO</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'STRO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}
                                        {sortA.sort === 'STRO ↓ZA' ? <div class="col p-0 d-flex">
                                            <p className="tituloTabla ps-1">F STRO</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'STRO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}
                                        {sortA.sort !== 'STRO ↑' && sortA.sort !== 'STRO ↓ZA' ? <div class="col p-0 d-flex">
                                            <p className="tituloTabla ps-1">F STRO</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'STRO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}

                                        {sortA.sort === 'COMPAÑIA ↑' ? <div class="col p-0 d-flex">
                                            <p className="tituloTabla ps-1">CIA</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}
                                        {sortA.sort === 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                            <p className="tituloTabla ps-1">CIA</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}
                                        {sortA.sort !== 'COMPAÑIA ↑' && sortA.sort !== 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                            <p className="tituloTabla ps-1">CIA</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}


                                        {sortA.sort === 'CLIENTE ↑' ? <div class="col-3 p-0 d-flex">
                                            <p className="tituloTabla ps-1">CLIENTE</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}
                                        {sortA.sort === 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                            <p className="tituloTabla ps-1">CLIENTE</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}
                                        {sortA.sort !== 'CLIENTE ↑' && sortA.sort !== 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                            <p className="tituloTabla ps-1">CLIENTE</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}


                                        {sortA.sort === 'ESTADO ↑' ? <div class="col p-0 d-flex">
                                            <p className="tituloTabla ps-1">ESTADO</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}
                                        {sortA.sort === 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                            <p className="tituloTabla ps-1">ESTADO</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}
                                        {sortA.sort !== 'ESTADO ↑' && sortA.sort !== 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                            <p className="tituloTabla ps-1">ESTADO</p>
                                            <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                        </div> : <div className="d-none"></div>}
                                        <div className="col p-0 d-flex"></div>
                                    </div>
                                    <h2 className="center letra mt-5">SIN RESULTADOS</h2>
                                </div>
                            </div>) : <div className="center mt-5 pt-5">
                            <div class="spinner-border text-primary center" role="status"></div>
                        </div>
                        }
                        <div className="center mt-5">
                            <Excel data={updatedList} />
                        </div>
                    </div>
                    <div className="col-2 letra">
                        <div className="filtroTicketContainer me-1">
                            <div className="d-flex ps-3 pt-3">
                                <div className="">
                                    <button onClick={(e) => filtrar()} style={btn}><img src={iconoFiltrar} style={img} alt="filtrar"></img>FILTRAR</button>
                                </div>
                                <div>
                                    <button onClick={(e) => vaciar()} style={btn}><img src={iconoGlobal} alt="vaciar" style={img}></img>VACIAR</button>
                                </div>
                            </div>
                            <div className="me-3 ms-3 mt-3" onKeyDown={(e) => { if (e.key === 'Enter') { filtrar() } }}>
                                <div class="">
                                    <input
                                        type="search"
                                        class="inputsDelFiltroSearch"
                                        placeholder="BUSQUEDA GENERAL"
                                        onChange={(e) =>
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                info: e.target.value,
                                            }))
                                        }
                                    />
                                </div>
                                <hr className="me-3 ms-3 mt-3" />
                                <div className="row mt-3">
                                    <div className="col">
                                        <h6 className="">Estado</h6>
                                        <MultiSelect
                                            className=""
                                            options={estadoInput}
                                            value={info.estado}
                                            onChange={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    estado: e,
                                                }));
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <h6 className="">Compañia</h6>
                                        <MultiSelect
                                            className=""
                                            options={compInput}
                                            value={info.comp}
                                            onChange={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    comp: e,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <h6 className="m-0 letra">Productores</h6>
                                        <MultiSelect
                                            className=""
                                            options={proInput}
                                            value={info.pas}
                                            onChange={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    pas: e,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <h6 className="">Patente</h6>
                                        <input
                                            type="search"
                                            class="inputsDelFiltro"
                                            onChange={(e) =>
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    patente: e.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                    <div className="col">
                                        <h6 className="">Cliente</h6>
                                        <input
                                            type="search"
                                            class="inputsDelFiltro"
                                            onChange={(e) =>
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    cliente: e.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mt-5 center">
                                    <ReactPaginate
                                        forcePage={currentPage}
                                        className='react-paginate center'
                                        activeClassName={'item active '}
                                        breakClassName={'item break-me '}
                                        breakLabel={'...'}
                                        containerClassName={'pagination'}
                                        disabledClassName={'disabled-page'}
                                        marginPagesDisplayed={1}
                                        nextClassName={"item next "}
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageClassName={'item pagination-page '}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount}
                                        previousClassName={"item previous"}
                                        previousLabel="<"
                                    />
                                </div>
                                <div className="center mt-1">
                                    <h6>Cantidad de expedientes: {updatedList.length}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <h4 className="p-5">Se necesita iniciar sesion</h4>
            )
            }
        </main >
    );
}
