import axios from "axios"
import { useCallback, useEffect, useState } from "react"
import Mensaje from "./Mensaje"
import './detalles.css'
export default function DetallesPas({ expediente }) {
    const [mensaje, setMensaje] = useState({ mensaje: '', ofrecimiento: false, privado: false, clavado: false })
    const [filtroMensaje, setFiltroMensaje] = useState({ filtro: false, estado: '' })
    const [detalles, setDetalles] = useState([])
    useEffect(() => {
        axios.get(`https://api.tuveunchoque.com.ar/api/gerencia/detalles/${expediente.id}`).then((res) => {
            setDetalles(res.data);
        });
    }, [expediente]);
    const today = new Date()
    const estadoInput = [
        "RECEPCIONADO",
        "SIN DENUNCIA",
        "ENVIADO A COTIZAR",
        "PRES SIN RESOLUCION",
        "ACEPTACION",
        "EN PROCESO DE PAGO",
        "COBRADO",
        "MEDIACION",
        "RECHAZADO",
    ];
    const now = today.toLocaleString()
    const conFiltro = () => {
        if (filtroMensaje.filtro && !filtroMensaje.estado) {
            return detalles.filter(detalles => detalles.sistemaId === expediente.id && detalles['OFRECIMIENTO'] === true && detalles['PRIVADO'] === false).map(detalle => {
                return (<Mensaje mensaje={detalle} key={detalles['ID']} />)
            })
        } else if (!filtroMensaje.filtro && filtroMensaje.estado) {
            return detalles.filter(detalles => detalles.sistemaId === expediente.id && detalles['ESTADO'] === filtroMensaje.estado && detalles['PRIVADO'] === false).map(detalle => {
                    return (<Mensaje mensaje={detalle} key={detalles['ID']} />)
                })
        } else if (filtroMensaje.filtro && filtroMensaje.estado) {
            return detalles.filter(detalles => detalles.sistemaId === expediente.id && detalles['OFRECIMIENTO'] === true && detalles['ESTADO'] === filtroMensaje.estado && detalles['PRIVADO'] === false).map(detalle => {
            return (<Mensaje mensaje={detalle} key={detalles['ID']} />)
        })
        } else {
            return detalles.filter(detalles => detalles.sistemaId === expediente.id && detalles['PRIVADO'] === false).map(detalle => {
            return (<Mensaje mensaje={detalle} key={detalles['ID']} />)
        })
        }
    }
    return (
        <div className='detallesContainer col ms-2'>
            <div style={{ height: '500px', overflowX: 'hidden', overflowY: 'auto' }}>
                {conFiltro()}
            </div>
            <div className="pt-1">
                <form class="">
                    <div className="row">
                        <div className="col ms-4">
                            
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-9 ms-3 letra ofrecimientoBtn w-50">
                                    <input type="checkbox" value={filtroMensaje.filtro} className="form-check-input me-1" id="ofrecimiento"
                                        onChange={(e) =>
                                            setFiltroMensaje((prevState) => ({
                                                ...prevState,
                                                filtro: e.target.checked,
                                            }))} />
                                    <label class="form-check-label ofreLabel" for="ofrecimiento">Filtrar Ofrecimiento</label>
                                </div>
                                <div className="col-3">
                                    <select
                                        className="w-100"
                                        onChange={(e) =>
                                            setFiltroMensaje((prevState) => ({
                                                ...prevState,
                                                estado: e.target.value,
                                            }))
                                        }
                                    >
                                        <option value="" selected>
                                            Estado{" "}
                                        </option>
                                        {estadoInput.map((estado) => (
                                            <option value={estado}>{estado}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    )
}