import { useContext, useState, useEffect, useCallback } from "react";
import '../index.css'
import { useCookies } from 'react-cookie';
import { vencimientoContext } from "../../../context/Vencimiento";
import axios from 'axios'
import { editarContext } from "../../../context/Editar";
import Select from 'react-select';
import DatePicker, { registerLocale } from "react-datepicker";
import SeccionCliente from '../SeccionCliente/SeccionCliente'
import SeccionCobro from '../SeccionCobro/SeccionCobro'
import SeccionExpediente from '../SeccionExpediente/SeccionExpediente'
import SeccionRequerido from '../SeccionRequerido/SeccionRequerido'
import './informacionExpediente.css'
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('es', es)
export default function InformacionExpediente({ expediente}) {
    // VALORES GLOBALES 
    const [active, setActive] = useState(1);
    const SetView = (active) => { setActive(active) };
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    // FUNCION CONDICIONAL PESTANIAS
    const ActiveView = () => {
        switch (active) {
            case 1:
                return <SeccionExpediente expediente={expediente}/>;
            case 2:
                return <SeccionCliente expediente={expediente}/>;
            case 3:
                return <SeccionCobro expediente={expediente}/>;
            case 4:
                return <SeccionRequerido expediente={expediente}/>;
            case 1:
                return <SeccionExpediente expediente={expediente}/>;
            case 2:
                return <SeccionCliente expediente={expediente}/>;
            case 4:
                return <SeccionRequerido expediente={expediente}/>;
            default:
                return <SeccionExpediente expediente={expediente}/>
        }
    };
    return (
        <>
            {window.innerWidth > 1920 ?
                <div className="infoExpContainerAnclado col me-2">
                    {cookies.user.cargo !== 'PAS' ? <div className="d-flex m-0 p-0">
                        <div className={active === 1 ? 'btnInfoActivoExp m-0 p-0 pt-2 pb-2' : 'btnInfoExp w-100 pt-2 pb-2'}>
                            <button className="btnCambioPlanilla" onClick={() => SetView(1)}>EXPEDIENTE</button>
                        </div>
                        <div className={active === 2 ? 'btnInfoActivo w-100 pt-2 pb-2' : 'btnInfo w-100 pt-2 pb-2'}>
                            <button className="btnCambioPlanilla" onClick={() => SetView(2)}>CLIENTE</button>
                        </div>
                        <div className={active === 3 ? 'btnInfoActivo w-100 pt-2 pb-2' : 'btnInfo w-100 pt-2 pb-2'}>
                            <button className="btnCambioPlanilla" onClick={() => SetView(3)}>COBRO</button>
                        </div>
                        <div className={active === 4 ? 'btnInfoActivoReq w-100 pt-2 pb-2' : 'btnInfoReq w-100 pt-2 pb-2'}>
                            <button className="btnCambioPlanilla" onClick={() => SetView(4)}>REQUERIDO</button>
                        </div>
                    </div> : <div className="d-flex m-0 p-0">
                        <div className={active === 1 ? 'btnInfoActivoExp m-0 p-0 pt-2 pb-2' : 'btnInfoExp w-100 pt-2 pb-2'}>
                            <button className="btnCambioPlanilla" onClick={() => SetView(1)}>EXPEDIENTE</button>
                        </div>
                        <div className={active === 2 ? 'btnInfoActivo w-100 pt-2 pb-2' : 'btnInfo w-100 pt-2 pb-2'}>
                            <button className="btnCambioPlanilla" onClick={() => SetView(2)}>CLIENTE</button>
                        </div>
                        <div className={active === 4 ? 'btnInfoActivoReq w-100 pt-2 pb-2' : 'btnInfoReq w-100 pt-2 pb-2'}>
                            <button className="btnCambioPlanilla" onClick={() => SetView(4)}>REQUERIDO</button>
                        </div>
                    </div>}
                    {ActiveView()}
                </div>
                :
                <div className="infoExpContainerAnclado col me-2">
                    {cookies.user.cargo !== 'PAS' ? <div className="d-flex m-0 p-0">
                        <div className={active === 1 ? 'btnInfoActivoExp m-0 p-0 pt-2 pb-2' : 'btnInfoExp w-100 pt-2 pb-2'}>
                            <button className="btnCambioPlanilla1366" onClick={() => SetView(1)}>EXPEDIENTE</button>
                        </div>
                        <div className={active === 2 ? 'btnInfoActivo w-100 pt-2 pb-2' : 'btnInfo w-100 pt-2 pb-2'}>
                            <button className="btnCambioPlanilla1366" onClick={() => SetView(2)}>CLIENTE</button>
                        </div>
                        <div className={active === 3 ? 'btnInfoActivo w-100 pt-2 pb-2' : 'btnInfo w-100 pt-2 pb-2'}>
                            <button className="btnCambioPlanilla1366" onClick={() => SetView(3)}>COBRO</button>
                        </div>
                        <div className={active === 4 ? 'btnInfoActivoReq w-100 pt-2 pb-2' : 'btnInfoReq w-100 pt-2 pb-2'}>
                            <button className="btnCambioPlanilla1366" onClick={() => SetView(4)}>REQUERIDO</button>
                        </div>
                    </div> : <div className="d-flex m-0 p-0">
                        <div className={active === 1 ? 'btnInfoActivoExp m-0 p-0 pt-2 pb-2' : 'btnInfoExp w-100 pt-2 pb-2'}>
                            <button className="btnCambioPlanilla1366" onClick={() => SetView(1)}>EXPEDIENTE</button>
                        </div>
                        <div className={active === 2 ? 'btnInfoActivo w-100 pt-2 pb-2' : 'btnInfo w-100 pt-2 pb-2'}>
                            <button className="btnCambioPlanilla1366" onClick={() => SetView(2)}>CLIENTE</button>
                        </div>
                        <div className={active === 4 ? 'btnInfoActivoReq w-100 pt-2 pb-2' : 'btnInfoReq w-100 pt-2 pb-2'}>
                            <button className="btnCambioPlanilla1366" onClick={() => SetView(4)}>REQUERIDO</button>
                        </div>
                    </div>}
                    {ActiveView()}
                </div>}
        </>
    )
}