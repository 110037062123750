import axios from "axios";
import { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import LineaProgreso from "../components/Expediente/LineaProgreso/LineaProgreso";
import MiniTablaExpediente from '../components/Expediente/MiniTablaExpediente/MiniTablaExpediente'
import Detalles from '../components/Expediente/Detalles/Detalles'
import fresh from '../images/fresh.png'
import callbell from '../images/callbell.jpeg'
import callbellReq from '../images/calbellReq.jpeg'
import sharepoint from '../images/sharepoint.jpeg'
import sharepointRojo from '../images/shareRojo.png'
import { Link } from "react-router-dom";
import './index.css'
import ComentarioAnclado from "../components/Expediente/ComentarioAnclado/ComentarioAnclado";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import DetallesPas from "../components/Expediente/Detalles/DetallesPas";
import DatePicker, { registerLocale } from "react-datepicker";
import { vencimientoContext } from "../context/Vencimiento";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import Ampliaciones from "../components/Expediente/Ampliaciones/Ampliaciones";
import PlanillaExpedienteMediacion from "../components/ExpedienteMediacion/PlanillaExpedienteMediacion";
import MiniTablaMediacion from "../components/ExpedienteMediacion/MiniTablaMediacion";
import InformacionExpediente from "../components/Expediente/InformacionExpediente/InformacionExpediente";
import AmpliacionesPas from "../components/Expediente/Ampliaciones/AmpliacionesPas";
import LineaEstadoAsignadoIzq from "../components/ExpedienteMediacion/LineaEstadoAsignadoIzq";
import RegistroSiniestralidad from '../components/Expediente/RegistroSiniestralidad/RegistroSiniestralidad'
registerLocale('es', es)
export default function ExpedienteMediacion({ expediente, otrosExp, otrosExpReq }) {
    const [vistaMed, setVistaMed] = useState(expediente.estado === 'MEDIACION' ? true : false)
    const mensajes = expediente["COMENTARIOS"]
    const logo = { height: '30px', width: '30px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1 }
    const logo2 = { height: '30px', width: '30px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1 }
    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const cook = () => {
        if (!cookies.user) {
            navigate('/login')
        }
    }
    cook()
    const { vencimiento, setVencimiento } = useContext(vencimientoContext)
    const expedienteDataUpdate = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/updateVencimiento`,
            {
                id: expediente.id,
                vencimiento: new Date(vencimiento).toLocaleDateString('fr-FR'),
            }
        ).then((response) => {
            if (response) {
                setTimeout(async () => {
                    await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
                    localStorage.setItem('hora', formatTime(time))
                    window.location.reload()
                }, 1000);
            }
        })
    }, [vencimiento, expediente]);
    const [nro, setNro] = useState({ nro: '' })
    const [cont, setCont] = useCookies(['contadorExp']);
    const [cont1, setCont1] = useCookies(['contadorExpMediacion']);
    const [exp, setExp] = useCookies(['exp']);
    const [expMediacion, setExpMediacion] = useCookies(['expMediacion']);
    const index = exp.exp.indexOf(expediente.nro)
    const atras = index - 1
    const adelante = index + 1
    const hora = localStorage.getItem('hora');
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const prev = async () => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        setCont('contadorExp', (Number(cont.contadorExp)))
        navigate(`/expediente/${exp.exp[atras]}`)
        window.location.reload()
    }
    const next = async () => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        setCont('contadorExp', (Number(cont.contadorExp)))
        navigate(`/expediente/${exp.exp[adelante]}`)
        window.location.reload()
    }
    const index1 = expMediacion.expMediacion.indexOf(expediente.nro)
    const atras1 = index1 - 1
    const adelante1 = index1 + 1
    const prev1 = async () => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        setCont1('contadorExpMediacion', (Number(cont1.contadorExpMediacion)))
        navigate(`/expediente/${expMediacion.expMediacion[atras1]}`)
        window.location.reload()
    }
    const next1 = async () => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        setCont1('contadorExpMediacion', (Number(cont1.contadorExpMediacion)))
        navigate(`/expediente/${expMediacion.expMediacion[adelante1]}`)
        window.location.reload()
    }
    const trash = { fontSize: "22px" };
    const btn = { border: 'none', background: 'none' }
    const pasCargo = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionCargaPas`, {
            id: expediente.id,
            carga: 0
        }).then((response) => {
            if (response) {
                setTimeout(async () => {
                    window.location.reload()
                }, 200);
            }
        })
    }, [expediente])
    if (expediente?.contadorRevisionLegales > 1) { alert(`El expediente ha vuelto a Revisiones Legales ${expediente.contadorRevisionLegales} veces`); }
    const div1 = { border: 'none', background: 'none' }
    return (
        <>
            {window.innerWidth >= 1920 ?
                <div>
                    {vistaMed === true ?
                        <>
                            <div className="d-flex headerDeMediacion ms-5 me-5">
                                {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ? <div className="buscarNro">
                                    <input type="number" placeholder="Buscar Nro" className="form-control form-control-sm"
                                        onChange={(e) =>
                                            setNro((prevState) => ({
                                                ...prevState,
                                                nro: e.target.value,
                                            }))}
                                        onKeyDown={async (e) => {
                                            if (e.key === 'Enter') {
                                                await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
                                                localStorage.setItem('hora', formatTime(time))
                                                navigate(`/expediente/${nro.nro}`)
                                                window.location.reload()
                                            }
                                        }} />
                                </div> : <div></div>}
                                {expediente.ticket && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo1Med ms-4">
                                    <Link to={`https://estudiopyp.freshdesk.com/a/tickets/${expediente.ticket}`} target="_blank" rel="noopener noreferrer">
                                        <img src={fresh} alt="" style={logo} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.callbell && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo2Med ms-4">
                                    <Link to={`https://dash.callbell.eu/chat/${expediente.callbell}?filter=all`} target="_blank" rel="noopener noreferrer">
                                        <img src={callbell} alt="" style={logo2} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.callbellRequerido && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo3Med ms-4">
                                    <Link to={`https://dash.callbell.eu/chat/${expediente.callbellRequerido}?filter=all`} target="_blank" rel="noopener noreferrer">
                                        <img src={callbellReq} alt="" style={logo2} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.sharepoint && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' || cookies.user.cargo === 'PAS') ? <div className="botonTitulo4Med ms-4">
                                    <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer">
                                        <img src={sharepoint} alt="" style={logo2} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.pasCargoDocumentacion === 1 && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo5Med ms-4"><button onClick={pasCargo} style={div1}><img src={sharepointRojo} alt="" style={logo2} /></button></div> : <div></div>}
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="vtoMed ms-5 letra center">
                                    <h6 className="center me-1 text-nowrap center">Vencimiento: {expediente.fechaVencimiento?.split("-").reverse().join("-")}</h6>
                                    <DatePicker locale='es' value={vencimiento} selected={vencimiento} className="form-control form-control-sm  w-100"
                                        onChange={(e) => {
                                            setVencimiento(e)
                                        }
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                expedienteDataUpdate(e)
                                            }
                                        }
                                        }
                                    />
                                </div> : <div></div>}
                                {expediente.estadoAtras === 1 && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="estadoAtras ms-5 d-flex">
                                    <i class="bi bi-exclamation-circle-fill text-warning" style={trash}></i>
                                    <p className="mt-1 text-primary"> {expediente.estadoAnterior}</p>
                                </div> : <div></div>}
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') && expMediacion.expMediacion.length > 0 ? <div className="prev ms-5">
                                    {index1 > 0 ?
                                        <div>
                                            <button className="btn btn-danger divPre btn-sm" onClick={prev1}>
                                                <p className="">Exp {expMediacion.expMediacion[atras1]}</p>
                                            </button>
                                        </div> : <div></div>}
                                </div> : <div></div>}
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') && expMediacion.expMediacion.length > 0 ? <div className="nextExp ms-5">
                                    {adelante1 === expMediacion.expMediacion.length ? <div></div> : <div>
                                        <button className="btn btn-primary divNext btn-sm" onClick={next1}>
                                            <p className="m-0 p-0">Exp {expMediacion.expMediacion[adelante1]}</p>
                                        </button>
                                    </div>}
                                </div> : <div></div>}
                                <div className="cambiarVista"><button className="btn btn-primary btn-sm" onClick={() => setVistaMed(false)}>VISTA EXPEDIENTE</button></div>
                            </div>
                            <main className="mt-3">
                                {cookies.user ?
                                    <>
                                        <section className="row me-5 ms-5">
                                            <MiniTablaMediacion expediente={expediente} />
                                            {expediente.anclado ? <ComentarioAnclado expediente={expediente} /> : <div className="col-6"></div>}
                                        </section>
                                        <section className="row me-5 ms-5 mt-3">
                                            <LineaEstadoAsignadoIzq expediente={expediente} />
                                            <LineaProgreso expediente={expediente} />
                                        </section>
                                        <section className="row mt-3 me-5 ms-5">
                                            <PlanillaExpedienteMediacion expediente={expediente} />
                                            {<Detalles expediente={expediente} mensajes={mensajes} mediacion={expediente.estado === 'MEDIACION' ? 'MEDIACION' : ''} />}
                                        </section>
                                    </> : <h4 className="p-5">Se necesita iniciar sesion</h4>}
                            </main>
                        </> :
                        <>
                            <div className="d-flex headerDeMediacion ms-5 me-5">
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="buscarNro">
                                    <input type="number" placeholder="Buscar Nro" className="form-control form-control-sm"
                                        onChange={(e) =>
                                            setNro((prevState) => ({
                                                ...prevState,
                                                nro: e.target.value,
                                            }))}
                                        onKeyDown={async (e) => {
                                            if (e.key === 'Enter') {
                                                await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
                                                localStorage.setItem('hora', formatTime(time))
                                                navigate(`/expediente/${nro.nro}`)
                                                window.location.reload()
                                            }
                                        }} />
                                </div> : <div></div>}
                                {expediente.ticket && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo1Med ms-4">
                                    <Link to={`https://estudiopyp.freshdesk.com/a/tickets/${expediente.ticket}`} target="_blank" rel="noopener noreferrer">
                                        <img src={fresh} alt="" style={logo} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.callbell && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo2Med ms-4">
                                    <Link to={`https://dash.callbell.eu/chat/${expediente.callbell}?filter=all`} target="_blank" rel="noopener noreferrer">
                                        <img src={callbell} alt="" style={logo2} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.callbellRequerido && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo3Med ms-4">
                                    <Link to={`https://dash.callbell.eu/chat/${expediente.callbellRequerido}?filter=all`} target="_blank" rel="noopener noreferrer">
                                        <img src={callbellReq} alt="" style={logo2} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.sharepoint && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' || cookies.user.cargo === 'PAS') ? <div className="botonTitulo4Med ms-4">
                                    <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer">
                                        <img src={sharepoint} alt="" style={logo2} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.pasCargoDocumentacion === 1 && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo5Med ms-4">
                                    <button onClick={pasCargo} style={div1}><img src={sharepointRojo} alt="" style={logo2} /></button>
                                </div> : <div></div>}
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="vtoMed ms-5 letra center">
                                    <h6 className="center me-1 text-nowrap center">Vencimiento: {expediente.fechaVencimiento?.split("-").reverse().join("-")}</h6>
                                    <DatePicker locale='es' value={vencimiento} selected={vencimiento} className="form-control form-control-sm  w-100"
                                        onChange={(e) => {
                                            setVencimiento(e)
                                        }
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                expedienteDataUpdate(e)
                                            }
                                        }
                                        }
                                    />
                                </div> : <div></div>}
                                {expediente.estadoAtras === 1 && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="estadoAtras ms-5 d-flex">
                                    <i class="bi bi-exclamation-circle-fill text-warning" style={trash}></i>
                                    <p className="mt-1 text-primary"> {expediente.estadoAnterior}</p>
                                </div> : <div></div>}
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') && exp.exp.length > 0 ? <div className="prev ms-5">
                                    {index > 0 ?
                                        <div>
                                            <button className="btn btn-danger divPre btn-sm" onClick={prev}>
                                                <p className="">Exp {exp.exp[atras]}</p>
                                            </button>
                                        </div> : <div></div>}
                                </div> : <div></div>}
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') && exp.exp.length > 0 ? <div className="nextExp ms-5">
                                    {adelante === exp.exp.length ? <div></div> : <div>
                                        <button className="btn btn-primary divNext btn-sm" onClick={next}>
                                            <p className="m-0 p-0">Exp {exp.exp[adelante]}</p>
                                        </button>
                                    </div>}
                                </div> : <div></div>}
                                <div className="cambiarVista">
                                    <button className="btn btn-warning btn-sm text-white" onClick={() => setVistaMed(true)}>VISTA MEDIACION</button>
                                </div>
                            </div>
                            <main className="mt-3">
                                {cookies.user ?
                                    <>
                                        <section className="row me-5 ms-5">
                                            <MiniTablaMediacion expediente={expediente} />
                                            {expediente.anclado ? <ComentarioAnclado expediente={expediente} /> : <div className="col-6"></div>}
                                        </section>
                                        <section className="row me-5 ms-5 mt-3">
                                            <div className="col ms-2"></div>
                                            <LineaProgreso expediente={expediente} />
                                        </section>
                                        <section className="row mt-3 me-5 ms-5">
                                            <InformacionExpediente expediente={expediente} />
                                            {<Detalles expediente={expediente} mensajes={mensajes} mediacion={expediente.estado === 'MEDIACION' ? 'MEDIACION' : ''} />}
                                        </section>
                                        {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ?
                                            <div className="row mt-5 me-5 ms-5 mb-5">
                                                <Ampliaciones expediente={expediente} />
                                                <RegistroSiniestralidad expediente={expediente} otrosExp={otrosExp} otrosExpReq={otrosExpReq} />
                                            </div>
                                            : <AmpliacionesPas expediente={expediente} />}
                                    </> : <h4 className="p-5">Se necesita iniciar sesion</h4>}
                            </main>
                        </>}
                </div >
                :
                <div>
                    {vistaMed === true ?
                        <>
                            <div className="d-flex headerDeMediacion ms-5 me-5">
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="buscarNro">
                                    <input type="number" placeholder="Buscar Nro" className="form-control form-control-sm"
                                        onChange={(e) =>
                                            setNro((prevState) => ({
                                                ...prevState,
                                                nro: e.target.value,
                                            }))}
                                        onKeyDown={async (e) => {
                                            if (e.key === 'Enter') {
                                                await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
                                                localStorage.setItem('hora', formatTime(time))
                                                navigate(`/expediente/${nro.nro}`)
                                                window.location.reload()
                                            }
                                        }} />
                                </div> : <div></div>}
                                {expediente.ticket && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo1Med ms-4">
                                    <Link to={`https://estudiopyp.freshdesk.com/a/tickets/${expediente.ticket}`} target="_blank" rel="noopener noreferrer">
                                        <img src={fresh} alt="" style={logo} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.callbell && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo2Med ms-4">
                                    <Link to={`https://dash.callbell.eu/chat/${expediente.callbell}?filter=all`} target="_blank" rel="noopener noreferrer">
                                        <img src={callbell} alt="" style={logo2} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.callbellRequerido && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo3Med ms-4">
                                    <Link to={`https://dash.callbell.eu/chat/${expediente.callbellRequerido}?filter=all`} target="_blank" rel="noopener noreferrer">
                                        <img src={callbellReq} alt="" style={logo2} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.sharepoint && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' || cookies.user.cargo === 'PAS') ? <div className="botonTitulo4Med ms-4">
                                    <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer">
                                        <img src={sharepoint} alt="" style={logo2} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.pasCargoDocumentacion === 1 && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo5Med ms-4">
                                    <button onClick={pasCargo} style={div1}><img src={sharepointRojo} alt="" style={logo2} /></button>
                                </div> : <div></div>}
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="vtoMed ms-5 letra center">
                                    <h6 className="center me-1 text-nowrap center">Vencimiento: {expediente.fechaVencimiento?.split("-").reverse().join("-")}</h6>
                                    <DatePicker locale='es' value={vencimiento} selected={vencimiento} className="form-control form-control-sm  w-100"
                                        onChange={(e) => {
                                            setVencimiento(e)
                                        }
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                expedienteDataUpdate(e)
                                            }
                                        }
                                        }
                                    />
                                </div> : <div></div>}
                                {expediente.estadoAtras === 1 && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="estadoAtras ms-5 d-flex">
                                    <i class="bi bi-exclamation-circle-fill text-warning" style={trash}></i>
                                    <p className="mt-1 text-primary"> {expediente.estadoAnterior}</p>
                                </div> : <div></div>}
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') && expMediacion.expMediacion.length > 0 ? <div className="prev ms-5">
                                    {index1 > 0 ?
                                        <div>
                                            <button className="btn btn-danger divPre" onClick={prev1}>
                                                <p className="">Exp {expMediacion.expMediacion[atras1]}</p>
                                            </button>
                                        </div> : <div></div>}

                                </div> : <div></div>}
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') && expMediacion.expMediacion.length > 0 ? <div className="nextExp ms-5">
                                    {adelante1 === expMediacion.exp.length ? <div></div> : <div>
                                        <button className="btn btn-primary divNext" onClick={next1}>
                                            <p className="m-0 p-0">Exp {expMediacion.expMediacion[adelante1]}</p>
                                        </button>
                                    </div>}
                                </div> : <div></div>}
                                <div className="cambiarVista"><button className="btn btn-primary btn-sm" onClick={() => setVistaMed(false)}>VISTA EXPEDIENTE</button></div>
                            </div>
                            <main className="mt-3">
                                {cookies.user ?
                                    <>
                                        <section className="row me-5 ms-5">
                                            <MiniTablaMediacion expediente={expediente} />
                                            {expediente.anclado ? <ComentarioAnclado expediente={expediente} /> : <div className="col-6"></div>}
                                        </section>
                                        <section className="row me-5 ms-5 mt-3">
                                            <LineaEstadoAsignadoIzq expediente={expediente} />
                                            <LineaProgreso expediente={expediente} />
                                        </section>
                                        <section className="row mt-3 me-5 ms-5">
                                            <PlanillaExpedienteMediacion expediente={expediente} />
                                            {<Detalles expediente={expediente} mensajes={mensajes} mediacion={expediente.estado === 'MEDIACION' ? 'MEDIACION' : ''} />}
                                        </section>
                                    </> : <h4 className="p-5">Se necesita iniciar sesion</h4>}
                            </main>
                        </> :
                        <>
                            <div className="headerDeMediacion d-flex ms-5 me-5">
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="buscarNro">
                                    <input type="number" placeholder="Buscar Nro" className="form-control form-control-sm"
                                        onChange={(e) =>
                                            setNro((prevState) => ({
                                                ...prevState,
                                                nro: e.target.value,
                                            }))}
                                        onKeyDown={async (e) => {
                                            if (e.key === 'Enter') {
                                                await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
                                                localStorage.setItem('hora', formatTime(time))
                                                navigate(`/expediente/${nro.nro}`)
                                                window.location.reload()
                                            }
                                        }} />
                                </div> : <div></div>}
                                {expediente.ticket && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo1Med ms-4">
                                    <Link to={`https://estudiopyp.freshdesk.com/a/tickets/${expediente.ticket}`} target="_blank" rel="noopener noreferrer">
                                        <img src={fresh} alt="" style={logo} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.callbell && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo2Med ms-4">
                                    <Link to={`https://dash.callbell.eu/chat/${expediente.callbell}?filter=all`} target="_blank" rel="noopener noreferrer">
                                        <img src={callbell} alt="" style={logo2} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.callbellRequerido && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo3Med ms-4">
                                    <Link to={`https://dash.callbell.eu/chat/${expediente.callbellRequerido}?filter=all`} target="_blank" rel="noopener noreferrer">
                                        <img src={callbellReq} alt="" style={logo2} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.sharepoint && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' || cookies.user.cargo === 'PAS') ? <div className="botonTitulo4Med ms-4">
                                    <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer">
                                        <img src={sharepoint} alt="" style={logo2} />
                                    </Link>
                                </div> : <div></div>}
                                {expediente.pasCargoDocumentacion === 1 && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="botonTitulo5Med ms-4">
                                    <button onClick={pasCargo} style={div1}><img src={sharepointRojo} alt="" style={logo2} /></button>
                                </div> : <div></div>}
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="vtoMed ms-5 letra center">
                                    <h6 className="center me-1 text-nowrap center">Vencimiento: {expediente.fechaVencimiento?.split("-").reverse().join("-")}</h6>
                                    <DatePicker locale='es' value={vencimiento} selected={vencimiento} className="form-control form-control-sm  w-100"
                                        onChange={(e) => {
                                            setVencimiento(e)
                                        }
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                expedienteDataUpdate(e)
                                            }
                                        }
                                        }
                                    />
                                </div> : <div></div>}
                                {expediente.estadoAtras === 1 && (cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') ? <div className="estadoAtras ms-5 d-flex">
                                    <i class="bi bi-exclamation-circle-fill text-warning" style={trash}></i>
                                    <p className="mt-1 text-primary"> {expediente.estadoAnterior}</p>
                                </div> : <div></div>}
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') && exp.exp.length > 0 ? <div className="prev ms-5">
                                    {index > 0 ?
                                        <div>
                                            <button className="btn btn-danger divPre btn-sm" onClick={prev}>
                                                <p className="">Exp {exp.exp[atras]}</p>
                                            </button>
                                        </div> : <div></div>}
                                </div> : <div></div>}
                                {(cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE') && exp.exp.length > 0 ? <div className="nextExp ms-5">
                                    {adelante === exp.exp.length ? <div></div> : <div>
                                        <button className="btn btn-primary divNext btn-sm" onClick={next}>
                                            <p className="m-0 p-0">Exp {exp.exp[adelante]}</p>
                                        </button>
                                    </div>}
                                </div> : <div></div>}
                                <div className="cambiarVista">
                                    <button className="btn btn-warning btn-sm text-white" onClick={() => setVistaMed(true)}>VISTA MEDIACION</button>
                                </div>
                            </div>
                            <main className="mt-3">
                                {cookies.user ?
                                    <>
                                        <section className="row me-5 ms-5">
                                            <MiniTablaMediacion expediente={expediente} />
                                            {expediente.anclado ? <ComentarioAnclado expediente={expediente} /> : <div className="col-6"></div>}
                                        </section>
                                        <section className="row me-5 ms-5 mt-3">
                                            <div className="col me-2"></div>
                                            <LineaProgreso expediente={expediente} />
                                        </section>
                                        <section className="row mt-3 me-5 ms-5">
                                            <InformacionExpediente expediente={expediente} />
                                            {cookies.user?.cargo !== 'PAS' ? <Detalles expediente={expediente} mensajes={mensajes} mediacion={expediente.estado === 'MEDIACION' ? 'MEDIACION' : ''} /> : <DetallesPas expediente={expediente} mensajes={mensajes} />}
                                        </section>
                                        <section className="row mt-5 me-5 ms-5 mb-5">
                                            {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ?
                                                <div className="row mt-5 me-5 ms-5 mb-5">
                                                    <Ampliaciones expediente={expediente} />
                                                    <RegistroSiniestralidad expediente={expediente} otrosExp={otrosExp} otrosExpReq={otrosExpReq} />
                                                </div>
                                                : <AmpliacionesPas expediente={expediente} />}
                                            <div className="col ms-2"></div>
                                        </section>
                                    </> : <h4 className="p-5">Se necesita iniciar sesion</h4>}
                            </main>
                        </>}
                </div >}
        </>
    )
}