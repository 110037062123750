import { useCallback, useState, useEffect } from "react";
import axios from 'axios'
import { useCookies } from 'react-cookie';
export default function Pas(pas) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const btn = { border: 'none', background: 'none' }
    const [edit, setEdit] = useState(false)
    const [paso, setPaso] = useState({ nombre: pas.pas.nombre, email: pas.pas.email })
    const pasUpdate = useCallback(async () => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/ajustes/pas/update`,
            {
                nombre: paso.nombre,
                email: paso.email,
                id: pas.pas['ID']
            }
        ).then((response) => {
            if (response) {
                setTimeout(() => {
                    window.location.reload()
                }, 10);
            }
        })
    }, [paso])
    return (
        <tr className='letra'>
            <td class="align-middle">
                {!edit ? <h6 class="">{pas.pas.nombre}</h6> : <input type="text" class="form-control form-control-sm w-50" value={paso.nombre} placeholder={pas.pas.nombre} onChange={(e) =>
                    setPaso((prevState) => ({
                        ...prevState,
                        nombre: e.target.value,
                    }))}></input>}
            </td>
            <td class="align-middle=">
                {!edit ? <h6 class="">{pas.pas.email}</h6> : <input type="text" class="form-control form-control-sm" placeholder={pas.pas.email} value={paso.email} onChange={(e) =>
                    setPaso((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                    }))}></input>}
            </td>
            <td>{!edit ? <div></div> :
                <div className="d-flex">
                    <button className="btn btn-primary btn-sm me-2" onClick={(e) => pasUpdate(e)}>Actualizar</button>
                </div>}</td>
            <td>
                {!edit ? <button style={btn} onClick={((e) => setEdit(true))} className={cookies.user.cargo === 'GERENCIA' ? '' : 'd-none'}><i class="bi bi-pencil-square"></i></button> :
                    <div className="d-flex">
                        <button style={btn} onClick={((e) => setEdit(false))}><i class="bi bi-x-circle-fill"></i></button>
                    </div>}
            </td>
        </tr>
    )
}