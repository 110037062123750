import './expedientesTablas.css'
export default function Estado({estado}) {
    let clas = ''
    let clas1366 = ''
    if(estado === 'RECEPCIONADO') {
        clas = 'estadoATabla center'
        clas1366 = 'estadoATabla1366 center'
    }
    else if (estado === 'SIN DENUNCIA') {
        clas = 'estadoBTabla center'
        clas1366 = 'estadoBTabla1366 center'
    } 
    else if (estado === 'ENVIADO A COTIZAR') {
        clas = 'estadoCTabla center'
        clas1366 = 'estadoCTabla1366 center'
    } 
    else if (estado === 'PRES SIN RESOLUCION') {
        clas = 'estadoDTabla center'
        clas1366 = 'estadoDTabla1366 center'
    } 
    else if (estado === 'ACEPTACION') {
        clas = 'estadoETabla center'
        clas1366 = 'estadoETabla1366 center'
    } 
    else if (estado === 'EN PROCESO DE PAGO') {
        clas = 'estadoFTabla center'
        clas1366 = 'estadoFTabla1366 center'
    }
    else if (estado === 'COBRADO') {
        clas = 'estadoGTabla center'
        clas1366 = 'estadoGTabla1366 center'
    }
    else if (estado === 'MEDIACION') {
        clas = 'estadoHTabla center'
        clas1366 = 'estadoHTabla1366 center'
    }
    else if (estado === 'CERRADO') {
        clas = 'estadoITabla center'
        clas1366 = 'estadoITabla1366 center'
    }
    return (
        <div className="center ms-1 mt-1">
            <h6 className={window.innerWidth > 1920 ? clas : clas1366}>{estado}</h6>
        </div>
    )
}