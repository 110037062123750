import { createContext, useEffect, useState } from 'react'
export const expedienteActContext = createContext()
export function ExpedienteActContextProvider({ children }) {
    const [expedienteActualizar, setExpedienteActualizar] = useState()
    const a = expedienteActualizar?.fechaVencimiento?.split('/')
    const b = expedienteActualizar?.fechaExp?.split('/')
    const c = expedienteActualizar?.fechaStro?.split('/')
    const [expedienteData, setExpedienteData] = useState({
        idExpediente: expedienteActualizar?.id,
        exp2: expedienteActualizar?.fechaExp,
        fechaExp: expedienteActualizar ? (new Date(b[2] + '/' + b[1] + '/' + b[0]).toLocaleDateString('fr-FR')) : null,
        stro2: expedienteActualizar?.fechaStro,
        fechaStro: expedienteActualizar ? (new Date(c[2] + '/' + c[1] + '/' + c[0]).toLocaleDateString('fr-FR')) : null,
        estado: expedienteActualizar?.estado,
        monto: expedienteActualizar?.monto,
        asignado: expedienteActualizar?.asignadoA,
        nivel: expedienteActualizar?.nivel,
        capital: expedienteActualizar?.capital,
        vencimiento2: expedienteActualizar?.fechaVencimiento,
        vencimiento: expedienteActualizar ? (new Date(a[2] + '/' + a[1] + '/' + a[0]).toLocaleDateString('fr-FR')) : null,
        ticket: expedienteActualizar?.ticket,
        callbell: expedienteActualizar?.callbell,
        infoAdicional: expedienteActualizar?.infoAdicional,
        prioridad: expedienteActualizar?.prioridad
    })
    return (
        <expedienteActContext.Provider value={{ expedienteActualizar, setExpedienteActualizar ,setExpedienteData, expedienteData }}>
            {children}
        </expedienteActContext.Provider>
    )
}