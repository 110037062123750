import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import "react-circular-progressbar/dist/styles.css";
export default function Nivel({ nivel }) {
    let color = 'transparent'
    let porcentaje = '0'
    if(Number(nivel) === 1) {
        color = 'green'
        porcentaje = 25
    } else if (Number(nivel) === 2) {
        color = 'yellow'
        porcentaje = 50
    } else if (Number(nivel) === 3) {
        color = 'red'
        porcentaje = 100
    }
    return (
        <div class="d-flex align-items-center gap-1">
            <div style={window.innerWidth > 1920 ? { width: '30px' } : { width: '20px' }}>
                <CircularProgressbar value={porcentaje} circleRatio={0.8}
                    styles={buildStyles({
                        rotation: 0.6,
                        pathColor: `${color}`
                    })} />
            </div>
        </div>
    )
}