import { useEffect, useState } from 'react'
import './index.css'
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom"
export default function File() {
    const id = useParams().id
    function isPDFLink(link) {
        // Get the file extension
        const extension = link.split('.').pop().toLowerCase();

        // Check if the extension indicates a PDF file
        return extension === 'pdf';
    }
    return (
        <main className='mainCrearExp '>
            {isPDFLink(id) ? <embed src={`https://api.tuveunchoque.com.ar/image/${id}`} type="application/pdf" width="100%" height="600px" /> : 
            <img src={`https://api.tuveunchoque.com.ar/image/${id}`} alt={`File`}  height={'800px'} width={'800px'}/>}
        </main>
    )
}