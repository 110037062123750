import { useCallback, useState, useEffect } from "react";
import axios from 'axios'
import { useCookies } from 'react-cookie';
export default function User(user) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const btn = { border: 'none', background: 'none' }
    const [edit, setEdit] = useState(false)
    const [check, setCheck] = useState(0);
    const [com, setCom] = useState({ nombre: user.user.nombre, password: user.user.password})
    function handleChange(e) {
        if (check === 0) {
            setCheck(1)
        } else if (check === 1) {
            setCheck(0)
        }
    }
    const compUpdate = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/ajustes/user/update/pas`,
            {
                id: user.user.id,
                nombre: com.nombre,
                password: com.password,
                crear: Number(check) 
            }
        ).then((response) => {
            if (response) {
                setTimeout(() => {
                    window.location.reload()
                }, 10);
            }
        })
    }, [com, check]);
    return (
        <tr className='letra'>
            <td class="align-middle">
                {!edit ? <h6 class="">{user.user.nombre}</h6> : <input type="text" class="form-control form-control-sm" placeholder={user.user.nombre} onChange={(e) =>
                    setCom((prevState) => ({
                        ...prevState,
                        nombre: e.target.value,
                    }))}></input>}
            </td>
            <td class="align-middle">
                {!edit ? <h6 class="">{user.user.password}</h6> : <input type="text" class="form-control form-control-sm" placeholder={user.user.password} onChange={(e) =>
                    setCom((prevState) => ({
                        ...prevState,
                        password: e.target.value,
                    }))}></input>}
            </td>
            {user.user.cargo === 'PAS' ? <td class="align-middle">
                {!edit ? <h6 class="">{user.user.crearExpediente ? 'SI' : 'NO'}</h6> : <input class="form-check-input"
                    type="checkbox"
                    onChange={handleChange}
                    checked={check}></input>}
            </td> : <td></td>}
            <td>{!edit ? <div></div> :
                <div className="d-flex">
                    <button className="btn btn-primary btn-sm me-2" onClick={(e) => compUpdate()}>Actualizar</button>
                </div>}</td>
            <td>
                {!edit ? <button style={btn} onClick={((e) => setEdit(true))} className={cookies.user.cargo === 'GERENCIA' ? '' : 'd-none'}><i class="bi bi-pencil-square"></i></button> :
                    <div className="d-flex">
                        <button style={btn} onClick={((e) => setEdit(false))}><i class="bi bi-x-circle-fill"></i></button>
                    </div>}
            </td>
            <td>
                {!edit ? <div>
                    <button className={cookies.user.cargo === 'GERENCIA' ? 'btn btn-secondary btn-sm' : 'd-none'}>Elimnar</button>
                </div> : <div></div>}
            </td>
        </tr>
    )
}