import { useCallback, useState, useEffect } from "react";
import axios from 'axios'
import ReactPaginate from 'react-paginate';
export default function PasEstadisticas({ pas, expedientes }) {
    const cantDeExpedientes = expedientes.filter((e) => e.productorAsociado === pas.nombre).length
    let capital = 0
    let promedioCapital = 0
    expedientes.filter((e) => e.productorAsociado === pas.nombre).forEach(e => {
        if (e.capital && e.productorAsociado === pas.nombre) {
            capital += Number(e.capital)
            promedioCapital += 1
        }
    });
    const a = capital 
    let hns = 0
    let promedioHns = 0
    expedientes.filter((e) => e.productorAsociado === pas.nombre).forEach(e => {
        if (e.honorariosCobro && e.productorAsociado === pas.nombre) {
            hns += Number(e.honorariosCobro)
            promedioHns += 1
        }
    });
    const c = hns 
    let recep = 0
    let sinde = 0
    let env = 0
    let pres = 0
    let acep = 0
    let proc = 0
    let prom = 0
    expedientes.filter((e) => e.productorAsociado === pas.nombre).forEach(e => {
        if (e.contadorRecepcionado || e.contadorSinDenuncia || e.contadorEnvCotizar || e.contadorPresSinRes || e.contadorAceptacion || e.contadorProcesoPago && e.productorAsociado === pas.nombre) {
            recep += Number(e.contadorRecepcionado)
            sinde += Number(e.contadorSinDenuncia)
            env += Number(e.contadorEnvCotizar)
            pres += Number(e.contadorPresSinRes)
            acep += Number(e.contadorAceptacion)
            proc += Number(e.contadorProcesoPago)
            prom += 1
        }
    });
    const b = (recep + sinde + env + pres + acep + proc) / prom
    const cantDeExpedientesEnProc = expedientes?.filter((e) => e.productorAsociado === pas.nombre && e.estado === 'EN PROCESO DE PAGO').length
    const cantDeExpedientesAceptacion = expedientes?.filter((e) => e.productorAsociado === pas.nombre && e.estado === 'ACEPTACION').length
    const cantDeExpedientesPresSinRes = expedientes?.filter((e) => e.productorAsociado === pas.nombre && e.estado === 'PRES SIN RESOLUCION').length
    const cantDeExpedientesCobrado = expedientes?.filter((e) => e.productorAsociado === pas.nombre && e.estado === 'COBRADO').length
    const cantDeExpedientesCerrado = expedientes?.filter((e) => e.productorAsociado === pas.nombre && e.estado === 'CERRADO').length
    const cantDeExpedientesNivel1 = expedientes?.filter((e) => e.productorAsociado === pas.nombre && e.nivel == 1).length
    const cantDeExpedientesNivel2 = expedientes?.filter((e) => e.productorAsociado === pas.nombre && e.nivel == 2).length
    const cantDeExpedientesNivel3 = expedientes?.filter((e) => e.productorAsociado === pas.nombre && e.nivel == 3).length
    let duracionProcPago = 0
    let cantProcDePagoConContador = 0
    expedientes?.forEach(e => {
        if (e.contadorProcesoPago && e.productorAsociado === pas.nombre) {
            duracionProcPago += e.contadorProcesoPago
            cantProcDePagoConContador += 1
        }
    });
    const A = duracionProcPago / cantProcDePagoConContador
    let duracionAceptacion = 0
    let cantAceptacionContador = 0
    expedientes?.forEach(e => {
        if (e.contadorAceptacion && e.productorAsociado === pas.nombre) {
            duracionAceptacion += e.contadorAceptacion
            cantAceptacionContador += 1
        }
    });
    const B = duracionAceptacion / cantAceptacionContador
    let duracionPresSinRes = 0
    let cantPresSinResContador = 0
    expedientes?.forEach(e => {
        if (e.contadorPresSinRes && e.productorAsociado === pas.nombre) {
            duracionPresSinRes += e.contadorPresSinRes
            cantPresSinResContador += 1
        }
    });
    const C = duracionPresSinRes / cantPresSinResContador
    const promA = cantDeExpedientes * 100 / expedientes.length
    return (
        <div>
            <div className={cantDeExpedientes !== 0 ? 'containerTablaExpedientes row mb-3 me-1 ms-1' : 'd-none'}>
                <div class="tituloExpedienteTabla3 col m-0 ms-1 p-0">{pas.nombre}</div>
                <th class="tituloExpedienteTabla3 col p-0 m-0">{`${cantDeExpedientes} (${Math.round(promA)}%)`}</th>
                <div class="tituloExpedienteTabla3 col p-0 m-0">{c}</div>
                <div class="tituloExpedienteTabla3 col p-0 m-0">{a}</div>
                <th class="tituloExpedienteTabla3 col p-0 m-0">{cantDeExpedientesEnProc} ({Math.round(A)} días)</th>
                <th class="tituloExpedienteTabla3 col p-0 m-0">{cantDeExpedientesPresSinRes} ({Math.round(C)} días)</th>
                <th class="tituloExpedienteTabla3 col p-0 m-0">{cantDeExpedientesCobrado}</th>
                <th class="tituloExpedienteTabla3 col p-0 m-0">{cantDeExpedientesNivel1}</th>
                <th class="tituloExpedienteTabla3 col p-0 m-0">{cantDeExpedientesNivel2}</th>
                <th class="tituloExpedienteTabla3 col p-0 m-0">{cantDeExpedientesNivel3}</th>
            </div >
        </div>
    )
}