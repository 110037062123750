import Estado from './Estado';
import { Link } from "react-router-dom"
import contactopas from '../../images/contactopas.jpg'
import pay from '../../images/pay.png'
import { useCookies } from 'react-cookie';
import sharepoint from "../../images/sharepoint.jpeg";
import cobroPas from "../../images/icono pago.png";
export default function ExpedientePas(expediente) {
    const logo = { height: '27px', width: '27px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1 }
    const logo2 = { height: '22px', width: '22px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }
    const link = { textDecoration: 'none' }
    return (
        <div className='containerTablaExpedientes row ps-3 pe-2 mt-1'>
            <div class="tituloExpedienteTabla11366 col p-0"><Link to={`/expediente/${expediente.expediente.nro}`} style={link}>{expediente.expediente.nro}</Link></div>
            <div class="tituloExpedienteTabla21366 col p-0">{expediente.expediente.dominioCliente}</div>
            <div class="tituloExpedienteTabla41366 col p-0">{expediente.expediente.fechaStro?.split("-").reverse().join("-")}</div>
            <div class="tituloExpedienteTabla51366 col p-0">{expediente.expediente.compania}</div>
            <div class="tituloExpedienteTabla61366 col-3 p-0">{expediente.expediente.nombre}</div>
            <div class="tituloExpedienteTabla41366 col p-0">{expediente.expediente.mesEstimado}</div>
            <div class="col p-0 center">
                <Estado estado={expediente.expediente.estado} />
            </div>
            <div className='col p-0 tituloExpedienteTabla8'>
                <div className='d-flex'>
                    {expediente.expediente.callbell ? <img src={contactopas} style={window.innerWidth > 1920 ? logo : logo2} className='me-1'></img> : <div style={logo} className='me-1'></div>}
                    {expediente.expediente.honorarios ? <img src={pay} style={logo} className='me-1'></img> : <div style={window.innerWidth > 1920 ? logo : logo2} className='me-1'></div>}
                    {expediente.expediente.pasCobroHonorarios === 'SI' ? <img src={cobroPas} style={logo} className='me-1'></img> : <div style={window.innerWidth > 1920 ? logo : logo2} className='me-1'></div>}
                    <div style={logo} className='me-1'></div>
                    {expediente.expediente.sharepoint ?
                        <Link
                            to={`${expediente.expediente.sharepoint}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={sharepoint} alt="" style={window.innerWidth > 1920 ? logo : logo2} />
                        </Link> : <div style={logo} className='me-1'></div>}
                </div>
            </div>
        </div>
    )
}