import { useCookies } from 'react-cookie';
import NivelMediacion from './NivelMediacion';
import EstadoMediacion from './EstadoMediacion';
export default function MiniTablaMediacion({ expediente }) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const paddingTitulo = { paddingLeft: '30px' }
    return (
        <>
            {
                window.innerHeight >= 1920 ?
                    <section className='col-6'>
                        <div className='miniExpContainerMediacion ps-3'>
                            <div className="letra row mt-3">
                                <div className="d-flex">
                                    <h5 class="tituloMediacion">{`EXP ${expediente.nro}\xa0`}</h5>
                                    <h5 className="tituloMediacion">{` - ${expediente.nombre} C/ ${expediente.compania} - ${expediente.productorAsociado}`}</h5>
                                </div>
                            </div>
                            <div className="letra row mt-2">
                                <div className='col-2 d-flex'>
                                    <NivelMediacion nivel={expediente.nivel} />
                                    {expediente.estado === 'MEDIACION' ? <div className='ms-3 center text-center'>
                                        <h6 className={expediente.etapaMediacion === 1 ? 'estadoATabla center' : (expediente.etapaMediacion === 2 ? 'estadoBTabla center' : (expediente.etapaMediacion === 3 ? 'estadoCTabla center' : (expediente.etapaMediacion === 4 ? 'estadoDTabla center' : (expediente.etapaMediacion === 5 ? 'estadoETabla center' : (expediente.etapaMediacion === 6 ? 'estadoFTabla center' : (expediente.etapaMediacion === 7 ? 'estadoGTabla center' : 'estadoH2Tabla center'))))))}>{expediente.etapaMediacion === 1 ? 'SOLICITUD DE FECHA' : (expediente.etapaMediacion === 2 ? 'FECHA ASIGNADA' : (expediente.etapaMediacion === 3 ? 'NEGOCIACION' : (expediente.etapaMediacion === 4 ? 'REVISIONES LEGALES' : (expediente.etapaMediacion === 5 ? 'ARMADO DERIVACION' : (expediente.etapaMediacion === 6 ? 'ESPERA CONFIRMACION' : (expediente.etapaMediacion === 7 ? 'ACEPTACION MEDIACION' : 'Sin Etapa'))))))}</h6>
                                    </div> : <EstadoMediacion estado={expediente.estado} />}
                                </div>
                                <div className="col-2">
                                    <p className='textMiniTablaMediacion center'>{expediente.asignadoA}</p>
                                </div>
                                <div className="col-4">
                                    <div className='d-flex'>
                                        <p className='subTextMiniTablaMediacion'>{`Stro\xa0`}</p>
                                        <p className='textMiniTablaMediacion'>{expediente.nroSeguimientoCompaniaRequerido}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <p className='textMiniTablaMediacion'>Ult Mod {expediente.ultimaMod}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section className='col-6'>
                        <div className='miniExpContainerMediacion ps-3'>
                            <div className="letra row mt-3">
                                <div className="d-flex">
                                    <h5 class="tituloMediacion1366">{`EXP ${expediente.nro}\xa0`}</h5>
                                    <h5 className="tituloMediacion1366">{` - ${expediente.nombre} C/ ${expediente.compania} - ${expediente.productorAsociado}`}</h5>
                                </div>
                            </div>
                            <div className="letra row mt-2">
                                <div className='col-2 d-flex'>
                                    <NivelMediacion nivel={expediente.nivel} />
                                    <EstadoMediacion estado={expediente.estado === 'MEDIACION' ? expediente.etapaMediacion : expediente.estado} />
                                </div>
                                <div className="col-2">
                                    <p className='textMiniTablaMediacion1366 ms-1 m-0'>{expediente.asignadoA}</p>
                                </div>
                                <div className="col-4">
                                    <div className='d-flex'>
                                        <p className='subTextMiniTablaMediacion1366'>{`Stro\xa0`}</p>
                                        <p className='textMiniTablaMediacion1366'>{expediente.nroSeguimientoCompaniaRequerido}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <p className='textMiniTablaMediacion1366'>Ult Mod {expediente.ultimaMod}</p>
                                </div>
                            </div>
                        </div>
                    </section>
            }
        </>
    )
}