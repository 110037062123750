import axios from "axios";
import { useCallback, useState, useEffect } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
registerLocale("es", es);
export default function AgregarCobro() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cobro = queryParams.get("nro");
  const navigate = useNavigate();
  const a = localStorage.getItem("user");
  if (a === null) {
    navigate("/login");
  }
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [expedientes, setExpedientes] = useState([]);
  useEffect(() => {
    if (cookies.user.nombre === "FRANCO") {
      axios
        .get(
          "https://api.tuveunchoque.com.ar/api/gerencia/expedientes/agregarcobroFranco"
        )
        .then((res) => {
          setExpedientes(res.data.reverse());
        });
      axios
        .get(
          "https://api.tuveunchoque.com.ar/api/gerencia/expedientes/agregarcobro"
        )
        .then((res) => {
          setExpedientes(res.data.reverse());
        });
    } else {
      axios
        .get(
          "https://api.tuveunchoque.com.ar/api/gerencia/expedientes/agregarcobro"
        )
        .then((res) => {
          setExpedientes(res.data.reverse());
        });
    }
  }, []);
  const [cobroData, setCobroData] = useState({
    nro: cobro !== null ? cobro : "",
    capital: "",
    mesCobrado: "",
    mesEstimado: "",
    modoDePago: "",
    honorariosCobro: "",
    estadoCobro: "",
    nroFactura: "",
    facturadoPor: "",
    honorariosCliente: "",
    honorariosFacturado: "",
    vencimiento: null,
  });
  const hoy = new Date().toISOString().split("T")[0];
  const ex = expedientes.find((e) => e.nro == cobroData.nro);
  const cobroDataUpdate = useCallback(
    async (e) => {
      e.preventDefault();
      await axios
        .post(`https://api.tuveunchoque.com.ar/api/gerencia/cobro/update`, {
          id: ex.id,
          capital: cobroData.capital,
          mesCobrado: cobroData.mesCobrado,
          mesEstimado: cobroData.mesEstimado,
          modoDePago: cobroData.modoDePago,
          honorariosCobro: cobroData.honorariosCobro,
          estadoCobro: cobroData.estadoCobro,
          nroFactura: cobroData.nroFactura,
          facturadoPor: cobroData.facturadoPor,
          honorariosCliente: cobroData.honorariosCliente,
          honorariosFacturado: cobroData.honorariosFacturado,
          compReq: ex.companiasTablaID,
          fechaCambioEstado: ex.fechaCambioEstado,
          mesCobradoViejo: ex.mesCobrado,
          vencimiento: new Date(cobroData.vencimiento).toLocaleDateString(
            "fr-FR"
          ),
          modificadoPor: cookies.user.nombre,
        })
        .then((response) => {
          if (response) {
            setTimeout(async () => {
              window.location.reload();
            }, 1000);
          }
        });
    },
    [cobroData, ex]
  );
  const pagoInput = ["CHEQUE", "CLIENTE", "TRANSFERENCIA"];
  const estadoCobroInput = ["PENDIENTE", "COBRADO"];
  const facturadoInput = [
    "JUAN",
    "FRANCO",
    "MAURO",
    "CLIENTE",
    "SANDRA",
    "FERLAUTO",
    "FACUNDO",
  ];
  const p = { color: "#0731FF" };
  const cook = () => {
    if (!cookies.user) {
      navigate("/login");
    } else if (cookies.user.cargo === "PAS") {
      navigate("/expedientes");
    }
  };
  cook();
  function isOnlyNumbers(input) {
    if (input === "" || input === null || input === undefined) {
      return true;
    }
    const regex = /^[0-9]+$/;
    return regex.test(input);
  }
  return (
    <main class="mainExpedientes">
      {cookies.user ? (
        <section className="row sectionExpedientes">
          {cookies.user?.cargo !== "PAS" ? (
            <div class="pe-5 ps-5 mt-3">
              <form className="row" onSubmit={cobroDataUpdate}>
                <div class="col-6">
                  <h4 className="mb-4 center" style={p}>
                    Expediente
                  </h4>
                  <div class="mb-2 row">
                    <div className="col">
                      <label class="mb-1">Nro</label>
                      <input
                        type="text"
                        class="form-control"
                        value={cobroData.nro}
                        placeholder={cobroData.nro}
                        onChange={(e) =>
                          setCobroData((prevState) => ({
                            ...prevState,
                            nro: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="col">
                      <label class="mb-1">Estado</label>
                      <input
                        type="text"
                        class="form-control"
                        value={ex?.estado}
                        placeholder={ex?.estado}
                        disabled
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <div class="col">
                      <label class="mb-1">Nombre Cliente</label>
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        value={ex?.nombre}
                        placeholder={ex?.nombre}
                      />
                    </div>
                    <div class="col">
                      <label class="mb-1">Compañia</label>
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        value={ex?.compania}
                        placeholder={ex?.compania}
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <div className="col">
                      <label class="mb-1">Patente</label>
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        value={ex?.dominioCliente}
                        placeholder={ex?.dominioCliente}
                      />
                    </div>
                    <div className="col">
                      <label class="mb-1">Dominio Requerido</label>
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        value={ex?.patenteRequerido}
                        placeholder={ex?.patenteRequerido}
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <div className="col">
                      <label class="mb-1">Pas</label>
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        value={ex?.productorAsociado}
                        placeholder={ex?.productorAsociado}
                      />
                    </div>
                    <div className="col">
                      <label class="mb-1">Capital</label>
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        value={ex?.capital}
                        placeholder={ex?.capital}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <h4 className="mb-4 center" style={p}>
                    Cobro
                  </h4>
                  <div class="mb-2 row">
                    <div className="col">
                      <label class="mb-">Capital (*)</label>
                      <input
                        type="number"
                        class="form-control"
                        value={cobroData.capital}
                        placeholder={ex?.capital}
                        required
                        onChange={(e) =>
                          setCobroData((prevState) => ({
                            ...prevState,
                            capital: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div class="col">
                      <label class="mb-1">Mes Cobrado</label>
                      <input
                        type="month"
                        class="form-control"
                        value={cobroData.mesCobrado}
                        required={
                          cobroData.estadoCobro === "COBRADO" ? true : false
                        }
                        onChange={(e) =>
                          setCobroData((prevState) => ({
                            ...prevState,
                            mesCobrado: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div class="col">
                      <label class="mb-1">Mes Cobrado</label>
                      <input
                        type="month"
                        class="form-control"
                        value={ex?.mesCobrado}
                        disabled
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <div className="col">
                      <label class="mb-1 ">Mes Estimado (*)</label>
                      <input
                        type="month"
                        class="form-control"
                        value={cobroData.mesEstimado}
                        required
                        onChange={(e) =>
                          setCobroData((prevState) => ({
                            ...prevState,
                            mesEstimado: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div class="col">
                      <label class="mb-1">Mes Estimado</label>
                      <input
                        type="month"
                        class="form-control"
                        value={ex?.mesEstimado}
                        disabled
                      />
                    </div>
                    <div class="col">
                      <label class="mb-1">Modo de pago (*)</label>
                      <Select
                        options={pagoInput
                          .sort((a, b) => a.localeCompare(b))
                          .map((pago) => ({ value: pago, label: pago }))}
                        placeholder={ex?.modoDePago}
                        onChange={(newValue) =>
                          setCobroData((prevState) => ({
                            ...prevState,
                            modoDePago: newValue.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <div className="col">
                      <label class="mb-1">Honorarios Facturado</label>
                      <input
                        type="number"
                        class="form-control"
                        value={cobroData.honorariosFacturado}
                        placeholder={ex?.honorariosFacturado}
                        onChange={(e) =>
                          setCobroData((prevState) => ({
                            ...prevState,
                            honorariosFacturado: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="col">
                      <label class="mb-1">Honorarios Cliente</label>
                      <input
                        type="number"
                        class="form-control"
                        value={cobroData.honorariosCliente}
                        placeholder={ex?.honorariosCliente}
                        onChange={(e) =>
                          setCobroData((prevState) => ({
                            ...prevState,
                            honorariosCliente: e.target.value,
                          }))
                        }
                      />
                    </div>
                    {cobroData.facturadoPor === "JUAN" ? (
                      <div class="col">
                        <label class="mb-1">Honorarios Cobrados Cia</label>
                        <input
                          type="number"
                          class="form-control"
                          placeholder={ex?.honorariosCobro}
                          value={cobroData.honorariosCobro}
                          required={
                            cobroData.estadoCobro === "COBRADO" ? true : false
                          }
                          onChange={(e) =>
                            setCobroData((prevState) => ({
                              ...prevState,
                              honorariosCobro: e.target.value,
                            }))
                          }
                        />
                      </div>
                    ) : (
                      <div class="col">
                        <label class="mb-1">Honorarios Cobrados Cia</label>
                        <input
                          type="number"
                          class="form-control"
                          value={cobroData.honorariosCobro}
                          placeholder={ex?.honorariosCobro}
                          onChange={(e) =>
                            setCobroData((prevState) => ({
                              ...prevState,
                              honorariosCobro: e.target.value,
                            }))
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div class="mb-2 row">
                    <div className="col">
                      <label class="mb-1">Estado Cobro (*)</label>
                      <Select
                        placeholder={ex?.estadoCobro}
                        required
                        options={estadoCobroInput
                          .sort((a, b) => a.localeCompare(b))
                          .map((estadoCobro) => ({
                            value: estadoCobro,
                            label: estadoCobro,
                          }))}
                        onChange={(newValue) =>
                          setCobroData((prevState) => ({
                            ...prevState,
                            estadoCobro: newValue.value,
                          }))
                        }
                      />
                    </div>
                    <div class="col">
                      <label class="mb-1">Nro Factura (*)</label>
                      <input
                        type="text"
                        placeholder={ex?.nroFactura}
                        class="form-control"
                        value={cobroData.nroFactura}
                        required
                        onChange={(e) =>
                          setCobroData((prevState) => ({
                            ...prevState,
                            nroFactura: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div class="col">
                      <label class="mb-1">Facturado Por (*)</label>
                      <Select
                        options={facturadoInput
                          .sort((a, b) => a.localeCompare(b))
                          .map((f) => ({ value: f, label: f }))}
                        placeholder={ex?.facturadoPor}
                        onChange={(newValue) =>
                          setCobroData((prevState) => ({
                            ...prevState,
                            facturadoPor: newValue.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label class="mb-1">Vencimiento</label>
                      <DatePicker
                        locale="es"
                        value={cobroData.vencimiento}
                        selected={cobroData.vencimiento}
                        className="form-control"
                        required={
                          cobroData.estadoCobro === "COBRADO" ? false : true
                        }
                        onChange={(e) =>
                          setCobroData((prevState) => ({
                            ...prevState,
                            vencimiento: e,
                          }))
                        }
                      />
                    </div>
                    <div className="col">
                      <label class="mb-1">Vencimiento del exp</label>
                      <input
                        type="text"
                        class="form-control"
                        value={ex?.fechaVencimiento}
                        disabled
                      />
                    </div>
                    <div className="col"></div>
                  </div>
                  <div className="center">
                    <button type="submit" className="btn btn-primary mb-5 mt-5" disabled={isOnlyNumbers(cobroData.honorariosCliente) === false ? true : isOnlyNumbers(cobroData.honorariosCobro) === false ? true : isOnlyNumbers(cobroData.honorariosFacturado) === false ? true : isOnlyNumbers(cobroData.capital) === false ? true : false}>
                      Crear
                    </button>
                  </div>
                </div>
              </form>
              {isOnlyNumbers(cobroData.capital) === false ? (
                <div className="center">
                  <p className="text-danger">
                    El campo Capital contiene valores diferentes a nros
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              {isOnlyNumbers(cobroData.honorariosFacturado) === false ? (
                <div className="center">
                  <p className="text-danger">
                    El campo Honorarios Facturado contiene valores diferentes a
                    nros
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              {isOnlyNumbers(cobroData.honorariosCliente) === false ? (
                <div className="center">
                  <p className="text-danger">
                    El campo Honorarios Cliente contiene valores diferentes a
                    nros
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              {isOnlyNumbers(cobroData.honorariosCobro) === false ? (
                <div className="center">
                  <p className="text-danger">
                    El campo Honorarios Cobrados Cia contiene valores diferentes
                    a nros
                  </p>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </section>
      ) : (
        <h4 className="p-5">Se necesita iniciar sesion</h4>
      )}
    </main>
  );
}
