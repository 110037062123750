import './Header.css'
import logo from '../../images/logoTuveUnChoque.png'
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from 'axios'
import { useEffect, useState } from 'react';
export default function Header() {
    const link = { textDecoration: 'none' }
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [ticketA, setTicketA] = useCookies(['exp']);
    const [cont, setCont] = useCookies(['contadorExp'])
    const navigate = useNavigate()
    const hora = localStorage.getItem('hora');
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const ticket = async () => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        setTicketA('exp', [])
        setCont('contadorExp', 0)
        window.location.reload()
    }
    const cerrar = async (e) => {
        e.preventDefault()
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        removeCookie('contadorExp')
        removeCookie('contadorExpMediacion')
        removeCookie('exp')
        removeCookie('expMediacion')
        removeCookie('gestion')
        removeCookie('tabla')
        removeCookie('tablaGerencia')
        removeCookie('ticket')
        removeCookie('user')
        localStorage.removeItem('user');
        navigate('/login')
        window.location.reload()
    }
    const registro = async (e) => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        setTicketA('exp', [])
        setCont('contadorExp', 0)
        window.location.reload()
    }
    return (
        <header className={cookies.user ? 'header' : ''}>
            {cookies.user ?
                <>
                    {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' || cookies.user.cargo === 'AGENTE' ?
                        <section className='sectionHeader'>
                            <div className=''>
                                {cookies.user?.cargo === 'PAS' || cookies.user.cargo === 'MEDIADOR' || cookies.user.cargo === 'ORGANIZACION' ? <Link to='/expedientes'><img src={logo} alt="logo" className='logo' /></Link> : <Link to='/gestion' onClick={ticket}><img src={logo} alt="logo" className='logo' /></Link>}
                            </div>
                            <nav class="navbar">
                                <div class="container-fluid">
                                    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                                        <span class="navbar-toggler-icon"></span>
                                    </button>
                                    <div class="shadow offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                                        <div class="offcanvas-header">
                                            <h5 class="offcanvas-title" id="offcanvasNavbarLabel"></h5>
                                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                        </div>
                                        <div class="offcanvas-body  mt-3 m-auto">
                                            <div className='ps-3'>
                                                <h6 className='textoNombreHeader ps-3'>{cookies.user.nombre}</h6>
                                                <div className='botonCerrarSesion center text-center'>
                                                    <button className='cerrarSesion' onClick={(e) => cerrar(e)}>Cerrar Sesión</button>
                                                </div>
                                            </div>
                                            {cookies.user ? <div className='mt-5'>
                                                {cookies.user.cargo !== 'PAS' && cookies.user.cargo !== 'MEDIADOR' && cookies.user.cargo !== 'ORGANIZACION' && cookies.user.cargo !== 'AGENTE' ? <Link to={'/novedades'} style={link} onClick={(e) => registro(e)}><div className='botonHeader d-flex justify-content-start align-items-center'><p className='textoBotonHeader m-0'>NOVEDADES</p></div></Link> : <div></div>}
                                                {cookies.user.cargo !== 'PAS' && cookies.user.cargo !== 'MEDIADOR' && cookies.user.cargo !== 'ORGANIZACION' ? <Link to={'/gestion'} style={link} onClick={(e) => registro(e)}>
                                                    <div className='botonHeader d-flex justify-content-start align-items-center'><p className='textoBotonHeader m-0'>GESTION</p></div>
                                                </Link> : <div></div>}
                                                {cookies.user.cargo !== 'PAS' && cookies.user.cargo !== 'MEDIADOR' && cookies.user.cargo !== 'ORGANIZACION' ? <Link to={'/expedientes'} style={link} onClick={(e) => registro(e)}>
                                                    <div className='botonHeader d-flex justify-content-start align-items-center'><p className='textoBotonHeader m-0'>TABLA</p></div>
                                                </Link> : <div></div>}
                                                {cookies.user.cargo !== 'PAS' && cookies.user.cargo !== 'MEDIADOR' && cookies.user.cargo !== 'ORGANIZACION' && cookies.user.cargo !== 'AGENTE' ? <Link to={'/mediacion'} style={link} onClick={(e) => registro(e)}>
                                                    <div className='botonHeader d-flex justify-content-start align-items-center'><p className='textoBotonHeader m-0'>MEDIACIONES</p></div>
                                                </Link> : <div></div>}
                                                {cookies.user.crearExpedienteGerencia ? <Link to={'/expediente/nuevo'} style={link} onClick={(e) => registro(e)}>
                                                    <div className='botonHeader d-flex justify-content-start align-items-center'><p className='textoBotonHeader m-0'>ING EXP</p></div>
                                                </Link> : <div></div>}
                                                {cookies.user.cargo !== 'PAS' && cookies.user.cargo !== 'MEDIADOR' && cookies.user.cargo !== 'ORGANIZACION' && cookies.user.cargo !== 'AGENTE' ? <Link to={'/expediente/cobro/nuevo'} style={link} onClick={(e) => registro(e)}>
                                                    <div className='botonHeader d-flex justify-content-start align-items-center'><p className='textoBotonHeader m-0'>COBROS</p></div>
                                                </Link> : <div></div>}
                                                {cookies.user.cargo !== 'PAS' && cookies.user.cargo !== 'MEDIADOR' && cookies.user.cargo !== 'ORGANIZACION' && cookies.user.cargo !== 'AGENTE' ? <Link to={'https://estudio-pyp.com.ar/formulario-presentacion/'} style={link} target="_blank" rel="noopener noreferrer">
                                                    <div className='botonHeader d-flex justify-content-start align-items-center'><p className='textoBotonHeader m-0'>FORMS</p></div>
                                                </Link> : <div></div>}
                                                {cookies.user.cargo !== 'PAS' && cookies.user.cargo !== 'MEDIADOR' && cookies.user.cargo !== 'ORGANIZACION' && cookies.user.cargo !== 'AGENTE' ? <Link to={'/estadisticas'} style={link} onClick={(e) => registro(e)}>
                                                    <div className='botonHeader d-flex justify-content-start align-items-center'><p className='textoBotonHeader m-0'>GRAPHS</p></div>
                                                </Link> : <div></div>}
                                                {cookies.user.cargo === "PAS" && cookies.user.crear === true ? <Link to={'/nuevoExpPas'} style={link} onClick={(e) => registro(e)}>
                                                    <div className='botonHeader d-flex justify-content-start align-items-center'><p className='textoBotonHeader m-0'>CREAR EXP</p></div>
                                                </Link> : <div></div>}
                                                {cookies.user.cargo === "GERENCIA" ? <Link to={'/files'} style={link} onClick={(e) => registro(e)}>
                                                    <div className='botonHeader d-flex justify-content-start align-items-center'><p className='textoBotonHeader m-0'>FILES</p></div>
                                                </Link> : <div></div>}
                                                {cookies.user.cargo === 'GERENCIA' || cookies.user.cargo === 'EMPLEADO' ?
                                                    <Link style={link} to={'/ajustes'} onClick={(e) => registro(e)}>
                                                        <button className='btnAjustes mt-3 center'>
                                                            <i class="bi bi-gear ajustes"></i>
                                                        </button>
                                                    </Link> : <div></div>}
                                            </div> : <div></div>}
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </section> :
                        <section className='sectionHeader d-flex justify-content-between align-items-center'>
                            <div className=''>
                                {cookies.user?.cargo === 'PAS' || cookies.user.cargo === 'MEDIADOR' || cookies.user.cargo === 'ORGANIZACION' ? <Link to='/expedientes'><img src={logo} alt="logo" className='logo' /></Link> : <Link to='/gestion' onClick={ticket}><img src={logo} alt="logo" className='logo' /></Link>}
                            </div>
                            <div className='d-flex'>
                                {cookies.user.cargo === "PAS" && cookies.user.crear === true ? <Link to={'/nuevoExpPas'} style={link} onClick={(e) => registro(e)} className='me-3'>
                                    <div className='botonHeaderPas d-flex justify-content-start align-items-center'><p className='textoBotonHeader m-0'>CREAR EXP</p></div>
                                </Link> : <div></div>}
                            </div>
                        </section>}
                </> : <div></div>}
        </header>
    )
}