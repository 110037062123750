import { useState } from "react";
import * as XLSX from "xlsx";
export default function ExcelAjustes({ data, fields }) {
    const [loading, setLoading] = useState(false);
    const handleDownload = () => {
        setLoading(true);
        const toExcelDate = (dateString) => {
            if (!dateString) return null;
            let date;
            const formats = [
                /^\d{4}-\d{2}-\d{2}$/, // yyyy-mm-dd
                /^\d{2}-\d{2}-\d{4}$/, // dd-mm-yyyy
                /^\d{2}\/\d{2}\/\d{4}$/, // dd/mm/yyyy
                /^\d{4}\/\d{2}\/\d{2}$/  // yyyy/mm/dd
            ];
            for (let format of formats) {
                if (format.test(dateString)) {
                    if (format === formats[1]) { // dd-mm-yyyy
                        const [day, month, year] = dateString.split("-");
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (format === formats[2]) { // dd/mm/yyyy
                        const [day, month, year] = dateString.split("/");
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (format === formats[3]) { // yyyy/mm/dd
                        const [year, month, day] = dateString.split("/");
                        date = new Date(`${year}-${month}-${day}`);
                    } else { // yyyy-mm-dd
                        date = new Date(dateString);
                    }
                    break;
                }
            }
            if (!date || isNaN(date.getTime())) {
                return null;
            }
            const excelDate = Math.floor((date - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000)) + 1;
            return excelDate;
        };

        const cleanNumberField = (value) => {
            if (!value) return null; // Return null for empty fields
            return parseFloat(value.replace(/[^\d.-]/g, '')); // Convert to a float number
        };

        const formattedData = data.map(item => {
            const newItem = { ...item };

            ['fechaExp', 'fechaStro'].forEach(field => {
                if (newItem[field]) {
                    newItem[field] = toExcelDate(newItem[field]);
                }
            });

            ['monto', 'honorariosCobro', 'honorariosFacturado', 'capital'].forEach(field => {
                if (newItem[field] !== undefined) {
                    newItem[field] = cleanNumberField(newItem[field]);
                }
            });
            newItem['nivel'] = newItem['nivel'] === null ? "" : newItem['nivel'];
            return newItem;
        });
        const libro = XLSX.utils.book_new();
        const hoja = XLSX.utils.json_to_sheet(formattedData);
        const applyDateFormat = (sheet) => {
            Object.keys(sheet).forEach(cell => {
                if (sheet[cell].v !== null && typeof sheet[cell].v === 'number' && sheet[cell].v > 0) {
                    if (cell.startsWith('C') || cell.startsWith('E')) {
                        sheet[cell].t = 'n';
                        sheet[cell].z = 'DD/MM/YYYY';
                    }
                }
            });
        };
        applyDateFormat(hoja);
        XLSX.utils.book_append_sheet(libro, hoja, "Productos");
        setTimeout(() => {
            XLSX.writeFile(libro, "data.xlsx");
            setLoading(false);
        }, 1000);
    };

    return (
        <>
            <button className="btn btn-primary ms-3" onClick={handleDownload}>
                Descarga
            </button>
        </>
    );
};