import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import ReactPaginate from 'react-paginate';
import { MultiSelect } from "react-multi-select-component";
export default function EstadisticaAsignadoNuevo({ expedientes, asignado}) {
    const nivel1 = expedientes?.filter(e => e.asignadoA === asignado.nombre && e.nivel == 1).length
    const nivel2 = expedientes?.filter(e => e.asignadoA === asignado.nombre && e.nivel == 2).length
    const nivel3 = expedientes?.filter(e => e.asignadoA === asignado.nombre && e.nivel == 3).length
    const asignados = expedientes?.filter(e => e.asignadoA === asignado.nombre).length
    const vencenHoy = expedientes?.filter(e => e.asignadoA === asignado.nombre && (new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toDateString() === new Date().toDateString())).length
    const vencenManiana = expedientes?.filter(e => e.asignadoA === asignado.nombre && (new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toLocaleDateString() === new Date(+new Date().setHours(0, 0, 0, 0) + 86400000).toLocaleDateString())).length
    const vencidos = expedientes?.filter(e => e.asignadoA === asignado.nombre && (Date.parse(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()) < new Date().setHours(0, 0, 0, 0))).length
    const prioridad = expedientes?.filter(e => e.asignadoA === asignado.nombre && e.prioridad === 'ALTA').length
    return (
        <div className='containerTablaExpedientes row mb-3 ps-3 pe-2 ms-1 me-1'>
            <div class="tituloExpedienteTabla3 col ps-">{asignado.nombre}</div>
            <div class="tituloExpedienteTabla3 col ps-">{asignados}</div>
            <div class="tituloExpedienteTabla3 col ps-">{nivel1}</div>
            <div class="tituloExpedienteTabla3 col ps-">{nivel2}</div>
            <div class="tituloExpedienteTabla3 col ps-">{nivel3}</div>
            <div class="tituloExpedienteTabla3 col ps-">{vencenHoy}</div>
            <div class="tituloExpedienteTabla3 col ps-">{vencenManiana}</div>
            <div class="tituloExpedienteTabla3 col ps-">{vencidos}</div>
            <div class="tituloExpedienteTabla3 col ps-">{prioridad}</div>
        </div>
    )
}