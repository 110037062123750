import axios from "axios";
import "./index.css";
import "./estadisticas.css";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { MultiSelect } from "react-multi-select-component";
import PasNuevoEstadisticas from "../components/Estadisticas/PasNuevo";
import CompNuevoEstadisticas from "../components/Estadisticas/CompNuevo";
import EstadisticaAsignadoNuevo from "../components/Estadisticas/AsignadosNuevo";
import CompEstadisticas from "../components/Estadisticas/Comp";
import PasEstadisticas from "../components/Estadisticas/Pas";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
export default function Estadisticas() {
    const navigate = useNavigate()
    const a = localStorage.getItem('user');
    if (a === null) {
        navigate('/login')
    }
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [expedientes, setExpedientes] = useState([]);
    const [productores, setProductores] = useState([]);
    const [companias, setCompanias] = useState([]);
    const [asignados, setAsignados] = useState([]);
    useEffect(() => {
        if (cookies.user.nombre === 'FRANCO') {
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expedientes/estadisticasFranco").then((res) => {
                setExpedientes(res.data);
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expedientes/estadisticas").then((res) => {
                setExpedientes(res.data.reverse());
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/pas").then((res) => {
                setProductores(res.data);
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/companias").then((res) => {
                setCompanias(res.data);
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/asignado").then((res) => {
                setAsignados(res.data);
            });
        } else {
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expedientes/estadisticas").then((res) => {
                setExpedientes(res.data.reverse());
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/pas").then((res) => {
                setProductores(res.data);
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/companias").then((res) => {
                setCompanias(res.data);
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/asignado").then((res) => {
                setAsignados(res.data);
            });
        }
    }, []);
    // Expedientes Filtrados por comp pas estado fecha nro
    let updatedList = expedientes;
    // Expedientes en total filtrado por fecha nro
    let updatedList1 = expedientes;
    // Expedientes Filtrados por comp pas estado fecha nro
    let updatedList20 = expedientes;
    // Expedientes en total filtrado por fecha nro
    let updatedList21 = expedientes;
    const [infoTicket, setInfoTicket] = useState({
        pas: [],
        comp: [],
        estado: [],
        fecha: [],
        expFrom: null,
        expTo: null,
        nroFrom: 7000,
        nroTo: null,
        mesCobradoFrom: null,
        mesCobradoTo: null
    });
    const [infoTicket2, setInfoTicket2] = useState({
        pas: [],
        comp: [],
        estado: [],
        fecha: [],
        expFrom: null,
        expTo: null,
        nroFrom: 7000,
        nroTo: null,
    });
    const estadoInput = [
        { label: "RECEPCIONADO", value: "RECEPCIONADO" },
        { label: "SIN DENUNCIA", value: "SIN DENUNCIA" },
        { label: "ENVIADO A COTIZAR", value: "ENVIADO A COTIZAR" },
        { label: "PRES SIN RESOLUCION", value: "PRES SIN RESOLUCION" },
        { label: "ACEPTACION", value: "ACEPTACION" },
        { label: "EN PROCESO DE PAGO", value: "EN PROCESO DE PAGO" },
        { label: "COBRADO", value: "COBRADO" },
        { label: "MEDIACION", value: "MEDIACION" },
        { label: "CERRADO", value: "CERRADO" },
    ];
    const compInput = companias.map((c) => {
        return { value: c.compania, label: c.compania };
    });
    const proInput = productores.map((p) => {
        return { value: p.nombre, label: p.nombre };
    });
    function convertToDate(dateString) {
        const parts = dateString.split("/");
        // Month is 0-based, so we subtract 1
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }
    const conFiltro = () => {
        if (infoTicket.pas.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTicket.pas.some((ele) => {
                    return ele.value === expediente.productorAsociado;
                });
            });
        }
        if (infoTicket.comp.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTicket.comp.some((ele) => {
                    return ele.value === expediente.compania;
                });
            });
        }
        if (infoTicket.estado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTicket.estado.some((ele) => {
                    return ele.value === expediente.estado;
                });
            });
        }
        if (infoTicket.nroFrom !== null) {
            updatedList = updatedList.filter((expediente) => {
                return expediente.nro >= infoTicket.nroFrom;
            });
        }
        if (infoTicket.nroTo !== null) {
            updatedList = updatedList.filter((expediente) => {
                return expediente.nro <= infoTicket.nroTo;
            });
        }
        if (infoTicket.expFrom) {
            updatedList = updatedList.filter(
                (expediente) =>
                    expediente.fechaExp.includes("/") &&
                    convertToDate(expediente.fechaExp) >= new Date(infoTicket.expFrom)
            );
        }
        if (infoTicket.expTo) {
            updatedList = updatedList.filter(
                (expediente) =>
                    expediente.fechaExp.includes("/") &&
                    convertToDate(expediente.fechaExp) <= new Date(infoTicket.expTo)
            );
        }
        if (infoTicket.nroFrom !== null) {
            updatedList1 = updatedList1.filter((expediente) => {
                return expediente.nro >= infoTicket.nroFrom;
            });
        }
        if (infoTicket.nroTo !== null) {
            updatedList1 = updatedList1.filter((expediente) => {
                return expediente.nro <= infoTicket.nroTo;
            });
        }
        if (infoTicket.expFrom) {
            updatedList1 = updatedList1.filter(
                (expediente) =>
                    expediente.fechaExp.includes("/") &&
                    convertToDate(expediente.fechaExp) >= new Date(infoTicket.expFrom)
            );
        }
        if (infoTicket.expTo) {
            updatedList1 = updatedList1.filter(
                (expediente) =>
                    expediente.fechaExp.includes("/") &&
                    convertToDate(expediente.fechaExp) <= new Date(infoTicket.expTo)
            );
        }
        if (infoTicket.mesCobradoFrom !== null) { updatedList = updatedList.filter((expediente) => (Date.parse(expediente.mesCobrado)) > (new Date(infoTicket.mesCobradoFrom).setHours(0, 0, 0, 0))) }
        if (infoTicket.mesCobradoTo !== null) { updatedList = updatedList.filter((expediente) => (Date.parse(expediente.mesCobrado)) < (new Date(infoTicket.mesCobradoTo).setHours(0, 0, 0, 0))) }
        if (infoTicket.mesCobradoFrom !== null) { updatedList1 = updatedList1.filter((expediente) => (Date.parse(expediente.mesCobrado)) > (new Date(infoTicket.mesCobradoFrom).setHours(0, 0, 0, 0))) }
        if (infoTicket.mesCobradoTo !== null) { updatedList1 = updatedList1.filter((expediente) => (Date.parse(expediente.mesCobrado)) < (new Date(infoTicket.mesCobradoTo).setHours(0, 0, 0, 0))) }
    };
    conFiltro();
    const conFiltro1 = () => {
        if (infoTicket2.pas.length > 0) {
            updatedList20 = updatedList20.filter((expediente) => {
                return infoTicket2.pas.some((ele) => {
                    return ele.value === expediente.productorAsociado;
                });
            });
        }
        if (infoTicket2.comp.length > 0) {
            updatedList20 = updatedList20.filter((expediente) => {
                return infoTicket2.comp.some((ele) => {
                    return ele.value === expediente.compania;
                });
            });
        }
        if (infoTicket2.estado.length > 0) {
            updatedList20 = updatedList20.filter((expediente) => {
                return infoTicket2.estado.some((ele) => {
                    return ele.value === expediente.estado;
                });
            });
        }
        if (infoTicket2.nroFrom !== null) {
            updatedList20 = updatedList20.filter((expediente) => {
                return expediente.nro >= infoTicket2.nroFrom;
            });
        }
        if (infoTicket2.nroTo !== null) {
            updatedList20 = updatedList20.filter((expediente) => {
                return expediente.nro <= infoTicket2.nroTo;
            });
        }
        if (infoTicket2.expFrom) {
            updatedList20 = updatedList20.filter(
                (expediente) =>
                    expediente.fechaExp.includes("/") &&
                    convertToDate(expediente.fechaExp) >= new Date(infoTicket2.expFrom)
            );
        }
        if (infoTicket2.expTo) {
            updatedList20 = updatedList20.filter(
                (expediente) =>
                    expediente.fechaExp.includes("/") &&
                    convertToDate(expediente.fechaExp) <= new Date(infoTicket2.expTo)
            );
        }
        if (infoTicket2.nroFrom !== null) {
            updatedList21 = updatedList21.filter((expediente) => {
                return expediente.nro >= infoTicket2.nroFrom;
            });
        }
        if (infoTicket2.nroTo !== null) {
            updatedList21 = updatedList21.filter((expediente) => {
                return expediente.nro <= infoTicket2.nroTo;
            });
        }
        if (infoTicket2.expFrom) {
            updatedList21 = updatedList21.filter(
                (expediente) =>
                    expediente.fechaExp.includes("/") &&
                    convertToDate(expediente.fechaExp) >= new Date(infoTicket2.expFrom)
            );
        }
        if (infoTicket2.expTo) {
            updatedList21 = updatedList21.filter(
                (expediente) =>
                    expediente.fechaExp.includes("/") &&
                    convertToDate(expediente.fechaExp) <= new Date(infoTicket2.expTo)
            );
        }
        if (infoTicket2.mesCobradoFrom !== null) { updatedList20 = updatedList20.filter((expediente) => (Date.parse(expediente.mesCobrado)) > (new Date(infoTicket2.mesCobradoFrom).setHours(0, 0, 0, 0))) }
        if (infoTicket2.mesCobradoTo !== null) { updatedList20 = updatedList20.filter((expediente) => (Date.parse(expediente.mesCobrado)) < (new Date(infoTicket2.mesCobradoTo).setHours(0, 0, 0, 0))) }
        if (infoTicket2.mesCobradoFrom !== null) { updatedList21 = updatedList21.filter((expediente) => (Date.parse(expediente.mesCobrado)) > (new Date(infoTicket2.mesCobradoFrom).setHours(0, 0, 0, 0))) }
        if (infoTicket2.mesCobradoTo !== null) { updatedList21 = updatedList21.filter((expediente) => (Date.parse(expediente.mesCobrado)) < (new Date(infoTicket2.mesCobradoTo).setHours(0, 0, 0, 0))) }
    };
    conFiltro1();
    let itemsPerPage = 5;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = productores.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(productores.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % productores.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    };
    const [itemOffset1, setItemOffset1] = useState(0);
    const endOffset1 = itemOffset + itemsPerPage;
    const currentItems1 = companias.slice(itemOffset1, endOffset1);
    const pageCount1 = Math.ceil(companias.length / itemsPerPage);
    const [currentPage1, setCurrentPage1] = useState(0);
    const handlePageClick1 = (event) => {
        const newOffset = (event.selected * itemsPerPage) % companias.length;
        setItemOffset1(newOffset);
        setCurrentPage1(event.selected);
    };
    return (
        <main className="pe-5 ps-5 mainEstadistica">
            <section className="">
                <div className="center m-auto w-50">
                    <div className="pe-1 w-100">
                        <h6 className="subtituloDelFiltro m-0 ps-1">Productores</h6>
                        <MultiSelect
                            className=""
                            options={proInput}
                            value={infoTicket.pas}
                            onChange={(e) => {
                                setInfoTicket((prevState) => ({
                                    ...prevState,
                                    pas: e,
                                }));
                            }}
                        />
                    </div>
                    <div className="pe-1 w-100">
                        <h6 className="subtituloDelFiltro m-0 ps-1">Compañias</h6>
                        <MultiSelect
                            className=""
                            options={compInput}
                            value={infoTicket.comp}
                            onChange={(e) => {
                                setInfoTicket((prevState) => ({
                                    ...prevState,
                                    comp: e,
                                }));
                            }}
                        />
                    </div>
                    <div className="w-100">
                        <h6 className="subtituloDelFiltro m-0 ps-1">Estado</h6>
                        <MultiSelect
                            options={estadoInput}
                            value={infoTicket.estado}
                            onChange={(e) => {
                                setInfoTicket((prevState) => ({
                                    ...prevState,
                                    estado: e,
                                }));
                            }}
                        />
                    </div>
                    <div className="w-100 ms-3">
                        <label htmlFor="">Mes Cobrado From</label>
                        <div className="">
                            <input type="month" class="inputsDelFiltro" value={infoTicket.mesCobradoFrom}
                                placeholder={infoTicket.mesCobradoFrom}
                                onChange={(e) =>
                                    setInfoTicket((prevState) => ({
                                        ...prevState,
                                        mesCobradoFrom: e.target.value,
                                    }))} />
                        </div>
                    </div>
                </div>
                <div className="center m-auto  mt-3 w-50">
                    <div className="w-100 pe-1">
                        <h6 className="subtituloDelFiltro m-0 ps-1">Fecha Exp</h6>
                        <div className="d-flex">
                            <div className="w-100 pe-1">
                                <input
                                    type="date"
                                    class="inputsDelFiltro"
                                    value={infoTicket.expFrom}
                                    placeholder={infoTicket.expFrom}
                                    onChange={(e) =>
                                        setInfoTicket((prevState) => ({
                                            ...prevState,
                                            expFrom: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                            <div className="w-100">
                                <input
                                    type="date"
                                    class="inputsDelFiltro"
                                    value={infoTicket.expTo}
                                    placeholder={infoTicket.expTo}
                                    onChange={(e) =>
                                        setInfoTicket((prevState) => ({
                                            ...prevState,
                                            expTo: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-100 pe-1">
                        <h6 className="subtituloDelFiltro m-0 ps-1">Nro</h6>
                        <div className="d-flex">
                            <div className="w-100 pe-1">
                                <input
                                    type="number"
                                    class="inputsDelFiltro"
                                    value={infoTicket.nroFrom}
                                    placeholder={infoTicket.nroFrom}
                                    onChange={(e) =>
                                        setInfoTicket((prevState) => ({
                                            ...prevState,
                                            nroFrom: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                            <div className="w-100">
                                <input
                                    type="number"
                                    class="inputsDelFiltro"
                                    value={infoTicket.nroTo}
                                    placeholder={infoTicket.nroTo}
                                    onChange={(e) =>
                                        setInfoTicket((prevState) => ({
                                            ...prevState,
                                            nroTo: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-100 ms-3">
                        <label htmlFor="">Mes Cobrado To</label>
                        <div className="">
                            <input type="month" class="inputsDelFiltro" value={infoTicket.mesCobradoTo} placeholder={infoTicket.mesCobradoTo}
                                onChange={(e) =>
                                    setInfoTicket((prevState) => ({
                                        ...prevState,
                                        mesCobradoTo: e.target.value,
                                    }))} />
                        </div>
                    </div>
                </div>
            </section>
            {infoTicket.expFrom !== null && infoTicket.expTo !== null ?
                <>{infoTicket.pas.length !== 0 ? <PasNuevoEstadisticas updatedList={updatedList} updatedList1={updatedList1} infoTicket={infoTicket} comp={companias} pas={productores} /> : (infoTicket.comp.length !== 0 ? <CompNuevoEstadisticas updatedList={updatedList} updatedList1={updatedList1} infoTicket={infoTicket} pas={productores} comp={companias} /> : <div></div>)}</>
                : <></>}
            <section style={{ marginTop: '100vh' }}>
                <div className="center m-auto w-50">
                    <div className="pe-1 w-100">
                        <h6 className="subtituloDelFiltro m-0 ps-1">Productores</h6>
                        <MultiSelect
                            className=""
                            options={proInput}
                            value={infoTicket2.pas}
                            onChange={(e) => {
                                setInfoTicket2((prevState) => ({
                                    ...prevState,
                                    pas: e,
                                }));
                            }}
                        />
                    </div>
                    <div className="pe-1 w-100">
                        <h6 className="subtituloDelFiltro m-0 ps-1">Compañias</h6>
                        <MultiSelect
                            className=""
                            options={compInput}
                            value={infoTicket2.comp}
                            onChange={(e) => {
                                setInfoTicket2((prevState) => ({
                                    ...prevState,
                                    comp: e,
                                }));
                            }}
                        />
                    </div>
                    <div className="w-100">
                        <h6 className="subtituloDelFiltro m-0 ps-1">Estado</h6>
                        <MultiSelect
                            options={estadoInput}
                            value={infoTicket2.estado}
                            onChange={(e) => {
                                setInfoTicket2((prevState) => ({
                                    ...prevState,
                                    estado: e,
                                }));
                            }}
                        />
                    </div>
                    <div className="w-100 ms-3">
                        <label htmlFor="">Mes Cobrado From</label>
                        <div className="">
                            <input type="month" class="inputsDelFiltro" value={infoTicket2.mesCobradoFrom}
                                placeholder={infoTicket2.mesCobradoFrom}
                                onChange={(e) =>
                                    setInfoTicket2((prevState) => ({
                                        ...prevState,
                                        mesCobradoFrom: e.target.value,
                                    }))} />
                        </div>
                    </div>
                </div>
                <div className="center m-auto  mt-3 w-50">
                    <div className="w-100 pe-1">
                        <h6 className="subtituloDelFiltro m-0 ps-1">Fecha Exp</h6>
                        <div className="d-flex">
                            <div className="w-100 pe-1">
                                <input
                                    type="date"
                                    class="inputsDelFiltro"
                                    value={infoTicket2.expFrom}
                                    placeholder={infoTicket2.expFrom}
                                    onChange={(e) =>
                                        setInfoTicket2((prevState) => ({
                                            ...prevState,
                                            expFrom: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                            <div className="w-100">
                                <input
                                    type="date"
                                    class="inputsDelFiltro"
                                    value={infoTicket2.expTo}
                                    placeholder={infoTicket2.expTo}
                                    onChange={(e) =>
                                        setInfoTicket2((prevState) => ({
                                            ...prevState,
                                            expTo: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-100 pe-1">
                        <h6 className="subtituloDelFiltro m-0 ps-1">Nro</h6>
                        <div className="d-flex">
                            <div className="w-100 pe-1">
                                <input
                                    type="number"
                                    class="inputsDelFiltro"
                                    value={infoTicket.nroFrom}
                                    placeholder={infoTicket.nroFrom}
                                    onChange={(e) =>
                                        setInfoTicket((prevState) => ({
                                            ...prevState,
                                            nroFrom: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                            <div className="w-100">
                                <input
                                    type="number"
                                    class="inputsDelFiltro"
                                    value={infoTicket.nroTo}
                                    placeholder={infoTicket.nroTo}
                                    onChange={(e) =>
                                        setInfoTicket((prevState) => ({
                                            ...prevState,
                                            nroTo: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-100 ms-3">
                        <label htmlFor="">Mes Cobrado To</label>
                        <div className="">
                            <input type="month" class="inputsDelFiltro" value={infoTicket2.mesCobradoTo} placeholder={infoTicket2.mesCobradoTo}
                                onChange={(e) =>
                                    setInfoTicket2((prevState) => ({
                                        ...prevState,
                                        mesCobradoTo: e.target.value,
                                    }))} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="row mt-3">
                <div className="col-6">
                    <div className="tablaHead row  m-0">
                        <div class="col p-0 d-flex"><p className="tituloTabla ps-">ASIGNADO</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">CANT EXP</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">NIVEL 1</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">NIVEL 2</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">NIVEL 3</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">HOY</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">MAÑANA</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">VENCIDOS</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">PRIORIDAD</p></div>
                    </div>
                    <div className="mt-2">
                        {asignados.filter((a) => a.cargo === 'EMPLEADO' || a.cargo === 'GERENCIA').filter((a) => a.id !== 56 && a.id !== 11 & a.id !== 42).map((a) => <EstadisticaAsignadoNuevo expedientes={updatedList} asignado={a} />)}
                    </div>
                </div>
                <div className="col-6">
                    <div className="tablaHead row  m-0">
                        <div class="col p-0 d-flex"><p className="tituloTabla ps-3">PAS</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">CANT EXP</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">PROC PAGO</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">ACEPTACION</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">PRES SIN RES</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">COBRADO</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">CERRADO</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">NIVEL 1</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">NIVEL 2</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">NIVEL 3</p></div>
                    </div>
                    {productores.slice(itemOffset, Number(itemOffset + 8)).map((p) => <PasEstadisticas expedientes={updatedList20} pas={p} />)}
                    <div className="mt-4 center">
                        <ReactPaginate
                            className='center p-0'
                            forcePage={currentPage}
                            activeClassName='item active'
                            breakClassName='item break-me'
                            breakLabel={'...'}
                            marginPagesDisplayed={1}
                            onPageChange={handlePageClick}
                            pageClassName='item pagination-page'
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                        />
                    </div>
                </div>
            </section>
            <section className="row mt-3">
                <div className="col">
                    <div className="tablaHead row  m-0">
                        <div class="col p-0 d-flex"><p className="tituloTabla ps-3">COMP</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">CANT EXP</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">PROC PAGO</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">ACEPTACION</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">PRES SIN RES</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">COBRADO</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">CERRADO</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">NIVEL 1</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">NIVEL 2</p></div>
                        <div class="col p-0 d-flex"><p className="tituloTabla">NIVEL 3</p></div>
                    </div>
                    {companias.slice(itemOffset1, Number(itemOffset1 + 8)).map((p) => <CompEstadisticas expedientes={updatedList20} comp={p} />)}
                    <div className="mt-4 center">
                        <ReactPaginate
                            className='center p-0'
                            forcePage={currentPage1}
                            activeClassName='item active'
                            breakClassName='item break-me'
                            breakLabel={'...'}
                            marginPagesDisplayed={1}
                            onPageChange={handlePageClick1}
                            pageClassName='item pagination-page'
                            pageRangeDisplayed={5}
                            pageCount={pageCount1}
                        />
                    </div>
                </div>
            </section>
        </main>
    );
}
