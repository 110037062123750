import './anclado.css'
export default function ComentarioAnclado({ expediente }) {
return (
    <section className='col-6'>
        <div className='ancladoContainer'>
        <p className='text-white ps-4 pt-2 pAnclado m-0 pe-1'>{expediente.anclado}</p>
        <div className="d-flex flex-row-reverse  text-white fs-italic">
            <p className="me-2 p-0 m-0 pe-5">{expediente.ancladoFecha}</p>
            <p className="me-2 p-0 m-0">{expediente.ancladoUser}</p>
        </div>
    </div>
    </section>
)
}