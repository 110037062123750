import { createContext, useState } from 'react'
export const editarContext = createContext()
export function EditarContextProvider ({children}) {
    const [editar, setEditar] = useState(false)
    function setEditarTrue () {
        setEditar(true)
    }
    function setEditarFalse () {
        setEditar(false)
    }
    return (
        <editarContext.Provider value={{setEditarTrue, setEditarFalse, editar, setEditar}}>
            {children}
        </editarContext.Provider>
    )
}