import './mediacion.css'
import Select from 'react-select';
import DatePicker, { registerLocale } from "react-datepicker";
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios'
import { Link } from 'react-router-dom';
export default function PlanillaExpedienteMediacion({ expediente, }) {
    const [alert1, setAlert] = useState(true)
    //if (expediente.contadorNegociacionMediacion > 1 && alert1) {
        //alert(`La Etapa negociación ha sido activada ${expediente.contadorNegociacionMediacion} veces`)
        //setAlert(false)
    //}
    const [empleadoAsignado, setEmpleadoAsignado] = useState([])
    useEffect(() => {
        axios.get(`https://api.tuveunchoque.com.ar/api/mediacion/estudiosAsignadosMediacion`).then((res) => {
            setEmpleadoAsignado(res.data);
        });
    }, []);
    const solicitudDocumentalInput = ['SI', 'NO']
    const pagoGastosJudiciales = ['CLIENTE', 'PYP']
    const [etapa, setEtapa] = useState(expediente.etapaMediacion)
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const today = new Date()
    const now = today.toLocaleString()
    //SOLICITUD FECHA
    const [solicitudFecha, setSolicitudFecha] = useState({ solicitud: expediente.solicitudInformeDominio });
    //renderizar color completo
    let completoSolicitudExp = 0
    if (expediente.solicitudInformeDominio !== 0) { completoSolicitudExp += 1 }
    const actualizarSolicitudFecha = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/solicitudFecha`,
            {
                id: expediente.id,
                solicitud: solicitudFecha.solicitud,
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [solicitudFecha, expediente,])
    const completoSolicitudFecha = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoSolicitudFecha`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa SOLICITUD DE FECHA',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [solicitudFecha, expediente,])
    const activarSolicitudFecha = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/activarSolicitudFecha`,
            {
                id: expediente.id,
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [solicitudFecha, expediente,])

    //FECHA MEDIACION
    const [fechaMediacion, setFechaMediacion] = useState({ fecha: expediente.fechaMediacion, hora: expediente.horaMediacion, link: expediente.linkAudencias })
    //renderizar color completo
    let completoFechaExp = 0
    if (expediente.fechaMediacion !== '') { completoFechaExp += 1 }
    if (expediente.horaMediacion !== '') { completoFechaExp += 1 }
    if (expediente.linkAudencias !== '') { completoFechaExp += 1 }
    const actualizarFechaMediacion = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/fechaAsignada`,
            {
                id: expediente.id,
                fecha: new Date(fechaMediacion.fecha).toLocaleDateString('fr-FR'),
                hora: fechaMediacion.hora,
                link: fechaMediacion.link
            }
        ).then(async (response) => {
            if (response) {
                if (fechaMediacion.fecha !== '' && fechaMediacion.hora !== '') {
                    await axios.post(`https://api.tuveunchoque.com.ar/api/mail/mailMediacionFechaAsignada`, {
                        exp: expediente.nro,
                        fecha: fechaMediacion.fecha,
                        hora: fechaMediacion.hora
                    }
                    )
                    window.location.reload()
                } else {
                    window.location.reload()
                }
            }
        })
    }, [fechaMediacion, expediente,])
    const completoFechaMediacion = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoFechaAsignada`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa FECHA ASIGNADA',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [fechaMediacion, expediente,])
    const completo2FechaMediacion = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completo2FechaAsignada`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa FECHA ASIGNADA',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [fechaMediacion, expediente,])

    //NEGOCIACION
    const [negociacion, setNegociacion] = useState({ mail: expediente.mailAbogadoMediacion, ra: expediente.raMediacion })
    //renderizar color completo
    let completoNegociacionExp = 0
    if (expediente.mailAbogadoMediacion !== '') { completoNegociacionExp += 1 }
    if (expediente.raMediacion !== '') { completoNegociacionExp += 1 }
    const actualizarNegociacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/negociacion`,
            {
                id: expediente.id,
                mail: negociacion.mail,
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [negociacion, expediente,])
    const completoNegociacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoNegociacion`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa NEGOCIACION',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [negociacion, expediente,])
    const activarNegociacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/activarNegociacion`,
            {
                id: expediente.id,
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [negociacion, expediente,])

    //REVISIONES LEGALES
    const [revisionesLegales, setRevisionesLegales] = useState({ nombre: expediente.nombreDatoTestigoMediacion, dni: expediente.dniDatoTestigoMediacion, tel: expediente.telefonoDatoTestigoMediacion, tipo: expediente.tipoTestigoMediacion, demandado: expediente.juridisccionDemandadoMediacion, aseguradora: expediente.juridisccionAseguradoraMediacion, posibles: expediente.posiblesJuridisccionesMediacion, blsg: expediente.blsgRevisionesLegales, accidente: expediente.accidenteRevisionesLegales, estudioAsignado: expediente.estudioAsignadoRevisionesLegales, pagoGastosJudiciales: expediente.pagoGastosJudicialesRevisionesLegales })
    //renderizar color completo
    let completoRevisionesLegalesExp = 0
    if (expediente.nombreDatoTestigoMediacion !== '') { completoRevisionesLegalesExp += 1 }
    if (expediente.dniDatoTestigoMediacion !== '') { completoRevisionesLegalesExp += 1 }
    if (expediente.telefonoDatoTestigoMediacion !== '') { completoRevisionesLegalesExp += 1 }
    if (expediente.tipoTestigoMediacion !== '') { completoRevisionesLegalesExp += 1 }
    if (expediente.juridisccionDemandadoMediacion !== '') { completoRevisionesLegalesExp += 1 }
    if (expediente.juridisccionAseguradoraMediacion !== '') { completoRevisionesLegalesExp += 1 }
    if (expediente.posiblesJuridisccionesMediacion !== '') { completoRevisionesLegalesExp += 1 }
    const actualizarRevisionesLegales = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/revisionesLegales`,
            {
                id: expediente.id,
                estudioAsignado: revisionesLegales.estudioAsignado
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [revisionesLegales, expediente,])
    const completoRevisionesLegales = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoRevisionesLegales`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa REVISIONES LEGALES',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [revisionesLegales, expediente,])
    const atrasRevisionesLegales = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/atrasRevisionesLegales`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [revisionesLegales, expediente,])
    const cerradoRevisionesLegales = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/cerradoRevisionesLegales`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [revisionesLegales, expediente,])

    //ARMADO DERIVACION
    const [armadoDerivacion, setArmadoDerivacion] = useState({ acta: expediente.actaDeMediacion, entrevista: expediente.entrevistaMediacion, poder: expediente.poderFirmadoMediacion, documental: expediente.documentalCompletaMediacion })
    //renderizar color completo
    let completoArmadoDerivacionExp = 0
    if (expediente.actaDeMediacion !== 0) { completoArmadoDerivacionExp += 1 }
    if (expediente.entrevistaMediacion !== 0) { completoArmadoDerivacionExp += 1 }
    if (expediente.poderFirmadoMediacion !== 0) { completoArmadoDerivacionExp += 1 }
    if (expediente.documentalCompletaMediacion !== 0) { completoArmadoDerivacionExp += 1 }
    const actualizarArmadoDerivacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/armadoDerivacion`,
            {
                id: expediente.id,
                acta: armadoDerivacion.acta,
                entrevista: armadoDerivacion.entrevista,
                poderFirmado: armadoDerivacion.poder,
                documentalCompleta: armadoDerivacion.documental,
                nombre: revisionesLegales.nombre,
                dni: revisionesLegales.dni,
                tel: revisionesLegales.tel,
                tipoTestigo: revisionesLegales.tipo,
                demandado: revisionesLegales.demandado,
                aseguradora: revisionesLegales.aseguradora,
                posibles: revisionesLegales.posibles,
                blsg: revisionesLegales.blsg,
                accidente: revisionesLegales.accidente,
                gastosJudiciales: revisionesLegales.pagoGastosJudiciales,
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [armadoDerivacion, expediente, revisionesLegales])
    const completoArmadoDerivacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoArmadoDerivacion`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa ARMADO DERIVACION',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [armadoDerivacion, expediente,])

    //ESPERA CONFIRMACION
    const [esperaConfirmacion, setEsperaConfirmacion] = useState({ solicitud: expediente.solicitudDeDocumentalMediacion, text: expediente.solicitudDeDocumentalMediacionText })
    //renderizar color completo
    let completoEsperaConfirmacionExp = 0
    if (expediente.solicitudDeDocumentalMediacion != 0) { completoEsperaConfirmacionExp += 1 }
    const actualizarEsperaConfirmacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/esperaConfirmacion`,
            {
                id: expediente.id,
                solicitud: esperaConfirmacion.solicitud,
                text: esperaConfirmacion.text
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa ESPERA CONFIRMACION',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [esperaConfirmacion, expediente,])
    const completoEsperaConfirmacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoEsperaConfirmacion`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa ESPERA CONFIRMACION',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [esperaConfirmacion, expediente,])
    // ACEPTACION MEDIACION
    const aceptarAceptacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/aceptarAceptacion`,
            {
                id: expediente.id,
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [esperaConfirmacion, expediente,])
    const completoAceptacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoAceptacion`,
            {
                id: expediente.id,
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa ACEPTACION',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.open(`https://sistema.tuveunchoque.com.ar/#/expediente/cobro/nuevo?nro=${expediente.nro}`, '_blank');
                window.location.reload()
            }
        })
    }, [esperaConfirmacion, expediente])
    // LEGALES
    const [legales, setLegales] = useState({ detallesExpedienteLegales: expediente.detallesExpedienteLegales })
    const actualizarLegales = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/actualizarLegales`,
            {
                id: expediente.id,
                detallesExpedienteLegales: legales.detallesExpedienteLegales
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [legales, expediente,])
    const completoLegales = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoLegales`,
            {
                id: expediente.id,
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa LEGALES',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [esperaConfirmacion, expediente,])
    const btnAzul = { backgroundColor: '#6DB8F2', color: 'white' }
    const btnVerde = { backgroundColor: '#6DF2C7', color: 'white' }
    const btnNaranja = { backgroundColor: '#F2AB6D', color: 'white' }
    const btnNaranja2 = { backgroundColor: '#F2AB6D', color: 'white', width: '142px' }
    const btnRojo = { backgroundColor: '#F26D98', color: 'white' }
    return (
        <div class="acordeonContainer col me-2" id="accordionExample">
            <div class="mb-1">
                <h2 class="m-0" id="headingOne" onClick={() => setEtapa(1)}>
                    <button className={completoSolicitudExp === 1 ? 'headerSuccessAcordeon' : (Number(etapa) === 1 ? 'headerPresentAcordeonNaranja' : 'headerPresentAcordeon')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded={Number(etapa) === 1 ? 'true' : 'false'} aria-controls="collapseOne">
                        <div className="d-flex justify-content-between ps-5 pe-5">
                            <p className={Number(etapa) === 1 ? 'tituloAcordeon letra m-0' : 'tituloAcordeon letra  m-0'}>SOLICITUD DE FECHA</p>
                            <p className={Number(etapa) === 1 ? 'tituloAcordeon letra m-0' : 'tituloAcordeon letra  m-0'}>{`FECHA DE FINALIZACION: ${expediente.finalizacionSolicitudFecha}`}</p>
                        </div>
                    </button>
                </h2>
                <div id="collapseOne" className={Number(etapa) === 1 ? 'bodyAcordeon collapse.show' : 'bodyAcordeon collapse'} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className='ps-5 pe-5 row'>
                        <div className="col-6">
                            <div className="mb-3 mt-4">
                                <label class="d-block">Solicitud Informe Dominio</label>
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    checked={solicitudFecha.solicitud}
                                    onChange={(e) => setSolicitudFecha((prevState) => ({
                                        ...prevState,
                                        solicitud: e.target.checked,
                                    }))}
                                />
                            </div>
                            <div className="">
                                <Link to={`https://estudio-pyp.com.ar/formulario-mediacion-?EXP=${expediente.nro}`} target="_blank" rel="noopener noreferrer">
                                    <button className='btn btn-primary mt-3'>Generador de Texto Automatico</button>
                                </Link>
                            </div>
                            <div className="d-flex mt-4">
                                <div className="center pe-3">
                                    <button className='btn mt-5 center' style={btnAzul} onClick={(e) => actualizarSolicitudFecha()}>Actualizar</button>
                                </div>
                                <div className="center pe-3">
                                    <button className='btn mt-5 center' style={btnVerde} onClick={(e) => completoSolicitudFecha()}>Completo</button>
                                </div>
                                <div className="center">
                                    <button className='btn mt-5 center' style={btnNaranja} onClick={(e) => activarSolicitudFecha()}>Activar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-1">
                <h2 class="m-0" id="headingTwo" onClick={() => setEtapa(2)}>
                    <button className={completoFechaExp === 3 ? 'headerSuccessAcordeon' : (Number(etapa) === 2 ? 'headerPresentAcordeonNaranja' : 'headerPresentAcordeon')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded={Number(etapa) === 2 ? 'true' : 'false'} aria-controls="collapseTwo">
                        <div className="d-flex justify-content-between pe-5 ps-5">
                            <p className={Number(etapa) === 2 ? 'tituloAcordeon letra m-0' : 'tituloAcordeon letra  m-0'}>FECHA ASIGNADA</p>
                            <p className={Number(etapa) === 2 ? 'tituloAcordeon letra m-0' : 'tituloAcordeon letra  m-0'}>{`FECHA DE FINALIZACION: ${expediente.finalizacionFechaAsignada}`}</p>
                        </div>
                    </button>
                </h2>
                <div id="collapseTwo" className={Number(etapa) === 2 ? 'bodyAcordeon collapse.show' : 'bodyAcordeon collapse'} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className='row ps-5 pe-5'>
                        <div className="col-6">
                            <div className="mb-3 mt-1">
                                <label class="">Fecha de Mediacion: {expediente.fechaMediacion}</label>
                                <DatePicker locale='es' value={new Date(fechaMediacion.fecha).toLocaleDateString('fr-FR')} className="form-control form-control-sm"
                                    onChange={(e) => setFechaMediacion((prevState) => ({
                                        ...prevState,
                                        fecha: e,
                                    }))}
                                />
                            </div>
                            <div className="mb-1">
                                <label class="">Hora de Mediacion</label>
                                <input type="time" class="form-control w-25"
                                    onChange={(e) => setFechaMediacion((prevState) => ({
                                        ...prevState,
                                        hora: e.target.value,
                                    }))} />
                            </div>
                            <div className="mb-1">
                                <label class="">Link Audiencias</label>
                                <input type="text" class="form-control" placeholder={fechaMediacion.link} value={fechaMediacion.link}
                                    onChange={(e) => setFechaMediacion((prevState) => ({
                                        ...prevState,
                                        link: e.target.value,
                                    }))} />
                            </div>
                            <div className="d-flex">
                                <div className=" mt-1 pe-3 center">
                                    <button className='btn center' style={btnAzul} onClick={(e) => actualizarFechaMediacion()}>Actualizar</button>
                                </div>
                                <div className=" mt-1 pe-3 center">
                                    <button className='btn center' style={btnVerde} onClick={(e) => completoFechaMediacion()}>Negociacion</button>
                                </div>
                                <div className=" mt-1 center">
                                    <button className='btn center' style={btnNaranja2} onClick={(e) => completo2FechaMediacion()}>Sin Negociacion</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-1">
                <h2 class="m-0" id="headingThree" onClick={() => setEtapa(3)}>
                    <button className={completoNegociacionExp === 2 ? 'headerSuccessAcordeon' : (Number(etapa) === 3 ? 'headerPresentAcordeonNaranja' : 'headerPresentAcordeon')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded={Number(etapa) === 3 ? 'true' : 'false'} aria-controls="collapseThree">
                        <div className="d-flex justify-content-between ps-5 pe-5">
                            <p className={Number(etapa) === 3 ? 'tituloAcordeon letra m-0' : 'tituloAcordeon letra  m-0'}>NEGOCIACION</p>
                            <p className={Number(etapa) === 3 ? 'tituloAcordeon letra m-0' : 'tituloAcordeon letra  m-0'}>{`FECHA DE FINALIZACION: ${expediente.finalizacionNegociacion}`}</p>
                        </div>
                    </button>
                </h2>
                <div id="collapseThree" className={Number(etapa) === 3 ? 'bodyAcordeon ' : 'bodyAcordeon collapse'} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className='row ps-5 pe-5'>
                        <div className="col-6">
                            <div className="mb-3 mt-4">
                                <label class="">Mail de Abogado</label>
                                <input type="text" class="form-control" placeholder={negociacion.mail} value={negociacion.mail}
                                    onChange={(e) => setNegociacion((prevState) => ({
                                        ...prevState,
                                        mail: e.target.value,
                                    }))} />
                                <div className="d-flex mt-5 pt-5">
                                    <div className="pe-3 center">
                                        <button className='btn center' style={btnAzul} onClick={(e) => actualizarNegociacion(e)}>Actualizar</button>
                                    </div>
                                    <div className="center pe-3">
                                        <button className='btn center' style={btnVerde} onClick={(e) => completoNegociacion(e)}>Completo</button>
                                    </div>
                                    <div className='me-1'><button className='btn' style={btnNaranja} onClick={(e) => activarNegociacion(e)}>Activar</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-1">
                <h2 class="m-0" id="headingFour" onClick={() => setEtapa(4)}>
                    <button className={completoRevisionesLegalesExp === 7 ? 'headerSuccessAcordeon' : (Number(etapa) === 4 ? 'headerPresentAcordeonNaranja' : 'headerPresentAcordeon')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded={Number(etapa) === 4 ? 'true' : 'false'} aria-controls="collapseFour">
                        <div className="d-flex justify-content-between ps-5 pe-5">
                            <p className={Number(etapa) === 4 ? 'tituloAcordeon letra m-0' : 'tituloAcordeon letra  m-0'}>REVISIONES LEGALES</p>
                            <p className={Number(etapa) === 4 ? 'tituloAcordeon letra m-0' : 'tituloAcordeon letra  m-0'}>{`FECHA DE FINALIZACION: ${expediente.finalizacionRevisionesLegales}`}</p>
                        </div>
                    </button>
                </h2>
                <div id="collapseFour" className={Number(etapa) === 4 ? 'bodyAcordeon collapse.show' : 'bodyAcordeon2 collapse'} aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className='row ps-5 pe-5'>
                        <div className="col-6">
                            <div className="mb-1 mt-3">
                                <label class="mb-1">Estudio Asignado</label>
                                <Select
                                    options={empleadoAsignado.map(asignado => ({ value: asignado.nombre, label: asignado.nombre }))}
                                    placeholder={revisionesLegales.estudioAsignado}
                                    onChange={(newValue) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        estudioAsignado: newValue.value,
                                    }))}
                                />
                            </div>
                            <div className="center mt-3">
                                <div className='me-1'><button className='btn' style={btnAzul} onClick={(e) => actualizarRevisionesLegales(e)}>Actualizar</button></div>
                                <div className='me-1'><button className='btn' style={btnNaranja} onClick={(e) => atrasRevisionesLegales(e)}>Negociacion</button></div>
                                <div className='me-1'><button className='btn' style={btnVerde} onClick={(e) => completoRevisionesLegales(e)}>Completo</button></div>
                                <div className='me-1'><button className='btn' style={btnRojo} onClick={(e) => cerradoRevisionesLegales(e)}>Cerrado</button></div>
                            </div>
                        </div>
                        <div className="col-6">

                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-1">
                <h2 class="m-0" id="headingFive" onClick={() => setEtapa(5)}>
                    <button className={completoArmadoDerivacionExp === 4 ? 'headerSuccessAcordeon' : (Number(etapa) === 5 ? 'headerPresentAcordeonNaranja' : 'headerPresentAcordeon')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded={Number(etapa) === 5 ? 'true' : 'false'} aria-controls="collapseFive">
                        <div className="d-flex justify-content-between ps-5 pe-5">
                            <p className={Number(etapa) === 5 ? 'tituloAcordeon letra m-0' : 'tituloAcordeon letra  m-0'}>ARMADO DERIVACION</p>
                            <p className={Number(etapa) === 5 ? 'tituloAcordeon letra m-0' : 'tituloAcordeon letra  m-0'}>{`FECHA DE FINALIZACION: ${expediente.finalizacionArmadoDerivacion}`}</p>
                        </div>
                    </button>
                </h2>
                <div id="collapseFive" className={Number(etapa) === 5 ? 'bodyAcordeon2 collapse.show' : 'bodyAcordeon2 collapse'} aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div className='row ps-5 pe-5'>
                        <div className="col-6 mt-3">
                            <div className="mb-3">
                                <label class="">Nombre</label>
                                <input type="text" class="form-control" placeholder={expediente.nombreDatoTestigoMediacion}
                                    onChange={(e) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        nombre: e.target.value,
                                    }))} />
                            </div>
                            <div className="mb-3">
                                <label class="">D.N.I</label>
                                <input type="text" class="form-control" placeholder={expediente.dniDatoTestigoMediacion}
                                    onChange={(e) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        dni: e.target.value,
                                    }))} />
                            </div>
                            <div className="mb-3">
                                <label class="">Telefono</label>
                                <input type="text" class="form-control" placeholder={expediente.telefonoDatoTestigoMediacion}
                                    onChange={(e) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        tel: e.target.value,
                                    }))} />
                            </div>
                            <div className="mb-1 mt-3">
                                <div>
                                    <label class="">Tipo de Testigo</label>
                                    <div className="d-flex">
                                        <div className='divCheckboxAmpliaciones form-check form-switch me-4'>
                                            <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={(e) => setRevisionesLegales((prevState) => ({ ...prevState, blsg: e.target.checked, }))} checked={revisionesLegales.blsg} />
                                            <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">BLSG</label>
                                        </div>
                                        <div className='divCheckboxAmpliaciones form-check form-switch ms-4'>
                                            <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={(e) => setRevisionesLegales((prevState) => ({ ...prevState, accidente: e.target.checked, }))} checked={revisionesLegales.accidente} />
                                            <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">ACCIDENTE</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="center mt-3">
                                <div className='me-1'><button className='btn' style={btnAzul} onClick={(e) => actualizarArmadoDerivacion(e)}>Actualizar</button></div>
                                <div className='me-1'><button className='btn' style={btnVerde} onClick={(e) => completoArmadoDerivacion(e)}>Completo</button></div>
                            </div>
                        </div>
                        <div className="col-6 mt-3">
                            <div className="mb-3">
                                <label class="">Juridisccion Demandado</label>
                                <input type="text" class="form-control" placeholder={expediente.juridisccionDemandadoMediacion}
                                    onChange={(e) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        demandado: e.target.value,
                                    }))} />
                            </div>
                            <div className="mb-3">
                                <label class="">Juridisccion Aseguradora</label>
                                <input type="text" class="form-control" placeholder={expediente.juridisccionAseguradoraMediacion}
                                    onChange={(e) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        aseguradora: e.target.value,
                                    }))} />
                            </div>
                            <div className="mb-3">
                                <label class="">Posibles Juridiscciones A Iniciar (CABA - PROVINCIA)</label>
                                <input type="text" class="form-control" placeholder={expediente.posiblesJuridisccionesMediacion}
                                    onChange={(e) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        posibles: e.target.value,
                                    }))} />
                            </div>
                            <div className="mb-3">
                                <label class="mb-1">PAGO DE GASTOS JUDICIALES</label>
                                <Select
                                    options={pagoGastosJudiciales.map(asignado => ({ value: asignado, label: asignado }))}
                                    placeholder={revisionesLegales.pagoGastosJudiciales}
                                    onChange={(newValue) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        pagoGastosJudiciales: newValue.value,
                                    }))}
                                />
                            </div>
                            <div className="d-flex w-100">
                                <div className="mb-1">
                                    <label class="">Acta Mediacion</label>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={armadoDerivacion.acta}
                                        onChange={(e) => setArmadoDerivacion((prevState) => ({
                                            ...prevState,
                                            acta: e.target.checked,
                                        }))}
                                    />
                                </div>
                                <div className="mb-1">
                                    <label class="">Entrevista</label>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={armadoDerivacion.entrevista}
                                        onChange={(e) => setArmadoDerivacion((prevState) => ({
                                            ...prevState,
                                            entrevista: e.target.checked,
                                        }))}
                                    />
                                </div>
                                <div className="mb-1">
                                    <label class="">Poder Firmado</label>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={armadoDerivacion.poder}
                                        onChange={(e) => setArmadoDerivacion((prevState) => ({
                                            ...prevState,
                                            poder: e.target.checked,
                                        }))}
                                    />
                                </div>
                                <div className="mb-1">
                                    <label class="">Doc. Completa</label>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={armadoDerivacion.documental}
                                        onChange={(e) => setArmadoDerivacion((prevState) => ({
                                            ...prevState,
                                            documental: e.target.checked,
                                        }))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-1">
                <h2 class="m-0" id="headingSix" onClick={() => setEtapa(6)}>
                    <button className={completoEsperaConfirmacionExp === 1 ? 'headerSuccessAcordeon' : (Number(etapa) === 6 ? 'headerPresentAcordeonNaranja' : 'headerPresentAcordeon')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded={Number(etapa) === 6 ? 'true' : 'false'} aria-controls="collapseSix">
                        <div className="d-flex justify-content-between ps-5 pe-5">
                            <p className={Number(etapa) === 6 ? 'tituloAcordeon letra m-0' : 'tituloAcordeon letra  m-0'}>ESPERA CONFIRMACION</p>
                            <p className={Number(etapa) === 6 ? 'tituloAcordeon letra m-0' : 'tituloAcordeon letra  m-0'}>{`FECHA DE FINALIZACION: ${expediente.finalizacionEsperaConfirmacion}`}</p>
                        </div>
                    </button>
                </h2>
                <div id="collapseSix" className={Number(etapa) === 6 ? 'bodyAcordeon collapse.show' : 'bodyAcordeon collapse'} aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                    <div className='row ps-5 pe-5'>
                        <div className="col-6">
                            <div className="mb-3 mt-3">
                                <label class="">Solicitud de Documental</label>
                                <Select
                                    options={solicitudDocumentalInput.map(les => ({ value: les, label: les }))}
                                    className=""
                                    onChange={(newValue) => setEsperaConfirmacion((prevState) => ({
                                        ...prevState,
                                        solicitud: newValue.value,
                                    }))}
                                    placeholder={expediente.solicitudDeDocumentalMediacion}
                                />
                            </div>
                            <div className="mb-3 mt-3">
                                {esperaConfirmacion.solicitud === 'SI' ? <div><textarea
                                    className="form-control"
                                    value={esperaConfirmacion.text}
                                    onChange={(e) =>
                                        setEsperaConfirmacion((prevState) => ({
                                            ...prevState,
                                            text: e.target.value,
                                        }))}
                                /></div> : <div></div>}
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="mt-4 center pe-5">
                                <button className='btn center' style={btnAzul} onClick={(e) => actualizarEsperaConfirmacion(e)}>Actualizar</button>
                            </div>
                            <div className="mt-4 center">
                                <button className='btn center' style={btnVerde} onClick={(e) => completoEsperaConfirmacion(e)}>Completo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-1">
                <h2 class="m-0" id="headingEight" onClick={() => setEtapa(8)}>
                    <button className='headerPresentAcordeon' type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded={Number(etapa) === 8 ? 'true' : 'false'} aria-controls="collapseEight">
                        <div className="d-flex justify-content-between ps-5 pe-5">
                            <p className='tituloAcordeon letra m-0 center'>LEGALES</p>
                        </div>
                    </button>
                </h2>
                <div id="collapseEight" className={Number(etapa) === 8 ? 'bodyAcordeon collapse.show' : 'bodyAcordeon collapse'} aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                    <div className='row ps-5 pe-5 pt-3'>
                        <div className="col-6">
                            <div className="">
                                <label class="mb-1">Estudio Asignado</label>
                                <input type="text" class="form-control" value={expediente.estudioAsignadoRevisionesLegales} />
                            </div>
                            <div className="mt-3">
                                <label class="mb-">Detalles Expediente</label>
                                <textarea class="form-control" value={legales.detallesExpedienteLegales} onChange={(e) =>
                                    setLegales((prevState) => ({
                                        ...prevState,
                                        detallesExpedienteLegales: e.target.value,
                                    }))} />
                            </div>
                            <button className='btn mt-3' onClick={(e) => actualizarLegales(e)} style={btnAzul}>Actualizar</button>
                            <button className='btn mt-3 ms-3' onClick={(e) => completoLegales(e)} style={btnVerde}>Completo</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-1 pb-5">
                <h2 class="m-0" id="headingSeven" onClick={() => setEtapa(7)}>
                    <button className='headerPresentAcordeon' type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded={Number(etapa) === 7 ? 'true' : 'false'} aria-controls="collapseSeven">
                        <div className="d-flex justify-content-between ps-5 pe-5">
                            <p className='tituloAcordeon letra m-0 center'>ACEPTACION MEDIACION</p>
                        </div>
                    </button>
                </h2>
                <div id="collapseSeven" className={Number(etapa) === 7 ? 'bodyAcordeon collapse.show' : 'bodyAcordeon collapse'} aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                    <div className='row ps-5 pe-5'>
                        <div className="col-6 mt-5">
                            <button className='btn' onClick={(e) => aceptarAceptacion(e)} style={btnVerde}>ACEPTAR</button>
                            <button className='btn ms-3' onClick={(e) => completoAceptacion(e)} style={btnVerde}>Completo</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}