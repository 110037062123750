import { useEffect, useState } from 'react'
import './index.css'
import axios from 'axios';
import { Link } from "react-router-dom"
export default function Files() {
    const [files, setFiles] = useState([])
    useEffect(() => {
        axios.get(`https://api.tuveunchoque.com.ar/api/mail/files`).then((res) => {
            setFiles(res.data);
        });
    }, [files]);
    return (
        <main className='mainCrearExp'>
            {files.map(f => 
                <div className='d-flex ps-3'>
                    <Link to={`${f}`}>{f}</Link>
                </div>
            )}
        </main>
    )
}

