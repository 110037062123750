import './index.css'
export default function EstadoTicket({estado}) {
    let clas = ''
    let clas1366 = ''
    if(estado === 'RECEPCIONADO') {
        clas = 'estadoA'
        clas1366 = 'estadoA1366'
    }
    else if (estado === 'SIN DENUNCIA') {
        clas = 'estadoB'
        clas1366 = 'estadoB1366'
    } 
    else if (estado === 'ENVIADO A COTIZAR') {
        clas = 'estadoC'
        clas1366 = 'estadoC1366'
    } 
    else if (estado === 'PRES SIN RESOLUCION') {
        clas = 'estadoD'
        clas1366 = 'estadoD1366'
    } 
    else if (estado === 'ACEPTACION') {
        clas = 'estadoE'
        clas1366 = 'estadoE1366'
    } 
    else if (estado === 'EN PROCESO DE PAGO') {
        clas = 'estadoF'
        clas1366 = 'estadoF1366'
    }
    else if (estado === 'COBRADO') {
        clas = 'estadoG'
        clas1366 = 'estadoG1366'
    }
    else if (estado === 'MEDIACION') {
        clas = 'estadoH'
        clas1366 = 'estadoH1366'
    }
    else if (estado === 'CERRADO') {
        clas = 'estadoI'
        clas1366 = 'estadoI1366'
    }
    return (
        <div className="d-block">
            <h6 className={window.innerWidth > 1920 ? clas : clas1366}>{estado}</h6>
        </div>
    )
}