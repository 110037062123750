import { useState } from "react";
import * as XLSX from "xlsx";
export default function Excel({ data }) {
    const [loading, setLoading] = useState(false);
    const handleDownload = () => {
        setLoading(true);
        const libro = XLSX.utils.book_new();
        const hoja = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(libro, hoja, "Productos");
        setTimeout(() => {
            XLSX.writeFile(libro, "data.xlsx");
            setLoading(false);
        }, 1000);
    };
    return (
        <>
            <button className="btn btn-secondary" onClick={handleDownload}>
                Exportar a Excel
            </button>
        </>
    );
};
