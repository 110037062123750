import { useParams } from "react-router-dom";
import axios from "axios";
import { useCallback, useState, useContext } from "react";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import './index.css'
export default function RutaAmpliacionPas() {
    const navigate = useNavigate();
    const a = localStorage.getItem('user');
    if (a === null) {
        navigate('/login')
    }
    const [cookies, setCookie, removeCookie] = useCookies(['user'], ['text'], ['ticket']);
    const id = useParams().id
    const hash = useParams().hash
    const [infoTicket, setInfoTicket] = useState({ info: "", pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], prioridad: [], plazo: 5, expFrom: null, expTo: null });
    const [infoGestion, setInfoGestion] = useState({ info: "", pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], prioridad: [], plazo: 0, expFrom: null, expTo: null });
    const [infoTabla, setInfoTabla] = useState({ info: "", pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], patente: '', cliente: '', expFrom: '', expTo: '' });
    const [infoTablaGerencia, setInfoTablaGerencia] = useState({ info: "", pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], vista: true, mesCobradoFrom: '', mesCobradoTo: '', mesEstimadoFrom: '', mesEstimadoTo: '', expFrom: '', expTo: '', modoDePago: '', estadoCobro: '', nroFactura: '', facturadoPor: '', honorariosFrom: '', honorariosTo: '', nroFrom: null, nroTo: null });
    const [userFalse, setUserFalse] = useState(false)
    const userSignUpSubmit = useCallback(async (e) => {
        const response = await axios.post("https://api.tuveunchoque.com.ar/api/user/log2",
            {
                hash: hash
            })
        if (response.data === 'Invalid OAuth') {
            setUserFalse(true)
        } else {
            setCookie('user', response.data, { path: '/' })
            setCookie('ticket', JSON.stringify(infoTicket), { path: '/' })
            setCookie('tabla', JSON.stringify(infoTabla), { path: '/' })
            setCookie('exp', JSON.stringify([]), { path: '/' })
            setCookie('contadorExp', JSON.stringify(0), { path: '/' })
            setCookie('tablaGerencia', JSON.stringify(infoTablaGerencia), { path: '/' })
            setCookie('gestion', JSON.stringify(infoGestion), { path: '/' })
            if (response.data.cargo === 'MEDIADOR') {
                navigate(`/expediente/${id}`)
                removeCookie('text')
            } else if (response.data.cargo === 'PAS') {
                navigate(`/expediente/${id}`)
                removeCookie('text')
            } else if (response.data.cargo === 'ORGANIZACION') {
                navigate(`/expediente/${id}`)
                removeCookie('text')
            } else {
                navigate(`/expediente/${id}`)
                removeCookie('text')
            }
        }
    }, [hash])
    if (hash && id) {userSignUpSubmit()}
    return (
        <div>
            <h1>Pas</h1>
        </div>
    )
}

